import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AlertSeverity, ButtonLinkCancel, ButtonValidate, FieldBlock, FormLayoutButtons, FormLayoutMention, FormLayoutRows, FormLayoutTitle, InputDomain, ModalContent, ModalNew} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {session} from "../../api/ApiSession";
import {EmailNotificationType} from "../../common/api/model/email/EmailNotificationType";
import {PORTAL} from "../../utils/Portal";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ModalConfigCustomInfoProps = {
    active: boolean;
    onClose: () => void;
}

const ModalConfigCustomInfo: FunctionComponent<ModalConfigCustomInfoProps> = ({active, onClose}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const [siteDomain, setSiteDomain] = useState("");
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (active) {
            setSiteDomain("");
        }
    }, [active]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await session.restPortal.sendEmailNotification(
                EmailNotificationType.CUSTOM_CMP_REQUEST_NOTIFICATION,
                PORTAL.origin,
                {"##SITE_NAME##": siteDomain}
            );
            UIEventManager.alert(textConfiguration("message.send_custom_cmp_request"), AlertSeverity.SUCCESS);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.send_custom_cmp_request"), AlertSeverity.DANGER);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>{textConfiguration("modal_config.request_custom_setup")}</FormLayoutTitle>
                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.DOMAIN}`)} required>
                        <InputDomain
                            placeholder={textConfiguration(`placeholder.${CmpConfigField.DOMAIN}`)}
                            value={siteDomain}
                            onChange={setSiteDomain}
                        />
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <ButtonValidate onClick={handleSubmit} disabled={!siteDomain} loading={isLoading}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalConfigCustomInfo;
