import {GraphData, GraphDataset} from "../chart_options";

export class StatHomeBoard {
    consent: number = 0;
    data: GraphData = {
        labels: [],
        datasets: []
    };

    constructor(labels: string[]) {
        this.data.labels = labels;
    }

    addDataset(dataset: GraphDataset) {
        this.data.datasets.push(dataset);
    }
}
