import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {Action, Alert, AlertSeverity, Box, BoxRadius, Color, FormLayoutRows, Icon, IconTooltip, Loader, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {CmpConfigThemeMode} from "../../../api/model/cmp/config/theme/CmpConfigThemeMode";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {Formatter} from "../../../common/utils/Formatter";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";

type ImageSelectorProps = {
    field: CmpConfigThemeModeField.LOGO | CmpConfigThemeModeField.SKIN | CmpConfigThemeModeField.WATERMARK;
    currentImage: string;
    onChange: (newImage: string) => void;
    maxFileSize?: number;
    allowCustomImage?: boolean;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({field, currentImage, onChange, maxFileSize, allowCustomImage = false}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    const [imageSelection, setImageSelection] = useState<string[]>([]);
    const [customImage, setCustomImage] = useState("");
    const [isUploading, setUploading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const imageSelection = await session.restCmpConfig.getImageSelection();
                const fieldImages = imageSelection.forField(field);
                setImageSelection(fieldImages.map((it) => it.url));

                if (currentImage && !fieldImages.some((it) => it.url === currentImage)) {
                    setCustomImage(currentImage);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load image selection", e.message);
                }
            }
        })();
    }, [field, currentImage]);

    const handleImportCustomImage = async ({target}: ChangeEvent<HTMLInputElement>) => {
        if (target.files?.length !== 1) return;

        const file = target.files[0] as File;

        if (maxFileSize && file.size > maxFileSize) {
            UIEventManager.alert(t("error.file_size", {size: Formatter.formatBytes(maxFileSize)}), AlertSeverity.DANGER);
            return;
        }

        try {
            setUploading(true);
            const uploadImageResponse = await session.restCmpConfig.uploadImage(file);
            setCustomImage(uploadImageResponse.url || "");
            onChange(uploadImageResponse.url || "");
        } catch (e) {
            UIEventManager.alert(t("error.file_fail"), AlertSeverity.DANGER);
        } finally {
            setUploading(false);
        }
    };

    const handleClearCustomImage = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (currentImage === customImage) {
            onChange("");
        }
        setCustomImage("");
    };

    const handleEditCustomImage = (e: React.MouseEvent) => {
        e.stopPropagation();
        inputFileRef.current!.click();
    };

    return (
        <FormLayoutRows>
            {maxFileSize &&
                <Alert
                    text={textConfiguration("message.image_size_limit", {
                        size: Formatter.formatBytes(maxFileSize),
                        acceptType: CmpConfigThemeMode.ACCEPTED_IMAGE_TYPES.join(", "),
                        recommendedFormat: field === CmpConfigThemeModeField.SKIN ? `<br>${textConfiguration("image.recommended_format_skin")}` : ""
                    })}
                    fullWidth
                />
            }
            <Box radius={BoxRadius.LG} cssClass="image-selection-box">
                <div className="image-gallery">
                    <Tooltip text={textConfiguration("image.cancel_image")} onClick={() => onChange("")} cssClass={clsx("image-gallery__image", {"image-gallery__image--active": !currentImage})}>
                        <div className={clsx("image-gallery__image__content", "image-gallery__image__content--cancel")}>
                            <Icon name="block" colorIcon={Color.WHITE}/>
                        </div>
                    </Tooltip>
                    {allowCustomImage && (
                        customImage
                            ? (
                                <div className={clsx("image-gallery__image", {"image-gallery__image--active": customImage === currentImage})} onClick={() => onChange(customImage)}>
                                    <div className="image-gallery__image__content" style={{backgroundImage: `url(${customImage})`}}/>
                                    <div className="image-gallery__image__clear">
                                        <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={handleClearCustomImage}/>
                                    </div>
                                    <div className="image-gallery__image__edit">
                                        <IconTooltip icon={{name: "change_circle", outlined: true}} text={t("misc.modify")} onClick={handleEditCustomImage}/>
                                    </div>
                                </div>
                            ) : (
                                <Tooltip
                                    text={textConfiguration("image.add_image")}
                                    onClick={() => inputFileRef.current!.click()}
                                    cssClass={clsx("image-gallery__image", {"image-gallery__image--active": false})}
                                >
                                    <div className={clsx("image-gallery__image__content", "image-gallery__image__content--loading")}>
                                        {isUploading ? <Loader cssClass="image-gallery__image__content__loader" small/> : <Icon {...Action.UPLOAD.icon} colorIcon={Color.WHITE}/>}
                                    </div>
                                </Tooltip>
                            )
                    )}
                    {imageSelection.map((it) => (
                        <div
                            key={it}
                            className={clsx("image-gallery__image", {"image-gallery__image--active": it === currentImage})}
                            onClick={() => onChange(it)}
                        >
                            <div className="image-gallery__image__content" style={{backgroundImage: `url(${it})`}}/>
                        </div>
                    ))}
                    <input
                        ref={inputFileRef}
                        type="file"
                        accept={CmpConfigThemeMode.ACCEPTED_IMAGE_TYPES.join(",")}
                        onChange={(e) => {
                            handleImportCustomImage(e);
                            inputFileRef.current!.value = "";
                        }}
                    />
                </div>
            </Box>
        </FormLayoutRows>
    );
};

export default ImageSelector;
