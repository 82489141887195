import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, ButtonSize, ButtonStyle, ContentBlock, Loadable, Table} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalPurposeRestriction from "../../modal/ModalPurposeRestriction";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigPublisherRestrictionField} from "../../../api/model/cmp/config/CmpConfigPublisherRestrictionField";
import {RestrictionRow} from "../index";
import {CmpConfigPublisherRestriction} from "../../../api/model/cmp/config/CmpConfigPublisherRestriction";
import {Vendor} from "../../../api/model/cmp/list/global-vendor-list/Vendor";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";

type PublisherRestrictionsProps = {
    publisherRestrictions: CmpConfigPublisherRestriction[];
    vendors: Vendor[];
    onChange: (publisherRestrictions: CmpConfigPublisherRestriction[]) => void;
    currentPurpose?: Purpose;
}

const PublisherRestrictions: FunctionComponent<PublisherRestrictionsProps> = ({publisherRestrictions, vendors, onChange, currentPurpose}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const [isLoading, setLoading] = useState(false);
    const [activeRestriction, setActiveRestriction] = useState<CmpConfigPublisherRestriction>();

    const handleAddRestriction = () => {
        setActiveRestriction(new CmpConfigPublisherRestriction({
            [CmpConfigPublisherRestrictionField.PURPOSE]: currentPurpose?.id
        }));
    };

    const handleSubmitRestriction = (restriction: CmpConfigPublisherRestriction) => {
        const newRestrictions = [...publisherRestrictions];
        const index = newRestrictions.findIndex((it) => it.purpose === restriction.purpose && it.restrictionType === restriction.restrictionType);
        if (index > -1) {
            newRestrictions[index] = restriction;
        } else {
            newRestrictions.push(restriction);
        }
        onChange(newRestrictions);
        setActiveRestriction(undefined);
    };

    const handleRemoveRestriction = (restriction: CmpConfigPublisherRestriction) => {
        const newRestrictions = [...publisherRestrictions];
        const index = newRestrictions.findIndex((it) => it.purpose === restriction.purpose && it.restrictionType === restriction.restrictionType);
        newRestrictions.splice(index, 1);
        onChange(newRestrictions);
    };

    useEffect(() => {
        if (currentPurpose) {
            setLoading(true);
            setTimeout(() => setLoading(false), 200);
        }
    }, [currentPurpose]);

    if (!currentPurpose?.id) {
        return <div/>;
    }

    return (
        <>
            <Loadable loading={isLoading}>
                <ContentBlock
                    header={{
                        title: {label: textConfigPurposes("tab.partner.restriction_for_purpose", {purpose: currentPurpose?.id})},
                        actions: (
                            <Button size={ButtonSize.SMALL} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={handleAddRestriction}>
                                {textConfigPurposes("tab.partner.action.add_restrict")}
                            </Button>
                        )
                    }}
                >
                    <Table
                        columns={[
                            {width: 40, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_RESTRICTIONS}.${CmpConfigPublisherRestrictionField.RESTRICTION_TYPE}`)},
                            {width: 50, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_RESTRICTIONS}.${CmpConfigPublisherRestrictionField.VENDORS}`)},
                            {width: 10, label: textConfigPurposes("tab.partner.table.actions")}
                        ]}
                    >
                        {
                            publisherRestrictions.filter((it) => it.purpose === currentPurpose?.id).map((restriction) =>
                                <RestrictionRow
                                    key={restriction.restrictionType}
                                    restriction={restriction}
                                    vendors={vendors}
                                    onEdit={() => setActiveRestriction(restriction)}
                                    onRemove={() => handleRemoveRestriction(restriction)}
                                />
                            )
                        }
                    </Table>
                </ContentBlock>
            </Loadable>
            <ModalPurposeRestriction
                initRestriction={activeRestriction}
                vendors={vendors}
                restrictions={publisherRestrictions.filter((it) => it.purpose === currentPurpose?.id)}
                onClose={() => setActiveRestriction(undefined)}
                onSubmit={handleSubmitRestriction}
            />
        </>
    );
};

export default PublisherRestrictions;
