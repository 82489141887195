import {FunctionComponent} from "react";
import {Formatter, InputDate, SearchField, Select} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {TranslationPortalFile} from "../../../utils/constants";
import {STAT_DATE_FORMAT} from "../../../utils/stat/consent/stats";
import moment from "moment/moment";

type SearchFieldPeriodProps = {
    period: StatPeriod;
    customInterval: StatPeriodInterval;
    onChangePeriod: (period?: StatPeriod) => void;
    onChangeCustomInterval: (customInterval: StatPeriodInterval) => void;
}

const SearchFieldPeriod: FunctionComponent<SearchFieldPeriodProps> = ({period, customInterval, onChangePeriod, onChangeCustomInterval}) => {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);

    const handleChangeCustomInterval = (name: string, date: string) => {
        const newInterval = new StatPeriodInterval(customInterval.start, customInterval.end);
        if (name === "start" || name === "end") {
            newInterval[name] = date;
        }
        onChangeCustomInterval(newInterval);
    };

    return (
        <>
            <SearchField label={textAnalytics("label.period")}>
                <Select
                    value={period.name}
                    options={StatPeriod.values().map((it) => ({value: it.name, label: textAnalytics(`option.${it.name}`)}))}
                    onChange={(option) => option && onChangePeriod(StatPeriod.getByName(`${option.value || ""}`))}
                />
            </SearchField>
            {period.name === StatPeriod.CUSTOM.name &&
                <>
                    <SearchField label={textAnalytics("label.from")}>
                        <InputDate
                            value={customInterval.start}
                            onChange={(value) => handleChangeCustomInterval("start", value)}
                            max={customInterval.end}
                        />
                    </SearchField>
                    <SearchField label={textAnalytics("label.to")}>
                        <InputDate
                            value={customInterval.end}
                            onChange={(value) => handleChangeCustomInterval("end", value)}
                            min={customInterval.start}
                            max={Formatter.formatDate(moment().subtract(1, "day"), STAT_DATE_FORMAT)}
                        />
                    </SearchField>
                </>
            }
        </>
    );
};

export default SearchFieldPeriod;
