import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigPublisherCustomPurposeTranslation} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeTranslation";
import ModalTranslationLayout from "./ModalTranslationLayout";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {TranslationPortalFile} from "../../utils/constants";
import {Box, BoxRadius, BoxSpacing, FieldBlock, FormLayoutRows, InputText, Textarea} from "@sirdata/ui-lib";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigPublisherField} from "../../api/model/cmp/config/publisher/CmpConfigPublisherField";
import {CmpConfigPublisherCustomPurposeField} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeField";
import {CmpConfigPublisherCustomPurposeTranslationField} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeTranslationField";
import {CmpConfigPublisherCustomPurpose} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurpose";

type ModalTranslationPurposeProps = {
    initValue: CmpConfigPublisherCustomPurpose | undefined;
    onClose: () => void;
    onSubmit: (purposeTranslations: Map<string, CmpConfigPublisherCustomPurposeTranslation>) => void;
}

const ModalTranslationPurpose: FunctionComponent<ModalTranslationPurposeProps> = ({initValue, onClose, onSubmit}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const [purposeTranslations, setPurposeTranslations] = useState<Map<string, CmpConfigPublisherCustomPurposeTranslation>>(initValue?.translations || new Map<string, CmpConfigPublisherCustomPurposeTranslation>());
    const [currentLanguage, setCurrentLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);
    const [currentPurposeTranslation, setCurrentPurposeTranslation] = useState<CmpConfigPublisherCustomPurposeTranslation>(new CmpConfigPublisherCustomPurposeTranslation());
    const [definedLanguages, setDefinedLanguages] = useState<string[]>([]);

    const handleSelectLanguage = (language: CmpConfigLanguage) => {
        let newPurposeTranslations = new Map(purposeTranslations);
        let languageTranslation = newPurposeTranslations.get(language.name);
        if (!languageTranslation) {
            languageTranslation = new CmpConfigPublisherCustomPurposeTranslation();
            newPurposeTranslations.set(language.name, languageTranslation);
        }
        setCurrentLanguage(language);
        setCurrentPurposeTranslation(languageTranslation);
        setPurposeTranslations(newPurposeTranslations);
    };

    const handleChangePurposeTranslations = (field: CmpConfigPublisherCustomPurposeTranslationField, value: string) => {
        const newLanguageTranslation = new CmpConfigPublisherCustomPurposeTranslation({...currentPurposeTranslation, [field]: value});
        const newPurposeTranslations = new Map(purposeTranslations);
        newPurposeTranslations.set(currentLanguage.name, newLanguageTranslation);
        setCurrentPurposeTranslation(newLanguageTranslation);
        setPurposeTranslations(newPurposeTranslations);
    };

    const handleSubmit = () => {
        const newPurposeTranslations = new Map<string, CmpConfigPublisherCustomPurposeTranslation>();
        purposeTranslations.forEach((item, language) => {
            if (item.name && item.description) {
                newPurposeTranslations.set(language, new CmpConfigPublisherCustomPurposeTranslation(item));
            }
        });
        onSubmit(newPurposeTranslations);
    };

    useEffect(() => {
        const definedLanguages: string[] = [];
        purposeTranslations.forEach((item, language) => {
            if (item.name && item.description) {
                definedLanguages.push(language);
            }
        });
        setDefinedLanguages(definedLanguages);
    }, [currentLanguage, purposeTranslations]);

    useEffect(() => {
        if (initValue) {
            setPurposeTranslations(new Map(initValue.translations));
            setCurrentLanguage(CmpConfigLanguage.ENGLISH);
            const defaultPurposeTranslation = initValue.translations?.get(CmpConfigLanguage.ENGLISH.name);
            if (defaultPurposeTranslation) {
                setCurrentPurposeTranslation(new CmpConfigPublisherCustomPurposeTranslation(defaultPurposeTranslation));
            }
        }
    }, [initValue]);

    return (
        <ModalTranslationLayout
            active={!!initValue}
            titleKey={"modal_translation.translate_purpose"}
            currentLanguage={currentLanguage}
            definedLanguages={definedLanguages}
            onSelectLanguage={handleSelectLanguage}
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <Box spacing={BoxSpacing.MEDIUM} radius={BoxRadius.MD}>
                <FormLayoutRows>
                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.TRANSLATIONS}.${CmpConfigPublisherCustomPurposeTranslationField.NAME}`)}>
                        <InputText
                            value={currentPurposeTranslation.name}
                            onChange={(value) => handleChangePurposeTranslations(CmpConfigPublisherCustomPurposeTranslationField.NAME, value)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.TRANSLATIONS}.${CmpConfigPublisherCustomPurposeTranslationField.DESCRIPTION}`)}>
                        <Textarea
                            value={currentPurposeTranslation.description}
                            onChange={(value) => handleChangePurposeTranslations(CmpConfigPublisherCustomPurposeTranslationField.DESCRIPTION, value)}
                            rows={10}
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </Box>
        </ModalTranslationLayout>
    );
};

export default ModalTranslationPurpose;
