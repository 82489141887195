import moment, {MomentInput} from "moment/moment";
import {Formatter} from "@sirdata/ui-lib";
import {STAT_DATE_FORMAT} from "./consent/stats";

export class StatPeriodInterval {
    start: string = "";
    end: string = "";

    constructor(start?: MomentInput, end?: MomentInput) {
        this.start = this.formatDate(start);
        this.end = this.formatDate(end);
    }

    formatDate(value?: MomentInput): string {
        return Formatter.formatDate(value, STAT_DATE_FORMAT);
    }

    isInvalid(): boolean {
        if (!this.start || !this.end) return true;
        if (moment(this.start).diff(moment(), "day") > 0) return true;
        return moment(this.start).diff(this.end, "day") > 0;
    }
}
