import {useEffect} from "react";
import {Origin} from "../common/api/model/Origin";
import {PORTAL} from "../utils/Portal";
import {QueryString} from "../common/api/model/QueryString";
import {usePreprod} from "../common/utils/helper";

function Register() {
    useEffect(() => {
        window.location.href = `${Origin.ACCOUNT.getUrl(usePreprod)}/register?${QueryString.ORIGIN}=${PORTAL.origin.name}`;
    }, []);

    return null;
}

export default Register;
