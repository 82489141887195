import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    ColorPicker,
    FieldBlock,
    FlexContent,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    FormLayoutTitle,
    InputText,
    RadioButton
} from "@sirdata/ui-lib";

import Preview from "./Preview";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeMode} from "../../../api/model/cmp/config/theme/CmpConfigThemeMode";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CMP_CONFIG_THEME_POSITIONS} from "../../../api/model/cmp/config/theme/CmpConfigThemePosition";
import {ImageManager} from "../../widget";
import {TranslationPortalFile} from "../../../utils/constants";
import {validateUrl} from "../../../common/utils/string";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ConfigFlashInfoProps = {
    onSubmit: (cmpConfig: CmpConfig) => void;
    isSubmitting: boolean;
    onCancel?: () => void;
    initCmpConfig?: CmpConfig;
}

type ConfigFlashInfoFormErrors = {
    domain?: boolean;
    privacyPolicy?: boolean;
}

const ConfigFlashInfo: FunctionComponent<ConfigFlashInfoProps> = ({onSubmit, isSubmitting, onCancel, initCmpConfig}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const {t: textConfigurationFlash} = useTranslation(TranslationPortalFile.CONFIGURATION_FLASH);
    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
    const [infoFormErrors, setInfoFormErrors] = useState<ConfigFlashInfoFormErrors>();

    useEffect(() => {
        const newCmpConfig = initCmpConfig ? new CmpConfig(initCmpConfig) : new CmpConfig();
        setCmpConfig(newCmpConfig);
        setPrivacyPolicyUrl(newCmpConfig.settings.privacyPolicy);
    }, [initCmpConfig]);

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        setCmpConfig((prevState) => new CmpConfig({...prevState, settings: new CmpConfigSettings({...prevState.settings, [field]: value})}));
    };

    const handleChangeSettingsTheme = (field: CmpConfigThemeField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.THEME, new CmpConfigTheme({...cmpConfig.settings.theme, [field]: value}));
    };

    const handleChangeSettingsThemeMode = (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField, value: string) => {
        handleChangeSettingsTheme(mode, new CmpConfigThemeMode({...cmpConfig.settings.theme[mode], [field]: value.trim()}));
    };

    const handleChangeSettingsThemeModeImage = (field: CmpConfigThemeModeField, newImage: string) => {
        const newConfigSettings = new CmpConfigSettings(cmpConfig.settings);
        newConfigSettings.theme.lightMode[field] = newImage;
        newConfigSettings.theme.darkMode[field] = newImage;
        setCmpConfig((prevState) => new CmpConfig({...prevState, settings: newConfigSettings}));
    };

    const handleSubmit = () => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        let formErrors: ConfigFlashInfoFormErrors = {};

        newCmpConfig.domain = new URL(newCmpConfig.domain.includes("http") ? newCmpConfig.domain : `https://${newCmpConfig.domain}`).hostname;
        formErrors.domain = !validateUrl(newCmpConfig.domain);

        newCmpConfig.settings = new CmpConfigSettings({...newCmpConfig.settings, privacyPolicy: privacyPolicyUrl});
        if (!newCmpConfig.settings.privacyPolicy.includes("http")) {
            newCmpConfig.settings.privacyPolicy = `https://${newCmpConfig.settings.privacyPolicy}`;
        }
        formErrors.privacyPolicy = !validateUrl(newCmpConfig.settings.privacyPolicy);

        if (formErrors.domain || formErrors.privacyPolicy) {
            setInfoFormErrors(formErrors);
            return;
        }

        onSubmit(newCmpConfig);
    };

    const handleCancel = () => {
        setInfoFormErrors(undefined);
        !!onCancel && onCancel();
    };

    const hasEmptyField = () => {
        return !cmpConfig.domain || !cmpConfig.name || !cmpConfig.settings.theme.lightMode.mainColor || !privacyPolicyUrl;
    };

    return (
        <FormLayoutRows>
            <FlexContent direction={FlexContentDirection.ROW} layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT} spacing={FlexContentSpacing.LARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                <FormLayoutRows>
                    <FormLayoutTitle>{textConfigurationFlash("site_information")}</FormLayoutTitle>
                    <FormLayoutColumns>
                        <FieldBlock
                            label={textConfiguration(`field.${CmpConfigField.NAME}`)}
                            required
                        >
                            <InputText
                                value={cmpConfig.name}
                                placeholder={textConfiguration(`placeholder.${CmpConfigField.NAME}`)}
                                onChange={(value) => setCmpConfig((prevState) => new CmpConfig({...prevState, [CmpConfigField.NAME]: value}))}
                                autoFocus
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textConfiguration(`field.${CmpConfigField.DOMAIN}`)}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputText
                                value={cmpConfig.domain}
                                placeholder={textConfiguration(`placeholder.${CmpConfigField.DOMAIN}`)}
                                onChange={(value) => setCmpConfig((prevState) => new CmpConfig({...prevState, [CmpConfigField.DOMAIN]: value.toLowerCase()}))}
                            />
                            {!!infoFormErrors?.domain &&
                                <FormLayoutMessage message={t("error.invalid_domain")} severity={AlertSeverity.DANGER}/>
                            }
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputText
                            value={privacyPolicyUrl}
                            placeholder={textConfiguration(`placeholder.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                            onChange={(value) => setPrivacyPolicyUrl(value.toLowerCase())}
                        />
                        {!!infoFormErrors?.privacyPolicy &&
                            <FormLayoutMessage message={t("error.invalid_url")} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FormLayoutTitle>{textConfigurationFlash("position")}</FormLayoutTitle>
                    <FieldBlock>
                        <div className="configuration-flash__cmp-position">
                            <div className="configuration-flash__cmp-position__preview">
                                <img src={`/images/preview/cmp_preview_${cmpConfig.settings.theme.position.toLowerCase()}.svg`} alt={cmpConfig.settings.theme.position}/>
                            </div>
                            <div className="configuration-flash__cmp-position__choice">
                                {CMP_CONFIG_THEME_POSITIONS.map((it) =>
                                    <div key={it} className="configuration-flash__cmp-position__choice__item">
                                        <RadioButton
                                            id={it}
                                            value={it}
                                            label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.POSITION}.${it}`)}
                                            checked={cmpConfig.settings.theme.position === it}
                                            onChange={(value) => handleChangeSettingsTheme(CmpConfigThemeField.POSITION, value)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </FieldBlock>
                    <FormLayoutTitle>{textConfigurationFlash("design")}</FormLayoutTitle>
                    <FieldBlock
                        label={textConfigurationFlash("main_color")}
                        required
                        content={{noFullWidth: true}}
                    >
                        <ColorPicker
                            value={cmpConfig.settings.theme.lightMode.mainColor || ""}
                            onChange={(value) => handleChangeSettingsThemeMode(CmpConfigThemeField.LIGHT_MODE, CmpConfigThemeModeField.MAIN_COLOR, value)}
                        />
                    </FieldBlock>
                    <FormLayoutColumns>
                        <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.LOGO}`)}>
                            <ImageManager
                                field={CmpConfigThemeModeField.LOGO}
                                mode={CmpConfigThemeField.LIGHT_MODE}
                                currentImage={cmpConfig.settings.theme.lightMode.logo || ""}
                                onSave={(newImage) => handleChangeSettingsThemeModeImage(CmpConfigThemeModeField.LOGO, newImage)}
                                onRemove={() => handleChangeSettingsThemeModeImage(CmpConfigThemeModeField.LOGO, "")}
                                maxFileSize={CmpConfigThemeMode.LOGO_MAX_FILE_SIZE}
                                minifyActions
                            />
                        </FieldBlock>
                        <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.SKIN}`)}>
                            <ImageManager
                                field={CmpConfigThemeModeField.SKIN}
                                mode={CmpConfigThemeField.LIGHT_MODE}
                                currentImage={cmpConfig.settings.theme.lightMode.skin || ""}
                                onSave={(newImage) => handleChangeSettingsThemeModeImage(CmpConfigThemeModeField.SKIN, newImage)}
                                onRemove={() => handleChangeSettingsThemeModeImage(CmpConfigThemeModeField.SKIN, "")}
                                maxFileSize={CmpConfigThemeMode.SKIN_MAX_FILE_SIZE}
                                minifyActions
                            />
                        </FieldBlock>
                        <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.WATERMARK}`)}>
                            <ImageManager
                                field={CmpConfigThemeModeField.WATERMARK}
                                mode={CmpConfigThemeField.LIGHT_MODE}
                                currentImage={cmpConfig.settings.theme.lightMode.watermark || ""}
                                onSave={(newImage) => handleChangeSettingsThemeModeImage(CmpConfigThemeModeField.WATERMARK, newImage)}
                                onRemove={() => handleChangeSettingsThemeModeImage(CmpConfigThemeModeField.WATERMARK, "")}
                                minifyActions
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FormLayoutMention/>
                </FormLayoutRows>
                <div className="configuration-flash__preview-block">
                    <Preview cmpConfig={cmpConfig} hideBrowserBar/>
                </div>
            </FlexContent>
            <FormLayoutButtons>
                {!!onCancel && <ButtonLinkCancel onClick={handleCancel}/>}
                <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()} loading={isSubmitting}/>
            </FormLayoutButtons>
        </FormLayoutRows>
    );
};

export default ConfigFlashInfo;
