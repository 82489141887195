import React, {useEffect, useState} from "react";
import {AlertSeverity, Color, LayoutRows, Loadable, MainContentPageHeader, SearchField, SearchToolbar, SelectAutocomplete, Tabs} from "@sirdata/ui-lib";
import {animateScroll as scroll} from "react-scroll";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AnalyticsConsentDetailsDevice, AnalyticsConsentDetailsGlobal} from "../../../screen";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {pathAnalyticsConsentWithId, TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {SearchFieldPeriod} from "../../../component/snippet";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {StatConsentDetails} from "../../../api/model/stat/consent/StatConsentDetails";
import {PathHelper} from "../../../common/utils/PathHelper";

export enum AnalyticsConsentDetailsTab {
    GLOBAL = "global",
    DESKTOP = "desktop",
    MOBILE = "mobile",
    TABLET = "tablet",
}

function AnalyticsConsentDetails() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const navigate = useNavigate();
    const params = useParams() as {id: string};
    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [isLoading, setLoading] = useState(true);

    const [configId, setConfigId] = useState(params.id);
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_CONSENT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_CONSENT_PERIOD.interval);
    const [statsDetails, setStatsDetails] = useState<StatConsentDetails>(new StatConsentDetails());
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleChangeConfigId = (configId: string) => {
        setConfigId(configId);
        navigate(PathHelper.buildPathWithId(pathAnalyticsConsentWithId, configId));
    };

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_CONSENT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    const handleClickDevice = (tab: AnalyticsConsentDetailsTab) => {
        scroll.scrollToTop({delay: 0, duration: 0, smooth: "none"});
        setLoading(true);
        setTimeout(() => {
            setCurrentTabIndex(Object.values(AnalyticsConsentDetailsTab).findIndex((it) => it === tab));
            setLoading(false);
        }, 100);
    };

    useEffect(() => {
        if (interval.isInvalid()) {
            return;
        }

        (async () => {
            try {
                setLoading(true);
                const statsDetails = await session.restPartner.getConsentStatsForConfig(configId, period, interval.start, interval.end);
                setStatsDetails(statsDetails);
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
            } finally {
                setLoading(false);
            }
        })();
    }, [configId, interval, period, textAnalytics]);

    useEffect(() => {
        setInterval(period.interval);
    }, [period]);

    useEffect(() => {
        (async () => {
            try {
                await session.loadAccount();
                const cmpConfigs = await session.restCmpConfig.list();
                setCmpConfigs(cmpConfigs.filter((it) => it.step === ""));

                if (!!cmpConfigs.length && !cmpConfigs.find((it) => it.id === configId)) {
                    setConfigId(cmpConfigs[0].id);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load data", e.message);
                }
            }
        })();
    }, [configId]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textAnalytics("consent.title")} icon={{name: "bar_chart", colorIcon: Color.CYAN}}/>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchField label={textConfiguration(`field.${CmpConfigField.NAME}`)}>
                            <SelectAutocomplete
                                value={configId}
                                onChange={(option) => handleChangeConfigId(`${option?.value || ""}`)}
                                options={cmpConfigs.map(({id, name, domain}) => ({value: id, label: `${name} (${domain})`}))}
                            />
                        </SearchField>
                        <SearchFieldPeriod
                            period={period}
                            onChangePeriod={handleChangePeriod}
                            customInterval={interval}
                            onChangeCustomInterval={handleChangeInterval}
                        />
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        <Tabs
                            headers={Object.values(AnalyticsConsentDetailsTab).map((it) => ({label: textAnalytics(`tab.${it}`)}))}
                            current={currentTabIndex}
                        >
                            <AnalyticsConsentDetailsGlobal
                                statsDetails={statsDetails}
                                period={period}
                                onClickDevice={handleClickDevice}
                            />
                            <AnalyticsConsentDetailsDevice
                                dates={statsDetails.dates}
                                deviceRates={statsDetails.desktop}
                                period={period}
                            />
                            <AnalyticsConsentDetailsDevice
                                dates={statsDetails.dates}
                                deviceRates={statsDetails.mobile}
                                period={period}
                            />
                            <AnalyticsConsentDetailsDevice
                                dates={statsDetails.dates}
                                deviceRates={statsDetails.tablet}
                                period={period}
                            />
                        </Tabs>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default AnalyticsConsentDetails;
