import React, {FunctionComponent} from "react";
import clsx from "clsx";

export type ChartCardLegendLabels = {
    value: string;
    color: string;
};

type ChartCardLegendProps = {
    labels: ChartCardLegendLabels[] | undefined;
    alignRight?: boolean;
};

const ChartCardLegend: FunctionComponent<ChartCardLegendProps> = ({labels, alignRight}) => {
    if (!labels) return <></>;

    return (
        <ul className={clsx("chart-card__legend", {"chart-card__legend--align-right": alignRight})}>
            {labels.map((label) => (
                <li key={label.value}><span style={{backgroundColor: label.color}}/>{label.value}</li>
            ))}
        </ul>
    );
};

export default ChartCardLegend;
