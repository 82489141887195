import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {
    Action,
    AlertSeverity,
    Button,
    Checkbox,
    Color,
    FlexContent,
    FlexContentDirection,
    FlexContentJustify,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutRows,
    Icon,
    Loader,
    ModalContent,
    ModalNew,
    Paragraph,
    SvgRocket,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {CrossSalesProduct} from "../../utils/CrossSalesProduct";
import {session} from "../../api/ApiSession";
import {TranslationCommonFile} from "../../common/utils/constants";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ModalCrossSalesContactProps = {
    active: boolean;
    product?: CrossSalesProduct;
    onClose: () => void;
};

const ModalCrossSalesContact: FunctionComponent<ModalCrossSalesContactProps> = ({active, product, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContact} = useTranslation(TranslationCommonFile.CONTACT);
    const {t: textCrossSales} = useTranslation(TranslationPortalFile.CROSS_SALES);
    const [products, setProducts] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const handleClose = useCallback(() => {
        setProducts([]);
        setMessageSent(false);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        try {
            setSubmitting(true);
            const topic = textCrossSales("modal.contact_topic");
            const productList = textCrossSales(`product.${product}.name`) || products.join("<br/>- ");
            const message = textCrossSales("modal.contact_content", {productList: productList});
            await session.restPortal.sendContactMessage(topic, message);
            setMessageSent(true);
        } catch (e) {
            UIEventManager.alert(textContact("mail_error"), AlertSeverity.DANGER);
            handleClose();
        } finally {
            setSubmitting(false);
        }
    }, [handleClose, product, products, textContact, textCrossSales]);

    useEffect(() => {
        if (active && product) {
            handleSubmit();
        }
    }, [active, handleSubmit, product]);

    const handleChange = (name: string, isChecked: boolean) => {
        if (isChecked) {
            setProducts((products) => products.filter((product) => product !== name));
        } else {
            setProducts((products) => [...products, name]);
        }
    };

    const buildCheckbox = (name: string) => {
        const isChecked = products.includes(name);
        return (
            <Checkbox
                name={name}
                label={name}
                checked={isChecked}
                onChange={() => handleChange(name, isChecked)}
            />
        );
    };

    return (
        <ModalNew onClose={handleClose} active={active} cssClass="cross-sales-modal">
            <ModalContent centered>
                {messageSent ?
                    <>
                        <Icon name="check_circle" colorIcon={Color.GREEN} outlined/>
                        <Paragraph withSpacing><span dangerouslySetInnerHTML={{__html: textCrossSales("modal.message_sent")}}/></Paragraph>
                        <Button onClick={handleClose}>{textCommon(Action.CLOSE.labelKey)}</Button>
                    </>
                    : (product && submitting) && <Loader/>
                }
                {(!product && !messageSent) &&
                    <FlexContent direction={FlexContentDirection.ROW} cssClass="cross-sales-modal__content">
                        <div>
                            <SvgRocket/>
                        </div>
                        <div>
                            <Paragraph withSpacing>{textCrossSales("modal.contact_us")}</Paragraph>
                            <FormLayoutRows>
                                <FormLayoutRows spacing={FlexContentSpacing.LARGE}>
                                    {Object.keys(CrossSalesProduct).map((it) =>
                                        <React.Fragment key={it}>
                                            {buildCheckbox(textCrossSales(`product.${it}.name`))}
                                        </React.Fragment>
                                    )}
                                </FormLayoutRows>
                                <FormLayoutButtons justify={FlexContentJustify.END}>
                                    <Button
                                        icon={Action.SEND.icon}
                                        reverse
                                        disabled={!products.length}
                                        onClick={handleSubmit}
                                        loading={submitting}
                                    >
                                        {textCommon(Action.SEND.labelKey)}
                                    </Button>
                                </FormLayoutButtons>
                            </FormLayoutRows>
                        </div>
                    </FlexContent>
                }
            </ModalContent>
        </ModalNew>
    );
};

export default ModalCrossSalesContact;
