import React, {FunctionComponent} from "react";
import {ConfigHeaderCreate, ConfigHeaderEdit} from "../index";
import {CmpConfigStepName} from "../../../utils/CmpConfigStepper";

type ConfigHeaderProps = {
    step: CmpConfigStepName;
    editMode: boolean;
    hasUnsavedChanges: boolean;
    onSave: () => Promise<void>;
    onOpenPreview?: () => void;
};

const ConfigHeader: FunctionComponent<ConfigHeaderProps> = ({step, editMode, hasUnsavedChanges, onSave, onOpenPreview}) => {
    return (
        editMode ?
            <ConfigHeaderEdit
                step={step}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={onSave}
                onOpenPreview={onOpenPreview}
            /> :
            <ConfigHeaderCreate
                step={step}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={onSave}
                onOpenPreview={onOpenPreview}
            />
    );
};

export default ConfigHeader;
