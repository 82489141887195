import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Vendor} from "../../api/model/cmp/list/global-vendor-list/Vendor";
import {CmpConfigPublisherRestriction} from "../../api/model/cmp/config/CmpConfigPublisherRestriction";
import {CmpConfigPublisherRestrictionType} from "../../api/model/cmp/config/CmpConfigPublisherRestrictionType";
import {Action, Icon, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";

type RestrictionRowProps = {
    restriction: CmpConfigPublisherRestriction;
    vendors: Vendor[];
    onEdit: () => void;
    onRemove: () => void;
};

const RestrictionRow: FunctionComponent<RestrictionRowProps> = ({restriction, vendors, onEdit, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const [restrictionVendors, setRestrictionVendors] = useState<Vendor[]>([]);
    const [isOpenMore, setOpenMore] = useState<boolean>(false);

    const getTypeName = () => {
        switch (restriction.restrictionType) {
            case CmpConfigPublisherRestrictionType.NOT_ALLOWED:
                return textConfigPurposes("not_allowed");
            case CmpConfigPublisherRestrictionType.REQUIRE_CONSENT:
                return textConfigPurposes("require_consent");
            case CmpConfigPublisherRestrictionType.REQUIRE_LEGITIMATE_INTEREST:
                return textConfigPurposes("require_legint");
        }
    };

    useEffect(() => {
        const restrictionVendors: Vendor[] = [];
        restriction.vendors.forEach((id) => {
            const vendor = vendors.find((it) => it.id === id);
            if (vendor) restrictionVendors.push(vendor);
        });
        setRestrictionVendors(restrictionVendors);
    }, [restriction, vendors]);

    return (
        <>
            <TableRow>
                <TableColumn>{getTypeName()}</TableColumn>
                <TableColumn onClick={() => setOpenMore((prev) => !prev)}>
                    <span>{textConfigPurposes("tab.partner.table.see_partners")}</span>
                    <Icon name={isOpenMore ? "do_not_disturb_on" : "add_circle"} cssClass="table__action-button"/>
                </TableColumn>
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    <IconTooltip
                        icon={Action.EDIT.icon}
                        text={textCommon(Action.EDIT.labelKey)}
                        onClick={onEdit}
                    />
                    <IconTooltip
                        icon={Action.REMOVE.icon}
                        text={textCommon(Action.REMOVE.labelKey)}
                        onClick={onRemove}
                    />
                </TableActionColumn>
            </TableRow>
            {isOpenMore &&
                <TableRow>
                    <TableColumn colSpan={3}>
                        <ul>
                            {restrictionVendors.map((vendor) =>
                                <li key={vendor.id}>{vendor.name}</li>
                            )}
                        </ul>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default RestrictionRow;
