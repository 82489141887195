import {useEffect, useState} from "react";
import {
    AlertSeverity,
    Button,
    ButtonSize,
    Color,
    ContentBlock,
    EmptyContentMessage,
    IconTooltip,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    SearchError,
    SearchToolbar,
    SvgUnderConstruction,
    Table,
    TableActionColumn,
    TableColumn,
    TableColumnStyle,
    TableRow
} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {PAGE_SIZE, pathAnalyticsConsentWithId, pathCmp, TranslationPortalFile} from "../../../utils/constants";
import {CrossSales, SearchFieldPeriod} from "../../../component/snippet";
import {session} from "../../../api/ApiSession";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {AnalyticsColor} from "../../../utils/AnalyticsColor";
import {StatConsentRatingsResult} from "../../../api/model/stat/consent/StatConsentRatingsResult";
import {PathHelper} from "../../../common/utils/PathHelper";
import {CrossSalesProduct} from "../../../utils/CrossSalesProduct";

function AnalyticsConsent() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);

    const [query, setQuery] = useState<string>("");
    const [currentCmpConfigs, setCurrentCmpConfigs] = useState<CmpConfig[]>([]);
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_CONSENT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_CONSENT_PERIOD.interval);

    const [statRatings, setStatRatings] = useState<StatConsentRatingsResult>(new StatConsentRatingsResult());

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_CONSENT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    useEffect(() => {
        const newCurrentCmpConfigs = cmpConfigs.filter((item) => !query || `${item.name} ${item.domain}`.toLowerCase().includes(query.toLowerCase()));
        setCurrentCmpConfigs(newCurrentCmpConfigs);
    }, [query, cmpConfigs]);

    useEffect(() => {
        setInterval(period.interval);
    }, [period]);

    useEffect(() => {
        if (!cmpConfigs.length || interval.isInvalid()) {
            setStatRatings(new StatConsentRatingsResult());
            return;
        }

        (async () => {
            setLoading(true);

            try {
                const statRatings = await session.restPartner.getConsentStatsRatings(period, interval.start, interval.end);
                setStatRatings(statRatings);
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
                setStatRatings(new StatConsentRatingsResult());
            } finally {
                setLoading(false);
            }
        })();
    }, [cmpConfigs, period, interval, textAnalytics]);

    useEffect(() => {
        (async () => {
            try {
                const cmpConfigs = await session.restCmpConfig.list();
                setCmpConfigs(cmpConfigs);
                setCurrentCmpConfigs(cmpConfigs);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load data", e.message);
                }
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textAnalytics("consent.title")} icon={{name: "bar_chart", colorIcon: Color.CYAN}}/>
                <LayoutRows>
                    {!!cmpConfigs.length ? (
                        <ContentBlock>
                            <SearchToolbar searchBar={{placeholder: textConfiguration("search.site"), value: query, onChange: setQuery}} canHideFilters>
                                <SearchFieldPeriod
                                    period={period}
                                    onChangePeriod={handleChangePeriod}
                                    customInterval={interval}
                                    onChangeCustomInterval={handleChangeInterval}
                                />
                            </SearchToolbar>
                            {!!currentCmpConfigs.length ? (
                                <Table
                                    columns={[
                                        {width: 10, label: textConfiguration(`field.${CmpConfigField.ID}`)},
                                        {width: 40, label: textConfiguration(`field.${CmpConfigField.NAME}`)},
                                        {width: 15, label: textAnalytics("metric.consent"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAnalytics("metric.object"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAnalytics("metric.no_choice"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 5, styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={PAGE_SIZE}
                                >
                                    {currentCmpConfigs.map((config) => (
                                        <TableRow key={config.id}>
                                            <TableColumn>
                                                <span>{config.id}</span>
                                            </TableColumn>
                                            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                                                <span>{config.name}</span>
                                            </TableColumn>
                                            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]}>
                                                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                                                    <span style={{color: AnalyticsColor.CONSENT}}>{`${statRatings[config.id]?.consent || 0}%`}</span>
                                                </Loadable>
                                            </TableColumn>
                                            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]}>
                                                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                                                    <span style={{color: AnalyticsColor.OBJECT}}>{`${statRatings[config.id]?.object || 0}%`}</span>
                                                </Loadable>
                                            </TableColumn>
                                            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]}>
                                                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                                                    <span>{`${statRatings[config.id]?.no_choice || 0}%`}</span>
                                                </Loadable>
                                            </TableColumn>
                                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                                <IconTooltip
                                                    icon={{name: "visibility"}}
                                                    text={textAnalytics("action.see_full_analytics")}
                                                    onClick={() => navigate(PathHelper.buildPathWithId(pathAnalyticsConsentWithId, config.id))}
                                                />
                                            </TableActionColumn>
                                        </TableRow>
                                    ))}
                                </Table>
                            ) : (
                                <SearchError query={query}/>
                            )}
                        </ContentBlock>
                    ) : (
                        <EmptyContentMessage svg={SvgUnderConstruction} message={textAnalytics("empty_content.message")}>
                            <Button onClick={() => navigate(pathCmp)} size={ButtonSize.BIG}>
                                {textAnalytics("empty_content.start")}
                            </Button>
                        </EmptyContentMessage>
                    )}
                    <CrossSales product={CrossSalesProduct.GTM_SERVER_SIDE}/>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default AnalyticsConsent;
