import clsx from "clsx";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Action, Color, Icon} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import CmpConfigStepper, {CmpConfigStepName} from "../../../utils/CmpConfigStepper";

type ConfigHeaderMenuProps = {
    step: CmpConfigStepName;
    onChangePath: (path: string) => void;
}

const ConfigHeaderMenu: FunctionComponent<ConfigHeaderMenuProps> = ({step, onChangePath}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {id: configId} = useParams() as {id: string};

    return (
        <div className="config-header__menu">
            <span className="config-header__menu__label">
                <Icon name="list"/>
                <span>{t(`list.action.edit_step.${step}`)}</span>
                <Icon {...Action.EXPAND.icon} colorIcon={Color.GREY_DARK}/>
            </span>
            <div className="config-header__menu__content">
                <ul>
                    {CmpConfigStepper.steps.filter((it) => it.name !== CmpConfigStepName.INFO).map((item) =>
                        <li key={item.name} className={clsx("config-header__menu__content__row", {"config-header__menu__content__row--active": item.name === step})}>
                            <span className="config-header__menu__content__row__link" onClick={() => onChangePath(item.getPath(configId))}>{t(`list.action.edit_step.${item.name}`)}</span>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ConfigHeaderMenu;
