import React, {FunctionComponent} from "react";

type ProgressBarProps = {
    step: number;
    totalSteps: number;
};

const ProgressBar: FunctionComponent<ProgressBarProps> = ({step, totalSteps}) => {
    if (!step) return <></>;

    return (
        <div className="configuration-progress-bar">
            <div className="configuration-progress-bar__track" style={{width: step * (100 / totalSteps) + "%"}}/>
        </div>
    );
};

export default ProgressBar;
