import React from "react";
import {ChartOptions} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {GraphData, chartPieOptions} from "../../../utils/stat/chart_options";

interface ChartDoughnutProps {
    data: GraphData | undefined;
    options?: ChartOptions<"doughnut">;
}

const ChartDoughnut: React.FC<ChartDoughnutProps> = ({data, options = chartPieOptions}) => {
    if (!data) return <></>;

    return <Doughnut data={data} options={options}/>;
};

export default ChartDoughnut;
