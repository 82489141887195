import {ApiModel} from "../../../../common/api/model/ApiModel";
import {StatConsentRates} from "./StatConsentRates";

export class StatConsentDetails extends ApiModel {
    dates: string[] = [];
    global: StatConsentRates = new StatConsentRates();
    desktop: StatConsentRates = new StatConsentRates();
    mobile: StatConsentRates = new StatConsentRates();
    tablet: StatConsentRates = new StatConsentRates();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObject("global", StatConsentRates);
        this.loadObject("desktop", StatConsentRates);
        this.loadObject("mobile", StatConsentRates);
        this.loadObject("tablet", StatConsentRates);
    }
}
