import {FunctionComponent} from "react";

const SvgCookieIcon: FunctionComponent = () => {
    return (
        <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2.36904 5.86248L1.53638 6.05403C1.53638 6.05403 0.381216 7.83662 0.129074 9.73257C-0.0292483 10.9229 -0.0351122 12.4768 0.0743451 13.2508C0.185757 14.0249 0.584494 15.6726 1.21192 16.8336C1.81589 17.9516 2.40422 18.3914 3.04728 18.7804C3.32288 18.9465 3.56916 19.5153 3.99917 19.7909C4.40182 20.0489 4.93347 20.0997 4.93347 20.0997C4.93347 20.0997 6.40332 22.4081 9.58344 22.8088C13.3148 23.2779 15.1404 21.593 15.1404 21.593C15.1404 21.593 16.6337 21.5891 18.0429 20.9852C19.6242 20.3069 20.4686 18.8957 20.4686 18.8957C20.4686 18.8957 22.4486 17.1913 23.076 15.1507C23.5803 13.5128 23.594 10.3991 23.207 8.93314C22.82 7.4672 22.0713 6.22603 21.4771 5.43051C21.0041 4.79722 15.7228 1.99043 15.0856 1.74024C14.4504 1.49201 8.53383 1.105 8.31296 1.27114C8.09209 1.43923 2.36904 5.86248 2.36904 5.86248Z" fill="#AC5811"/>
            <path d="M4.42908 2.73342C3.05109 3.85536 2.33766 4.44174 1.62228 5.84513C0.906902 7.24853 1.13754 9.11126 1.26655 9.79928C1.3936 10.4873 1.62424 11.8145 1.75129 12.4771C1.87834 13.1416 2.21648 14.717 3.15273 15.6924C4.46426 17.0586 5.65266 17.5297 5.65266 17.5297C5.65266 17.5297 6.61627 19.023 8.94419 19.8772C12.0051 20.9991 13.3831 19.367 14.5558 18.9331C15.7286 18.4992 17.132 18.4738 18.3321 17.6313C19.5303 16.7889 20.8574 15.7686 21.7253 13.6264C22.5931 11.4841 22.4133 10.5401 22.4387 10.0045C22.4641 9.46895 22.3879 6.8928 21.393 5.31153C20.3981 3.73026 19.2019 2.78033 18.7406 2.47932C17.7203 1.81672 14.077 0.231541 13.1603 0.053673C12.2416 -0.124195 8.96569 0.153357 7.66589 0.688916C6.36608 1.22447 4.42908 2.73342 4.42908 2.73342Z" fill="url(#paint0_radial_1284_815)"/>
            <path d="M10.2438 12.8406C10.0268 12.7722 9.79814 12.4497 9.74341 11.7597C9.66718 10.7766 10.3923 10.5049 10.3923 10.5049C10.3923 10.5049 11.2563 10.2762 11.8641 10.0592C12.429 9.85793 12.8356 9.59992 12.8356 9.59992C12.8356 9.59992 12.8903 8.54639 13.2402 8.23561C13.592 7.92483 14.2116 7.9522 14.9016 8.03234C15.5896 8.11247 16.6979 8.5718 16.5356 8.99009C16.3734 9.40837 16.1838 9.82665 15.5231 10.2058C14.8625 10.5831 14.3895 10.9349 14.0513 11.232C13.7132 11.5311 13.0936 11.9063 12.0674 12.2855C11.0413 12.6647 10.5565 12.9384 10.2438 12.8406Z" fill="#FFC86E"/>
            <path d="M4.43286 4.04691C3.78394 3.914 2.86723 5.31935 2.69719 5.78454C2.52518 6.24974 2.34927 7.3834 2.32972 7.90137C2.30431 8.54443 2.29258 9.1484 2.56232 9.639C2.76951 10.0182 3.27184 10.2312 3.72335 9.84033C4.17486 9.44941 4.95865 8.37829 5.16584 8.22779C5.37302 8.07728 5.97895 7.87791 5.74049 6.8928C5.64276 6.49015 4.65178 5.6575 4.60291 5.54413C4.556 5.43272 4.80033 4.12314 4.43286 4.04691Z" fill="#FFC86E"/>
            <path d="M9.02066 2.28171C9.04607 2.65895 9.35489 3.26292 9.68913 3.58934C10.1895 4.07603 12.4842 5.7218 13.0784 5.62798C13.6726 5.53416 14.3196 4.46695 14.5092 4.16985C14.6988 3.87275 15.076 3.45447 15.2245 3.26488C15.3731 3.07528 15.764 2.64331 15.4415 2.21135C15.117 1.77938 14.3078 1.49597 13.7684 1.45492C13.2289 1.41387 12.3904 1.064 11.675 1.09137C10.9596 1.11873 8.9542 1.30833 9.02066 2.28171Z" fill="#FFC86E"/>
            <path d="M18.4651 7.31459C18.5101 7.72506 19.0867 7.81497 19.546 8.11207C20.0054 8.40917 20.3161 8.71995 20.5859 8.63785C20.8556 8.55772 21.2231 8.12575 21.139 7.36932C21.0315 6.39789 20.4237 5.15477 19.5734 5.19581C19.0593 5.22122 19.1297 6.11447 19.1297 6.11447C19.1297 6.11447 18.4378 7.07222 18.4651 7.31459Z" fill="#FFC86E"/>
            <path d="M7.34946 4.78187C7.34946 4.78187 5.50628 4.77014 5.53951 4.95387C5.57469 5.13761 5.73497 5.74353 6.2158 5.76699C6.69663 5.79044 7.31623 5.8354 7.61333 5.74353C7.91043 5.65166 8.73527 4.9871 8.73527 4.69C8.73527 4.39291 8.40299 3.95703 8.40299 3.95703L7.34946 4.78187Z" fill="#CF701E"/>
            <path d="M9.98192 8.16119C9.98192 8.16119 9.96042 8.17488 9.92915 8.20029C9.83728 8.27651 9.66137 8.45438 9.72978 8.65375C9.82165 8.91762 10.3592 9.41018 10.8869 9.41018C11.4146 9.41018 11.7 8.91762 11.7919 8.59707C11.8837 8.27651 11.8368 8.08105 11.8368 8.08105L9.98192 8.16119Z" fill="#CF701E"/>
            <path d="M5.87164 9.66242C5.87164 9.66242 5.55109 9.77774 5.56281 9.98297C5.57454 10.1882 5.65468 10.5674 6.0671 10.9681C6.48343 11.3727 7.0874 11.4841 7.63664 11.3356C8.18588 11.187 8.58657 10.7746 8.54161 10.2253C8.49666 9.6761 7.96892 9.25195 7.96892 9.25195L5.87164 9.66242Z" fill="#CF701E"/>
            <path d="M3.5008 12.6982C3.5008 12.6982 3.1568 12.7783 3.19198 13.007C3.22716 13.2357 3.61613 13.9569 4.23378 13.8768C4.85143 13.7966 5.82677 13.0871 5.82677 12.8115C5.82677 12.5359 5.33421 12.1704 5.33421 12.1704L3.5008 12.6982Z" fill="#CF701E"/>
            <path d="M7.71471 15.5615C7.71471 15.5615 7.58767 16.3004 8.58451 16.5701C10.2459 17.0177 10.4179 16.0091 10.4179 16.0091L7.71471 15.5615Z" fill="#CF701E"/>
            <path d="M14.2413 15.9038C14.2413 15.9038 13.7957 16.3495 14.3019 16.6993C14.8082 17.0492 15.3848 17.1684 15.9281 17.1078C16.4715 17.0472 16.8429 16.6133 16.8429 16.6133L14.2413 15.9038Z" fill="#CF701E"/>
            <path d="M18.4011 10.9546C18.4011 10.9546 17.5606 11.3787 18.2115 12.1117C18.706 12.6707 19.8377 12.5574 20.301 12.2427C20.6039 12.0374 20.6939 11.6739 20.5805 11.4901C20.4652 11.3064 18.4011 10.9546 18.4011 10.9546Z" fill="#CF701E"/>
            <path d="M15.1481 6.0993C15.1481 6.0993 15.1032 7.05901 16.3971 7.02773C18.2071 6.98278 17.9784 5.85889 17.9784 5.85889L15.1481 6.0993Z" fill="#CF701E"/>
            <path d="M15.9846 4.89713C15.9846 4.89713 15.1363 5.42487 15.0444 5.64183C14.9526 5.85879 14.7571 6.54681 15.7422 6.50185C16.7273 6.4569 17.7124 6.42172 18.0447 6.02102C18.377 5.62033 18.2969 5.25287 18.16 4.95577C18.0232 4.65867 17.2531 3.53478 16.6413 3.54846C16.2406 3.55628 15.9846 4.89713 15.9846 4.89713Z" fill="#593329"/>
            <path d="M16.6374 3.54481C16.6374 3.54481 16.0999 3.4764 15.6289 4.31296C15.1598 5.14953 15.0386 5.64795 15.0386 5.64795C15.0386 5.64795 15.9944 5.65186 16.5671 5.58345C17.1398 5.51504 17.5287 5.1593 17.5287 5.1593C17.5287 5.1593 17.0616 3.6484 16.6374 3.54481Z" fill="#925849"/>
            <path d="M10.1327 7.45176C10.1327 7.45176 9.66363 7.42048 9.62845 7.60226C9.59327 7.78599 9.69686 8.25314 9.85714 8.45837C10.0174 8.6636 10.4982 9.12293 10.9791 9.03107C11.4599 8.9392 11.7453 8.46815 11.8254 8.30591C11.8977 8.15346 11.9759 7.98927 11.8391 7.78404C11.7003 7.57685 10.1327 7.45176 10.1327 7.45176Z" fill="#593329"/>
            <path d="M10.8308 6.71854C10.4321 6.67554 9.47045 7.43979 9.64246 7.7017C9.83401 7.98903 9.86723 7.80725 10.395 8.07112C10.8308 8.28808 11.0204 8.4894 11.2941 8.45422C11.5677 8.41904 11.8941 7.96753 11.8375 7.78379C11.7808 7.60006 11.3703 6.77522 10.8308 6.71854Z" fill="#925849"/>
            <path d="M6.31762 3.62413L5.37942 4.27305C5.37942 4.27305 5.34424 4.71284 5.53969 4.95325C5.73515 5.19367 5.79184 5.34222 6.70854 5.34222C7.62525 5.34222 8.48331 5.21712 8.64359 4.56429C8.80387 3.91146 8.52241 3.49317 8.3504 3.25276C8.1784 3.01234 6.31762 3.62413 6.31762 3.62413Z" fill="#593329"/>
            <path d="M6.32911 2.6065C6.11801 2.69641 5.61959 3.37465 5.50427 3.67175C5.38895 3.96885 5.36354 4.28549 5.37918 4.2894C5.56877 4.33631 6.68485 4.43991 7.1774 4.35977C7.66996 4.27963 8.07065 4.06267 8.23093 3.77535C8.35798 3.54666 8.34821 3.2437 8.34821 3.2437C8.34821 3.2437 8.01397 2.64168 7.55464 2.45795C7.09727 2.27422 6.40925 2.57132 6.32911 2.6065Z" fill="#925949"/>
            <path d="M5.54351 9.70567C5.54351 9.70567 5.46533 9.82491 5.61388 10.0536C5.76242 10.2823 6.05366 10.5559 6.48953 10.683C6.92541 10.8081 7.23423 10.9801 7.5196 10.9468C7.80693 10.9117 8.3679 10.683 8.3894 9.89332C8.41285 9.10366 8.25258 8.7362 7.90857 8.39219C7.56456 8.04818 7.31633 8.10682 7.31633 8.10682L5.54351 9.70567Z" fill="#593329"/>
            <path d="M5.64293 8.97396C5.52174 9.17724 5.43183 9.83789 5.58038 9.98644C5.72893 10.135 6.62218 10.1448 6.85673 10.1193C7.09323 10.0939 7.8653 9.73038 7.87703 9.45479C7.88875 9.17919 7.74021 8.81368 7.74021 8.67686C7.74021 8.54004 7.91221 8.21948 7.44311 8.10416C6.974 7.98884 5.89311 8.54981 5.64293 8.97396Z" fill="#925849"/>
            <path d="M4.09466 11.9415C4.09466 11.9415 3.35778 12.1233 3.31087 12.3754C3.26591 12.6276 3.37146 12.8817 3.6236 13.1221C3.87574 13.3625 4.05947 13.6147 4.25298 13.5912C4.44844 13.5677 4.9625 13.1104 5.12277 12.9618C5.28305 12.8133 5.51174 12.5494 5.49024 12.3324C5.46874 12.1155 5.37492 11.8966 5.16968 11.7246C4.96641 11.5526 4.09466 11.9415 4.09466 11.9415Z" fill="#593329"/>
            <path d="M4.2784 11.2883C3.93439 11.2961 3.67052 11.7125 3.55716 11.861C3.44184 12.0096 3.24833 12.3653 3.32847 12.422C3.40861 12.4787 3.74089 12.5138 3.90117 12.6174C4.06144 12.721 4.30186 12.8813 4.48559 12.7328C4.66932 12.5842 4.8296 12.1718 4.92146 12.035C5.01333 11.8981 5.17361 11.7261 5.17361 11.7261C5.17361 11.7261 4.82764 11.2766 4.2784 11.2883Z" fill="#925849"/>
            <path d="M8.48272 14.0486C8.41431 14.1287 7.44092 14.9184 7.44092 14.9184C7.44092 14.9184 7.4761 15.6064 7.69306 15.7667C7.91002 15.9269 8.58631 16.1556 9.02218 16.2475C9.45806 16.3393 10.236 16.4429 10.4314 16.3628C10.6269 16.2827 10.8321 15.8585 10.8439 15.3093C10.8556 14.76 10.6601 14.3496 10.4432 14.0974C10.2262 13.8453 10.0523 13.7515 10.0523 13.7515L8.48272 14.0486Z" fill="#593329"/>
            <path d="M9.77721 15.0785C9.90426 14.9358 10.0528 13.7493 10.0528 13.7493C10.0528 13.7493 9.17129 13.0731 8.80382 13.0281C8.43636 12.9831 8.00244 13.0164 7.87539 13.1532C7.7503 13.29 7.25774 13.7826 7.30269 14.1031C7.34765 14.4237 7.43952 14.9162 7.43952 14.9162C7.43952 14.9162 9.51334 15.3775 9.77721 15.0785Z" fill="#925849"/>
            <path d="M14.0495 15.6436C14.0495 15.6436 13.9381 16.3863 14.7552 16.527C15.5722 16.6658 16.2504 16.8515 16.6042 16.7772C16.958 16.7029 17.3098 16.2299 17.3469 15.8859C17.3841 15.5419 17.4036 15.0455 17.3469 14.9106C17.1984 14.5529 16.5104 13.5736 16.1761 13.4701C15.9885 13.4134 15.8106 13.4446 15.8106 13.4446L14.0495 15.6436Z" fill="#593329"/>
            <path d="M19.266 9.29883C19.266 9.29883 19.5885 9.34574 19.9853 9.5842C20.4486 9.86175 20.9333 10.5693 20.8962 11.1459C20.859 11.7225 20.3567 11.9825 19.7449 11.9923C19.1331 12.002 18.535 11.9688 18.4236 11.6522C18.3122 11.3355 19.266 9.29883 19.266 9.29883Z" fill="#593329"/>
            <path d="M18.7775 9.49248C18.6818 9.62539 18.4335 10.0319 18.3495 10.5519C18.2654 11.0718 18.3221 11.666 18.5078 11.7696C18.6935 11.8712 19.7157 10.9897 19.7705 10.8959C19.8272 10.8021 19.4441 9.38107 19.3229 9.31657C19.2017 9.25207 18.8987 9.32634 18.7775 9.49248Z" fill="#925849"/>
            <path d="M14.3729 14.3685C14.0074 14.7731 13.8197 15.3576 13.8393 15.6664C13.8588 15.9576 15.2446 15.8306 15.4088 15.7309C15.573 15.6312 15.9307 15.1856 15.9424 15.037C15.9541 14.8885 15.8408 14.4233 15.8212 14.1359C15.8017 13.8486 15.7939 13.4577 15.6355 13.4675C15.4772 13.4772 14.8928 13.7939 14.3729 14.3685Z" fill="#925849"/>
            <defs>
                <radialGradient
                    id="paint0_radial_1284_815"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11.785 10.1308) scale(10.3988 10.3988)"
                >
                    <stop offset="0.507" stopColor="#F29F58"/>
                    <stop offset="0.715" stopColor="#F09D56"/>
                    <stop offset="0.826" stopColor="#EB9550"/>
                    <stop offset="0.914" stopColor="#E18745"/>
                    <stop offset="0.99" stopColor="#D47436"/>
                    <stop offset="1" stopColor="#D27133"/>
                </radialGradient>
            </defs>
        </svg>
    );
};

export default SvgCookieIcon;
