import {StatConsent} from "../../../api/model/stat/consent/StatConsent";

export const STAT_DATE_FORMAT = "YYYY-MM-DD";

export const convertRateToPercent = (value: number): number => {
    return parseFloat((value * 100).toFixed(2)) || 0;
};

export const countStats = (stats: StatConsent[]): number => {
    return stats.reduce((a, b) => a + b.count, 0);
};
