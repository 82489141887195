import {Color} from "@sirdata/ui-lib";

function SvgTada() {
    return (
        <svg
            width="129"
            height="130"
            viewBox="0 0 84 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="39.4809" cy="43.5192" r="39.4809" fill={Color.GREY_LIGHT}/>
            <path d="M36.7737 13.6663C36.5687 13.8795 36.4014 14.126 36.2791 14.3953L36.2643 14.3806L15.563 61.0122L15.5833 61.0325C15.1993 61.7763 15.8417 63.2898 17.1576 64.6076C18.4718 65.9217 19.9852 66.564 20.7291 66.1801L20.7475 66.1986L67.3828 45.4991L67.3681 45.4824C67.6412 45.3532 67.8885 45.1964 68.0971 44.9878C70.9801 42.1048 66.3049 32.7563 57.656 24.1074C49.007 15.4566 39.6567 10.7833 36.7737 13.6663Z" fill={Color.YELLOW}/>
            <path d="M39.3097 21.9942L16.0834 59.8421L15.563 61.0141L15.5833 61.0326C15.1993 61.7782 15.8417 63.2899 17.1576 64.6077C17.5534 64.999 17.9924 65.3439 18.4662 65.6357L46.6925 31.2227L39.3097 21.9942Z" fill="#FFE298"/>
            <path d="M57.7889 23.9616C66.4083 32.5847 71.1942 41.7763 68.4736 44.4951C65.7549 47.2138 56.5633 42.4297 47.9384 33.8103C39.3171 25.1872 34.5331 15.9937 37.2518 13.2732C39.9723 10.5545 49.1639 15.3404 57.7889 23.9616Z" fill="#D19500"/>
            <path d="M49.6272 24.9656C49.439 25.1183 49.2223 25.232 48.9897 25.3001C48.7571 25.3682 48.5133 25.3894 48.2725 25.3624C46.6704 25.1889 45.323 24.6315 44.3799 23.7511C43.3813 22.819 42.8885 21.5695 43.0233 20.3144C43.2595 18.1143 45.467 16.0951 49.2304 16.503C50.694 16.6599 51.3474 16.1893 51.3677 15.9641C51.3935 15.7408 50.8564 15.1409 49.3946 14.9822C47.7907 14.8087 46.4452 14.2513 45.5002 13.3709C44.5017 12.4388 44.007 11.1874 44.1436 9.93419C44.3836 7.73412 46.5892 5.71492 50.3489 6.12282C51.4157 6.23725 51.9768 6.01577 52.2149 5.87365C52.4068 5.75737 52.4825 5.64663 52.4899 5.58388C52.5102 5.36055 51.9805 4.76069 50.5131 4.60196C50.034 4.54034 49.5982 4.29334 49.2991 3.91398C49.0001 3.53462 48.8617 3.05315 48.9136 2.57289C48.9655 2.09264 49.2037 1.65188 49.5769 1.34523C49.9502 1.03858 50.4288 0.89048 50.91 0.932711C54.6697 1.33692 56.3972 3.77878 56.1591 5.9807C55.921 8.18078 53.7154 10.1981 49.952 9.79207C48.8852 9.67579 48.326 9.89728 48.0879 10.0394C47.8978 10.1557 47.8203 10.2683 47.8129 10.3292C47.7889 10.5543 48.3223 11.1542 49.7896 11.3129C53.5493 11.7171 55.2769 14.159 55.0388 16.3609C54.8007 18.561 52.5932 20.5802 48.8335 20.1723C47.7667 20.0579 47.2056 20.2775 46.9657 20.4196C46.7756 20.5359 46.7018 20.6485 46.6944 20.7112C46.6704 20.9346 47.2038 21.5344 48.6693 21.6931C49.033 21.7309 49.3773 21.8762 49.6582 22.1103C49.9391 22.3444 50.144 22.6568 50.2467 23.0078C50.3495 23.3588 50.3454 23.7324 50.2351 24.081C50.1247 24.4297 49.9131 24.7376 49.6272 24.9656Z" fill={Color.CYAN}/>
            <path d="M71.9066 42.0329C75.5482 41.0048 78.0602 42.6291 78.6582 44.7608C79.2562 46.8908 77.9605 49.5873 74.3227 50.6099C72.9015 51.0085 72.4751 51.6878 72.5323 51.9055C72.5951 52.1233 73.3168 52.4814 74.7343 52.079C78.374 51.0565 80.886 52.6807 81.484 54.8107C82.0875 56.9425 80.7881 59.6353 77.1466 60.6615C75.7272 61.0602 75.299 61.7413 75.3618 61.9572C75.4227 62.1732 76.1425 62.5294 77.5619 62.1326C77.7973 62.0598 78.045 62.0349 78.2903 62.0593C78.5355 62.0838 78.7734 62.1572 78.9898 62.2751C79.2063 62.3929 79.3969 62.553 79.5505 62.7458C79.7041 62.9385 79.8175 63.1601 79.8841 63.3974C79.9507 63.6347 79.9691 63.883 79.9381 64.1275C79.9072 64.372 79.8276 64.6079 79.7041 64.8211C79.5805 65.0344 79.4155 65.2208 79.2188 65.3692C79.022 65.5177 78.7975 65.6252 78.5585 65.6855C74.9225 66.7099 72.4068 65.0894 71.8051 62.9558C71.2071 60.8258 72.5046 58.1329 76.1481 57.1067C77.5674 56.7062 77.9956 56.0288 77.9329 55.811C77.8719 55.5951 77.154 55.2352 75.7346 55.6339C72.0931 56.6601 69.5811 55.0395 68.9812 52.9059C68.3814 50.7741 69.6789 48.0813 73.3223 47.055C74.7379 46.6582 75.1661 45.9753 75.1052 45.7612C75.0425 45.5416 74.3264 45.1835 72.9052 45.584C72.6689 45.66 72.4198 45.6876 72.1726 45.6652C71.9255 45.6427 71.6854 45.5706 71.4668 45.4532C71.2481 45.3359 71.0554 45.1756 70.9001 44.982C70.7448 44.7885 70.6301 44.5656 70.563 44.3267C70.4958 44.0878 70.4775 43.8378 70.5091 43.5916C70.5408 43.3455 70.6218 43.1083 70.7472 42.8941C70.8726 42.68 71.04 42.4934 71.2392 42.3454C71.4384 42.1974 71.6654 42.0911 71.9066 42.0329Z" fill={Color.DANGER_MAIN}/>
            <path d="M57.7686 37.0549C57.3864 37.0544 57.0137 36.9354 56.702 36.7142C56.3903 36.493 56.1548 36.1806 56.0281 35.82C55.9014 35.4594 55.8896 35.0684 55.9944 34.7009C56.0991 34.3333 56.3153 34.0073 56.6132 33.7677C57.0155 33.4447 66.6132 25.9069 80.1772 27.8467C80.4171 27.8809 80.6479 27.962 80.8564 28.0854C81.065 28.2089 81.2471 28.3722 81.3925 28.5661C81.5378 28.7599 81.6436 28.9806 81.7036 29.2153C81.7636 29.4501 81.7768 29.6944 81.7424 29.9342C81.7092 30.1747 81.6286 30.4062 81.5053 30.6154C81.382 30.8245 81.2185 31.0071 81.0242 31.1527C80.8299 31.2982 80.6087 31.4038 80.3734 31.4633C80.138 31.5228 79.8932 31.5351 79.653 31.4994C67.6689 29.7976 59.0052 36.5824 58.9203 36.6507C58.5938 36.913 58.1874 37.0556 57.7686 37.0549Z" fill={Color.CYAN}/>
            <path d="M25.9358 29.377C25.6479 29.3771 25.364 29.3098 25.1067 29.1806C24.8494 29.0514 24.626 28.8638 24.4541 28.6328C24.2823 28.4018 24.1669 28.1338 24.1172 27.8502C24.0674 27.5667 24.0847 27.2754 24.1676 26.9997C26.2588 20.0359 28.1543 8.9229 25.825 6.027C25.563 5.69846 25.1717 5.37547 24.271 5.44376C22.5379 5.5748 22.704 9.22929 22.7058 9.2662C22.7293 9.51072 22.7036 9.75746 22.6305 9.99196C22.5574 10.2265 22.4381 10.444 22.2798 10.6318C22.1215 10.8197 21.9273 10.974 21.7086 11.0858C21.4899 11.1976 21.251 11.2646 21.0061 11.2829C20.7611 11.3012 20.515 11.2704 20.2821 11.1924C20.0491 11.1143 19.8342 10.9906 19.6497 10.8284C19.4652 10.6661 19.315 10.4687 19.2078 10.2477C19.1006 10.0267 19.0386 9.7865 19.0255 9.54121C18.8335 6.99599 19.6272 2.09381 23.9923 1.76343C25.9413 1.61577 27.56 2.29315 28.7043 3.71434C33.0805 9.161 28.6379 24.9528 27.7021 28.0628C27.5879 28.4425 27.3545 28.7754 27.0363 29.0121C26.7182 29.2488 26.3323 29.3767 25.9358 29.377Z" fill={Color.DANGER_MAIN}/>
            <path d="M65.1495 17.3799C65.1494 17.7436 65.0777 18.1037 64.9384 18.4396C64.7991 18.7756 64.595 19.0808 64.3377 19.3379C64.0805 19.595 63.7751 19.7989 63.439 19.938C63.103 20.077 62.7428 20.1486 62.3791 20.1484C62.0154 20.1483 61.6553 20.0766 61.3194 19.9373C60.9834 19.798 60.6782 19.5939 60.4211 19.3366C60.164 19.0794 59.9601 18.774 59.821 18.4379C59.682 18.1019 59.6105 17.7417 59.6106 17.378C59.6108 16.6435 59.9028 15.9392 60.4224 15.42C60.9419 14.9008 61.6465 14.6092 62.381 14.6095C63.1155 14.6097 63.8198 14.9018 64.339 15.4213C64.8582 15.9409 65.1498 16.6454 65.1495 17.3799Z" fill={Color.CORAL}/>
            <path d="M22.6984 33.0684C22.6983 33.5532 22.6027 34.0334 22.417 34.4813C22.2313 34.9292 21.9593 35.3362 21.6163 35.679C21.2734 36.0217 20.8663 36.2936 20.4182 36.4791C19.9702 36.6645 19.4901 36.7599 19.0052 36.7598C18.5203 36.7596 18.0402 36.664 17.5923 36.4784C17.1443 36.2927 16.7374 36.0206 16.3946 35.6777C16.0518 35.3347 15.7799 34.9276 15.5945 34.4796C15.409 34.0316 15.3137 33.5514 15.3138 33.0665C15.314 32.0873 15.7033 31.1482 16.3959 30.4559C17.0885 29.7637 18.0278 29.3749 19.007 29.3751C19.9863 29.3754 20.9254 29.7646 21.6176 30.4572C22.3099 31.1498 22.6987 32.0891 22.6984 33.0684Z" fill={Color.CYAN}/>
            <path d="M78.0694 35.8369C78.0693 36.2006 77.9976 36.5607 77.8583 36.8967C77.719 37.2326 77.5149 37.5379 77.2576 37.795C77.0004 38.052 76.695 38.2559 76.3589 38.395C76.0229 38.5341 75.6627 38.6056 75.299 38.6055C74.9353 38.6053 74.5752 38.5336 74.2393 38.3943C73.9033 38.255 73.5981 38.0509 73.341 37.7937C73.0839 37.5364 72.88 37.231 72.7409 36.895C72.6019 36.5589 72.5304 36.1988 72.5305 35.8351C72.5307 35.1006 72.8227 34.3962 73.3423 33.877C73.8618 33.3578 74.5664 33.0663 75.3009 33.0665C76.0354 33.0668 76.7397 33.3588 77.2589 33.8783C77.7781 34.3979 78.0697 35.1024 78.0694 35.8369Z" fill={Color.CORAL}/>
            <path d="M61.4581 57.9851C61.458 58.3488 61.3863 58.7089 61.247 59.0449C61.1077 59.3808 60.9036 59.6861 60.6463 59.9432C60.3891 60.2002 60.0837 60.4041 59.7476 60.5432C59.4116 60.6823 59.0514 60.7538 58.6877 60.7537C58.324 60.7535 57.9639 60.6818 57.628 60.5425C57.292 60.4032 56.9868 60.1991 56.7297 59.9418C56.4726 59.6846 56.2687 59.3792 56.1296 59.0432C55.9906 58.7071 55.9191 58.347 55.9192 57.9833C55.9194 57.2488 56.2115 56.5444 56.731 56.0252C57.2506 55.506 57.9551 55.2145 58.6896 55.2147C59.4241 55.215 60.1284 55.507 60.6476 56.0265C61.1668 56.5461 61.4584 57.2506 61.4581 57.9851Z" fill={Color.CORAL}/>
            <path d="M70.6866 7.22852C70.6865 7.7134 70.5909 8.19351 70.4052 8.64144C70.2195 9.08937 69.9475 9.49634 69.6045 9.83912C69.2616 10.1819 68.8545 10.4538 68.4064 10.6392C67.9584 10.8247 67.4783 10.92 66.9934 10.9199C66.5085 10.9198 66.0284 10.8242 65.5805 10.6385C65.1325 10.4528 64.7256 10.1808 64.3828 9.83781C64.04 9.49486 63.7681 9.08776 63.5827 8.63974C63.3973 8.19172 63.3019 7.71156 63.302 7.22668C63.3022 6.24741 63.6915 5.30835 64.3841 4.61608C65.0767 3.92381 66.016 3.53503 66.9952 3.53527C67.9745 3.53552 68.9136 3.92477 69.6058 4.61738C70.2981 5.31 70.6869 6.24926 70.6866 7.22852Z" fill={Color.GREEN}/>
            <path d="M78.0694 15.5342C78.0693 15.8979 77.9976 16.258 77.8583 16.5939C77.719 16.9299 77.5149 17.2351 77.2576 17.4922C77.0004 17.7493 76.695 17.9532 76.3589 18.0923C76.0229 18.2313 75.6627 18.3029 75.299 18.3027C74.9353 18.3026 74.5752 18.2309 74.2393 18.0916C73.9033 17.9523 73.5981 17.7482 73.341 17.4909C73.0839 17.2337 72.88 16.9283 72.7409 16.5922C72.6019 16.2562 72.5304 15.896 72.5305 15.5323C72.5307 14.7978 72.8227 14.0935 73.3423 13.5743C73.8618 13.0551 74.5664 12.7635 75.3009 12.7638C76.0354 12.764 76.7397 13.056 77.2589 13.5756C77.7781 14.0951 78.0697 14.7997 78.0694 15.5342Z" fill={Color.GREEN}/>
            <path d="M72.5323 22.917C72.5322 23.2807 72.4604 23.6408 72.3212 23.9768C72.1819 24.3127 71.9778 24.618 71.7205 24.875C71.4633 25.1321 71.1579 25.336 70.8218 25.4751C70.4858 25.6142 70.1256 25.6857 69.7619 25.6855C69.3982 25.6854 69.0381 25.6137 68.7022 25.4744C68.3662 25.3351 68.061 25.131 67.8039 24.8737C67.5468 24.6165 67.3429 24.3111 67.2038 23.9751C67.0648 23.639 66.9933 23.2788 66.9934 22.9152C66.9936 22.1806 67.2856 21.4763 67.8052 20.9571C68.3247 20.4379 69.0293 20.1464 69.7638 20.1466C70.4983 20.1468 71.2026 20.4389 71.7218 20.9584C72.241 21.478 72.5326 22.1825 72.5323 22.917Z" fill={Color.GREEN}/>
            <path d="M31.9269 43.2197C31.9268 43.5834 31.8551 43.9435 31.7158 44.2795C31.5765 44.6155 31.3724 44.9207 31.1151 45.1778C30.8579 45.4349 30.5525 45.6388 30.2164 45.7778C29.8804 45.9169 29.5202 45.9884 29.1565 45.9883C28.7928 45.9882 28.4327 45.9164 28.0968 45.7771C27.7608 45.6378 27.4556 45.4337 27.1985 45.1765C26.9414 44.9192 26.7375 44.6138 26.5984 44.2778C26.4594 43.9417 26.3879 43.5816 26.388 43.2179C26.3882 42.4834 26.6802 41.779 27.1998 41.2598C27.7193 40.7406 28.4239 40.4491 29.1584 40.4493C29.8929 40.4496 30.5972 40.7416 31.1164 41.2611C31.6356 41.7807 31.9272 42.4852 31.9269 43.2197Z" fill={Color.GREEN}/>
        </svg>
    );
}

export default SvgTada;
