import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {Action, ButtonLink, ButtonLinkStyle, ButtonValidate, Color, FlexContentJustify, FormLayoutButtons, FormLayoutRows, Icon, IconTooltip, ModalContent, ModalNew, ModalTitle, ModalTitleAlignment, ToggleSwitch, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import ImageSelector from "./ImageSelector";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {TranslationPortalFile} from "../../../utils/constants";

type ImageManagerProps = {
    field: CmpConfigThemeModeField.LOGO | CmpConfigThemeModeField.SKIN | CmpConfigThemeModeField.WATERMARK;
    mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE;
    currentImage: string;
    onSave: (newImage: string, type: CmpConfigThemeModeField, mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, applyBothMode?: boolean) => void;
    onRemove: (type: CmpConfigThemeModeField, mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE) => void;
    maxFileSize?: number;
    hasApplyBothModes?: boolean;
    minifyActions?: boolean;
};

const ImageManager: React.FC<ImageManagerProps> = ({field, mode, currentImage, onSave, onRemove, maxFileSize, hasApplyBothModes = false, minifyActions = false}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [isShowEdition, setShowEdition] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [applyBothModes, setApplyBothModes] = useState(mode === CmpConfigThemeField.LIGHT_MODE);

    useEffect(() => {
        setSelectedImage(currentImage);
    }, [currentImage]);

    const handleValidate = () => {
        if (selectedImage !== currentImage) {
            onSave(selectedImage, field, mode, applyBothModes);
        }
        setShowEdition(false);
    };

    return (
        <div className="image-manager">
            <div className="image-manager__preview" onClick={() => setShowEdition(true)}>
                <div
                    className="image-manager__preview__content"
                    style={{
                        backgroundImage: `url(${currentImage})`,
                        backgroundColor: currentImage ? Color.WHITE : Color.GREY_LIGHT
                    }}
                >
                    {!currentImage && <Icon {...Action.ADD.icon} cssClass="image-manager__preview__content__icon"/>}
                </div>
            </div>
            {currentImage &&
                <div className={clsx("image-manager__actions", {"image-manager__actions--minified": minifyActions})}>
                    {minifyActions ?
                        <>
                            <IconTooltip icon={{...Action.EDIT.icon, colorIcon: Color.MIDNIGHT_LIGHT}} text={textCommon(Action.EDIT.labelKey)} onClick={() => setShowEdition(true)}/>
                            <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={() => onRemove(field, mode)}/>
                        </> :
                        <>
                            <ButtonLink icon={Action.EDIT.icon} reverse style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={() => setShowEdition(true)}>
                                {textCommon(Action.EDIT.labelKey)}
                            </ButtonLink>
                            <ButtonLink icon={Action.REMOVE.icon} reverse style={ButtonLinkStyle.DANGER} onClick={() => onRemove(field, mode)}>
                                {textCommon(Action.REMOVE.labelKey)}
                            </ButtonLink>
                        </>
                    }
                </div>
            }
            <ModalNew active={isShowEdition} onClose={() => setShowEdition(false)} keepMounted>
                <ModalContent>
                    <ModalTitle alignment={ModalTitleAlignment.LEFT}>{textConfiguration(`image.edit_${field}`)}</ModalTitle>
                    <FormLayoutRows>
                        <ImageSelector
                            field={field}
                            currentImage={selectedImage}
                            onChange={(newImage) => setSelectedImage(newImage)}
                            maxFileSize={maxFileSize}
                            allowCustomImage={[CmpConfigThemeModeField.LOGO, CmpConfigThemeModeField.SKIN].includes(field)}
                        />
                        {hasApplyBothModes ?
                            <FormLayoutButtons justify={FlexContentJustify.SPACE_BETWEEN}>
                                <ToggleSwitch
                                    label={textConfiguration("image.apply_to_mode", {mode: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${mode === CmpConfigThemeField.LIGHT_MODE ? CmpConfigThemeField.DARK_MODE : CmpConfigThemeField.LIGHT_MODE}`)})}
                                    name="toggle-switch-mode"
                                    checked={applyBothModes}
                                    onChange={setApplyBothModes}
                                />
                                <ButtonValidate onClick={handleValidate}/>
                            </FormLayoutButtons> :
                            <FormLayoutButtons>
                                <ButtonValidate onClick={handleValidate}/>
                            </FormLayoutButtons>
                        }
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </div>
    );
};

export default ImageManager;
