import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {session} from "../api/ApiSession";
import {QueryString} from "../common/api/model/QueryString";
import {DEFAULT_PATH, pathConfigurationMode, pathIndex} from "../utils/constants";

function Sso() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const queryString = location.search;
                const token = QueryString.getToken(queryString);
                const remember = QueryString.getRemember(queryString);
                const goTo = QueryString.getGoTo(queryString);

                await session.loginWithToken(token, remember);
                await session.loadAccount();

                if (goTo) {
                    window.location.href = goTo;
                } else if (QueryString.getRegister(queryString)) {
                    navigate(pathConfigurationMode);
                } else navigate(DEFAULT_PATH);
            } catch (e) {
                navigate(pathIndex);
            }
        })();
    }, [navigate, location.search]);

    return <></>;
}

export default Sso;
