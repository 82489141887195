import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {Color, FlexContent, FlexContentAlignment, FlexContentDirection, Icon, Paragraph, ParagraphAlignment, Tooltip} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpPreviewHelper} from "../../../utils/CmpPreviewHelper";
import {CmpConfigVersion} from "../../../api/model/cmp/config/CmpConfigVersion";
import {detectChanges} from "../../../common/utils/portal";
import {TranslationPortalFile} from "../../../utils/constants";

enum PreviewMode {
    LIGHT = "LIGHT",
    DARK = "DARK"
}

enum PreviewDevice {
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE"
}

type PreviewProps = {
    cmpConfig: CmpConfig;
    withTitle?: boolean;
    withActions?: boolean;
    isCCPA?: boolean;
    hideBrowserBar?: boolean;
};

const Preview: FunctionComponent<PreviewProps> = ({cmpConfig, withTitle, withActions, isCCPA, hideBrowserBar}) => {
    const {t: textPreview} = useTranslation(TranslationPortalFile.PREVIEW);
    const [isLoading, setLoading] = useState(true);
    const [mode, setMode] = useState<PreviewMode>(PreviewMode.LIGHT);
    const [device, setDevice] = useState<PreviewDevice>(PreviewDevice.DESKTOP);
    const [previewMessage, setPreviewMessage] = useState<{}>({});

    const iframeRef = useRef<HTMLIFrameElement>(null);
    let timeoutRef = useRef<NodeJS.Timeout | undefined>();

    const refreshPreview = useCallback((previewMessage: {}) => {
        setPreviewMessage(previewMessage);
        iframeRef.current?.contentWindow?.postMessage(previewMessage, "*");
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        timeoutRef.current = setTimeout(() => {
            const message = cmpConfig.getPreviewMessage(mode === PreviewMode.DARK);

            if (detectChanges(message, previewMessage)) {
                refreshPreview(message);
            }
            timeoutRef.current = undefined;
        }, 300);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isLoading, cmpConfig, mode, previewMessage, refreshPreview]);

    return (
        <div className="preview">
            <div className="preview__header">
                {withTitle && <span className="h2">{textPreview("preview")}</span>}
                {withActions &&
                    <div className="preview__actions">
                        <div className="preview__toggle">
                            {Object.values(PreviewDevice).map((it) =>
                                <Tooltip
                                    key={it}
                                    cssClass={clsx("preview__toggle__button", {"preview__toggle__button--active": device === it})}
                                    text={textPreview(`device.${it}`)}
                                    onClick={() => setDevice(it)}
                                >
                                    {it === PreviewDevice.DESKTOP ? <Icon name="computer" outlined/> : <Icon name="smartphone"/>}
                                </Tooltip>
                            )}
                        </div>
                        <div className="preview__toggle">
                            {Object.values(PreviewMode).map((it) =>
                                <Tooltip
                                    key={it}
                                    cssClass={clsx("preview__toggle__button", {"preview__toggle__button--active": mode === it})}
                                    text={textPreview(`mode.${it}`)}
                                    onClick={() => setMode(it)}
                                >
                                    <Icon name={it === PreviewMode.LIGHT ? "wb_sunny" : "brightness_2"}/>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                }
            </div>
            <div className={clsx("preview__container", {"preview__container--mobile": device === PreviewDevice.MOBILE})}>
                {!hideBrowserBar &&
                    <div className="preview__container__header">
                        <Icon name="circle" colorIcon={Color.CORAL}/>
                        <Icon name="circle" colorIcon={Color.YELLOW}/>
                        <Icon name="circle" colorIcon={Color.LIME}/>
                    </div>
                }
                <div className="preview__container__iframe">
                    <iframe
                        ref={iframeRef}
                        title={`preview-${isCCPA ? "ccpa" : "gdpr"}`}
                        src={CmpPreviewHelper.getUrlForVersion(CmpConfigVersion.DEFAULT, isCCPA)}
                        scrolling="yes"
                        height="700"
                        onLoad={() => setLoading(false)}
                    />
                </div>
                <div className="preview__container__iframe__no-preview">
                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER}>
                        <Icon name="visibility_off" colorIcon={Color.MIDNIGHT_LIGHT}/>
                        <Paragraph alignment={ParagraphAlignment.CENTER}>{textPreview("message.desktop_only")}</Paragraph>
                    </FlexContent>
                </div>
            </div>
        </div>
    );
};

export default Preview;
