import React from "react";
import {Box, BoxRadius, BoxShadow, FlexContent, FlexContentDirection, FlexContentJustify, FlexContentAlignment, Icon, Color, FlexContentSpacing, Action} from "@sirdata/ui-lib";

type ModuleDownloadProps = {
    file: {
        name: string;
        src: string;
    };
}

const ModuleDownload: React.FC<ModuleDownloadProps> = ({file}) => {
    return (
        <Box radius={BoxRadius.MD} shadow={BoxShadow.BLACK_MEDIUM} cssClass="module-download" onClick={() => window.open(file.src)}>
            <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.SPACE_BETWEEN} alignment={FlexContentAlignment.CENTER}>
                <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                    <Icon name="attach_file" colorIcon={Color.GREY_DARK}/>
                    <span className="h3">{file.name}</span>
                </FlexContent>
                <Icon {...Action.DOWNLOAD.icon} cssClass="module-download__icon-download"/>
            </FlexContent>
        </Box>
    );
};

export default ModuleDownload;
