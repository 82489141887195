import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class CmpConfigPublisherCustomPurposeTranslation extends ApiModel {
    name: string = "";
    description: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            description: this.description
        };
    }
}
