import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpVersion extends ApiModel {
    version: string = "";
    date: string = "";
    text_fr: string = "";
    text_en: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
