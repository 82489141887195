import {Rest} from "../../common/api/rest/Rest";
import {IntegrationProcessMailRequest} from "../model/mailing/IntegrationProcessMailRequest";

export class RestMailing extends Rest {
    rootPath = "/frontend-api/mailing";

    sendIntegrationProcess(request: IntegrationProcessMailRequest) {
        return this._client.post(`${this.rootPath}/integration-process`, request);
    }
}
