import {FunctionComponent, useState} from "react";
import clsx from "clsx";
import {Accordion, AccordionItem, AccordionItemContent, Box, BoxRadius, Button, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Icon, Tag, TagSize, TagStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import ModalCrossSalesContact from "../modal/ModalCrossSalesContact";
import {CrossSalesProduct} from "../../utils/CrossSalesProduct";
import {TranslationPortalFile} from "../../utils/constants";

type CrossSalesProps = {
    product?: CrossSalesProduct;
    cssClass?: string;
}

const CrossSales: FunctionComponent<CrossSalesProps> = ({product, cssClass}) => {
    const {t: textCrossSales} = useTranslation(TranslationPortalFile.CROSS_SALES);
    const [isShowModalCrossSalesContact, setShowModalCrossSalesContact] = useState(false);

    return (
        <Box radius={BoxRadius.LG} cssClass={clsx("cross-sales", cssClass)}>
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL}>
                    <Icon name="control_point_duplicate" outlined colorIcon={Color.GREEN}/>
                    <span className="h2 cross-sales__title">{textCrossSales("title")}</span>
                </FlexContent>
                <span>{textCrossSales("subtitle")}</span>
                <Accordion>
                    {Object.keys(CrossSalesProduct).filter((it) => !product || it === product).map((it, index) =>
                        <AccordionItem
                            key={it}
                            startOpen={index === 0}
                            heading={
                                <FlexContent
                                    cssClass="h3"
                                    direction={FlexContentDirection.ROW}
                                    spacing={FlexContentSpacing.XSMALL}
                                    alignment={FlexContentAlignment.CENTER}
                                >
                                    <span>{textCrossSales(`product.${it}.name`)}</span>
                                    <Tag label={textCrossSales(`product.${it}.price`)} size={TagSize.XSMALL} style={TagStyle.DEFAULT_OCEAN}/>
                                </FlexContent>
                            }
                        >
                            <AccordionItemContent cssClass="cross-sales__accordion-content">
                                <span dangerouslySetInnerHTML={{__html: textCrossSales(`product.${it}.description`)}}/>
                            </AccordionItemContent>
                        </AccordionItem>
                    )}
                </Accordion>
                <Button onClick={() => setShowModalCrossSalesContact(true)}>{textCrossSales("action.interested")}</Button>
            </FlexContent>
            <ModalCrossSalesContact
                active={isShowModalCrossSalesContact}
                product={product}
                onClose={() => setShowModalCrossSalesContact(false)}
            />
        </Box>
    );
};

export default CrossSales;
