import {ApiModel} from "../../../../common/api/model/ApiModel";

export class StatConsentDailyRatings extends ApiModel {
    consent: number[] = [];
    object: number[] = [];
    no_choice: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

