import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpConfigText extends ApiModel {
    static MAIN_TITLE = "main.title";
    static MAIN_CUSTOM_DESCRIPTION = "main.customDescription";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static keys(): string[] {
        return [
            this.MAIN_TITLE,
            this.MAIN_CUSTOM_DESCRIPTION
        ];
    }

    getJsonParameters(): {} {
        const values = new Map<string, string>();
        for (let key in this) {
            values.set(key, this[key]);
        }
        return Object.fromEntries(values);
    }

    entries(): { key: string; value: any }[] {
        let entries: { key: string; value: string }[] = [];
        for (let key in this) {
            if (this[key] !== undefined) {
                entries.push({key: key, value: this[key]});
            }
        }
        return entries;
    }
}
