import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpConfigScope} from "./CmpConfigScope";
import {CmpConfigCapping} from "./CmpConfigCapping";
import {CmpConfigText} from "./CmpConfigText";
import {CmpConfigTheme} from "./theme/CmpConfigTheme";
import {CmpConfigVendorList} from "./CmpConfigVendorList";
import {CmpConfigPublisherRestriction} from "./CmpConfigPublisherRestriction";
import {CmpConfigPublisher} from "./publisher/CmpConfigPublisher";
import {CmpConfigCCPA} from "./CmpConfigCCPA";
import {CmpConfigExternal} from "./CmpConfigExternal";
import {CmpConfigCookieWall} from "./CmpConfigCookieWall";
import {CmpConfigSettingsField} from "./CmpConfigSettingsField";
import {CmpConfigLanguage} from "./CmpConfigLanguage";

export const MIN_COOKIE_MAX_AGE_IN_DAYS = 0;
export const MAX_COOKIE_MAX_AGE_IN_DAYS = 390;
export const DEFAULT_COOKIE_MAX_AGE_IN_DAYS = 180;
export const MIN_CAPPING_IN_DAYS = 1;
export const MAX_CAPPING_IN_DAYS = 365;
export const DEFAULT_CAPPING_IN_DAYS = 1;

export class CmpConfigSettings extends ApiModel {
    logo: string = "";
    logoDark: string = "";
    privacyPolicy: string = "";
    language: string = "";
    defaultLanguage: string = "";
    scope: string = CmpConfigScope.DOMAIN.name;
    tcStringLocation: string = "";
    cookieDomain: string = "";
    domainList: string = "";
    cookieMaxAgeInDays: number = DEFAULT_COOKIE_MAX_AGE_IN_DAYS;
    capping: string = CmpConfigCapping.CHECK.name;
    cappingInDays: number = DEFAULT_CAPPING_IN_DAYS;
    forceGdpr: boolean = false;
    _texts: Map<string, CmpConfigText> | undefined;
    theme: CmpConfigTheme = new CmpConfigTheme();
    vendorList: CmpConfigVendorList = new CmpConfigVendorList();
    publisherRestrictions: CmpConfigPublisherRestriction[] | undefined;
    publisherPurposes: CmpConfigPublisher | undefined;
    cookieWall: CmpConfigCookieWall = new CmpConfigCookieWall();
    disableTcf: boolean = false;
    flexibleRefusal: boolean = true;
    ccpa: CmpConfigCCPA = new CmpConfigCCPA();
    external: CmpConfigExternal = new CmpConfigExternal();

    constructor(o?: {}) {
        super(o);
        this.load(o);
        if (o === undefined) {
            this.defaultLanguage = CmpConfigLanguage.ENGLISH.name;
            this.scope = CmpConfigScope.DOMAIN.name;
            this.forceGdpr = true;
        }
        this.loadObject(CmpConfigSettingsField.THEME, CmpConfigTheme);
        this.loadObject(CmpConfigSettingsField.VENDORLIST, CmpConfigVendorList);
        this.loadObject(CmpConfigSettingsField.PUBLISHER_PURPOSES, CmpConfigPublisher);
        this.loadObject(CmpConfigSettingsField.COOKIEWALL, CmpConfigCookieWall);
        this.loadObject(CmpConfigSettingsField.CCPA, CmpConfigCCPA);
        this.loadObject(CmpConfigSettingsField.EXTERNAL, CmpConfigExternal);
    }

    get texts(): Map<string, CmpConfigText> | undefined {
        return this._texts;
    }

    set texts(t: Map<string, CmpConfigText> | undefined) {
        if (!t) {
            this._texts = t;
            return;
        }

        this._texts = Object.entries(t).length ? new Map(Object.entries(t)) : t;
    }

    getTextsEntriesForLanguage(language: string) {
        const languageTexts = this.texts?.get(language);
        return languageTexts ? new CmpConfigText(languageTexts).entries() : [];
    }

    getTextsJson(): { [key: string]: object } | undefined {
        if (!this._texts) return undefined;

        let t: { [key: string]: object } = {};
        this._texts.forEach((value, key) => {
            t[key] = value.getJsonParameters ? value.getJsonParameters() : value;
        });

        return t;
    }

    getJsonParameters(): {} {
        return {
            logo: this.logo,
            logoDark: this.logoDark,
            privacyPolicy: this.privacyPolicy,
            language: this.language,
            defaultLanguage: this.defaultLanguage,
            scope: this.scope,
            tcStringLocation: this.tcStringLocation,
            cookieDomain: this.cookieDomain,
            domainList: this.domainList,
            cookieMaxAgeInDays: this.cookieMaxAgeInDays,
            capping: this.capping,
            cappingInDays: this.cappingInDays,
            forceGdpr: this.forceGdpr,
            texts: this.getTextsJson(),
            theme: this.theme.getJsonParameters(),
            vendorList: this.vendorList.getJsonParameters(),
            publisherRestrictions: this.publisherRestrictions,
            publisherPurposes: this.publisherPurposes ? this.publisherPurposes.getJsonParameters() : undefined,
            cookieWall: this.cookieWall.getJsonParameters(),
            disableTcf: this.disableTcf,
            flexibleRefusal: this.flexibleRefusal,
            ccpa: this.ccpa.getJsonParameters(),
            external: this.external.getJsonParameters()
        };
    }
}
