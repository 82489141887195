import moment from "moment";
import {CmpConfigVersion} from "../api/model/cmp/config/CmpConfigVersion";

class CmpUpgradeTransition {
    newVersion: CmpConfigVersion = CmpConfigVersion.DEFAULT;
    startDate: string = "2024-01-22";
    endDate: string = "2024-06-30";

    isActive(): boolean {
        return moment().isBetween(this.startDate, this.endDate);
    }

    getActiveVersion(): CmpConfigVersion {
        return this.isActive() ? this.newVersion : CmpConfigVersion.DEFAULT;
    }
}

export default new CmpUpgradeTransition();
