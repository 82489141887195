import {CmpConfigAddOn} from "./CmpConfigAddOn";

export class CmpConfigAddOnsGroup {
    labelKey: string = "";
    addOns: CmpConfigAddOn[] = [];

    static GOOGLE: CmpConfigAddOnsGroup = new CmpConfigAddOnsGroup("google", [CmpConfigAddOn.GOOGLE_CONSENT_MODE, CmpConfigAddOn.GOOGLE_ADSENSE, CmpConfigAddOn.GOOGLE_AD_MANAGER]);
    static OTHERS: CmpConfigAddOnsGroup = new CmpConfigAddOnsGroup("others", [CmpConfigAddOn.SHOPIFY, CmpConfigAddOn.MICROSOFT, CmpConfigAddOn.CONSENT_MODE_FROM_TCF]);

    constructor(labelKey: string, addOns: CmpConfigAddOn[]) {
        this.labelKey = labelKey;
        this.addOns = addOns;
    }

    static values(): CmpConfigAddOnsGroup[] {
        return [
            CmpConfigAddOnsGroup.GOOGLE,
            CmpConfigAddOnsGroup.OTHERS
        ];
    }
}
