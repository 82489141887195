import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {ButtonLinkCancel, ButtonValidate, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalNew, ModalTitle} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";

type ModalTranslationLayoutProps = {
    active: boolean;
    titleKey: string;
    descriptionKey?: string;
    currentLanguage: CmpConfigLanguage;
    definedLanguages?: string[];
    onClose: () => void;
    onSelectLanguage: (language: CmpConfigLanguage) => void;
    onSubmit: () => void;
    children: React.ReactNode;
};

const ModalTranslationLayout: FunctionComponent<ModalTranslationLayoutProps> = ({active, titleKey, descriptionKey, currentLanguage, definedLanguages, onClose, onSelectLanguage, onSubmit, children}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    return (
        <ModalNew onClose={() => onClose()} active={active}>
            <ModalContent>
                <ModalTitle>{textConfiguration(titleKey)}</ModalTitle>
                <FormLayoutRows>
                    {descriptionKey &&
                        <ModalDescription>
                            <span dangerouslySetInnerHTML={{__html: textConfiguration(descriptionKey)}}/>
                        </ModalDescription>
                    }
                    <div className="translate-languages">
                        {
                            [0, 4, 8, 12].map((i) =>
                                <ul key={i}>
                                    {
                                        CmpConfigLanguage.values()
                                            .sort((a, b) => t(`language.${a.name}`) > t(`language.${b.name}`) ? 1 : -1)
                                            .slice(i, i + 4)
                                            .map((language) =>
                                                <li
                                                    key={language.name}
                                                    className={clsx({"current": currentLanguage.name === language.name}, {"active": definedLanguages && definedLanguages.includes(language.name)})}
                                                    onClick={() => onSelectLanguage(language)}
                                                >
                                                    <img src={`/images/flags/${language.name}.png`} title={t(`language.${language.name}`)} alt={t(`language.${language.name}`)}/>
                                                </li>
                                            )
                                    }
                                </ul>
                            )
                        }
                    </div>
                    {children}
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <ButtonValidate onClick={onSubmit}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalTranslationLayout;
