import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class SirdataVendor extends ApiModel {
    id: number = 0;
    name: string = "";
    purposes: number[] = [];
    legIntPurposes: number[] = [];
    policyUrl: string = "";
    premium: boolean = false;
    deletedDate?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
