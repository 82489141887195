import React from "react";
import {MainContent, Wrapper} from "../common/component/widget";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {MainHeader} from "../common/component/snippet";
import {MainContentStyle, MainContentPageHeader, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";

type BarterAgreementSection = {
    title: string;
    paragraphs: string[];
    subSections?: BarterAgreementSection[];
    noBullet?: boolean;
}

function BarterAgreement() {
    const {t: textsBarterAgreement} = useTranslation(TranslationPortalFile.BARTER_AGREEMENT);
    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textsBarterAgreement("title")}/>
                <Paragraph withSpacing>
                    <strong><i>{textsBarterAgreement("lastUpdated")}</i></strong>
                </Paragraph>
                {(textsBarterAgreement("sections", {returnObjects: true}) as BarterAgreementSection[]).map(({title, paragraphs, subSections, noBullet}, sectionIndex) => {
                    const sectionKey = `section-${sectionIndex}`;
                    return (
                        <React.Fragment key={sectionKey}>
                            {title && <h2>{!noBullet && `${sectionIndex}. `}{title}</h2>}
                            {paragraphs?.map((paragraph, paragraphIndex) =>
                                <Paragraph key={`${sectionKey}.${paragraphIndex.toString()}`} alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                                    <span dangerouslySetInnerHTML={{__html: paragraph}}/>
                                </Paragraph>
                            )}
                            {subSections?.map((subSection, subSectionIndex) => {
                                const subSectionKey = `${sectionKey}-sub${subSectionIndex}`;
                                return (
                                    <React.Fragment key={subSectionKey}>
                                        {subSection.title && <h3>{!noBullet && `${sectionIndex}.${subSectionIndex + 1}`} {subSection.title}</h3>}
                                        {subSection.paragraphs.map((subSectionParagraph, subSectionParagraphIndex) =>
                                            <Paragraph key={`${subSectionKey}.${subSectionParagraphIndex.toString()}`} alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                                                <span dangerouslySetInnerHTML={{__html: subSectionParagraph}}/>
                                            </Paragraph>

                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </MainContent>
        </Wrapper>
    );
}

export default BarterAgreement;
