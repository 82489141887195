import {Rest} from "../../common/api/rest/Rest";
import {ApiClient} from "../ApiClient";
import {CmpVersion} from "../model/cmp/version/CmpVersion";
import {CmpWebinar} from "../model/cmp/webinar/CmpWebinar";

export class RestPortalCmp extends Rest {
    public get rootPath(): string {
        return `${(this._client as ApiClient).gatewayRoot}/portal`;
    }

    async getVersions(): Promise<CmpVersion[]> {
        return this._client.get(`${this.rootPath}/version`, CmpVersion) as Promise<CmpVersion[]>;
    }

    async getWebinars(): Promise<CmpWebinar[]> {
        return this._client.get(`${this.rootPath}/webinar`, CmpWebinar) as Promise<CmpWebinar[]>;
    }

    async getWebinar(webinarId: number): Promise<CmpWebinar> {
        return this._client.get(`${this.rootPath}/webinar/${webinarId}`, CmpWebinar) as Promise<CmpWebinar>;
    }
}
