import React from "react";
import {ContentBlock} from "@sirdata/ui-lib";

export type InstallationStepsProps = {
    children: React.ReactNode;
};

const InstallationSteps: React.FunctionComponent<InstallationStepsProps> = ({children}) => {
    return (
        <ContentBlock cssClass="installation-steps">
            {children}
        </ContentBlock>
    );
};

export default InstallationSteps;
