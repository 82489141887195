import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {Network} from "./Network";
import {NetworkListField} from "./NetworkListField";

export class NetworkList extends ApiModel {
    lastUpdated: string = "";
    networks: Network[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(NetworkListField.NETWORKS, Network);
    }

    getNetwork(id: number) {
        return this.networks.find((it) => it.id === id);
    }
}
