import {ApiModel} from "../../../../common/api/model/ApiModel";
import {StatConsentRatings} from "./StatConsentRatings";
import {StatConsentDailyRatings} from "./StatConsentDailyRatings";

export class StatConsentGlobal extends ApiModel {
    dates: string[] = [];
    ratings: StatConsentRatings = new StatConsentRatings();
    daily: StatConsentDailyRatings = new StatConsentDailyRatings();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

