import {FunctionComponent} from "react";
import SvgCookieIcon from "./SvgCookieIcon";

type SvgCookieShieldIconProps = {
    small: boolean;
    color?: string;
};

const SvgCookieShieldIcon: FunctionComponent<SvgCookieShieldIconProps> = ({small, color}) => {
    return (
        <svg
            width={small ? "37" : "47"}
            height={small ? "37" : "47"}
            viewBox="0 0 42 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M20.66 52.595C20.41 52.595 20.17 52.545 19.94 52.435C0.75 43.605 0.02 28.385 0 27.745V8.595C0 7.865 0.46 7.215 1.14 6.965L20.07 0.105C20.45 -0.035 20.87 -0.035 21.25 0.105L40.18 6.955C40.87 7.205 41.32 7.855 41.32 8.585V27.675C41.32 27.675 41.32 27.715 41.32 27.735C41.3 28.375 40.57 43.595 21.38 52.425C21.15 52.535 20.9 52.585 20.66 52.585V52.595Z" fill={color}/>
            <path d="M21 3L37.9776 9.15348C38.5964 9.37806 39 9.96196 39 10.6177V27.7666C39 27.7666 39 27.8026 39 27.8205C38.9821 28.3955 38.3274 42.0679 21.1166 50L21 3Z" fill="black" fillOpacity="0.1"/>
            <g transform="translate(9 14)">
                <SvgCookieIcon/>
            </g>
        </svg>
    );
};

export default SvgCookieShieldIcon;
