import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import validate from "validate.js";
import {CmpConfigPublisherLegalBasis} from "../../api/model/cmp/config/publisher/CmpConfigPublisherLegalBasis";
import {CmpConfigPublisherCustomPurpose} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurpose";
import {AlertSeverity, ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentDirection, FlexContentSpacing, FormLayoutButtons, FormLayoutMention, FormLayoutRows, InputText, ModalContent, ModalNew, ModalTitle, Select, Textarea, ToggleSwitch} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigPublisherField} from "../../api/model/cmp/config/publisher/CmpConfigPublisherField";
import {CmpConfigPublisherCustomPurposeField} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeField";
import {CmpConfigPublisherCustomPurposeVendorField} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeVendorField";
import {FormLayoutMessage} from "../../common/component/snippet";
import {CmpConfigPublisherCustomPurposeVendor} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeVendor";

type ModalCustomPurposeProps = {
    initValue: CmpConfigPublisherCustomPurpose | undefined;
    onClose: () => void;
    onSubmit: (restrict: CmpConfigPublisherCustomPurpose) => void;
}

type FormState = {
    isValid?: boolean;
    errors?: { [key: string]: string };
};

const ModalCustomPurpose: FunctionComponent<ModalCustomPurposeProps> = ({initValue, onClose, onSubmit}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const [purpose, setPurpose] = useState<CmpConfigPublisherCustomPurpose>(new CmpConfigPublisherCustomPurpose());
    const [formState, setFormState] = useState<FormState>({});
    const [isShowError, setShowError] = useState(false);

    useEffect(() => {
        if (initValue) {
            setPurpose(new CmpConfigPublisherCustomPurpose(initValue));
            setFormState({});
            setShowError(false);
        }
    }, [initValue]);

    const hasEmptyField = () => {
        return !purpose.name || !purpose.description || (purpose.vendor && !(purpose.vendor.name && purpose.vendor.policyUrl));
    };

    const handleChange = (field: CmpConfigPublisherCustomPurposeField, value: any) => {
        setPurpose((prevState) => new CmpConfigPublisherCustomPurpose({...prevState, [field]: value}));
    };

    const handleChangeVendor = (field: CmpConfigPublisherCustomPurposeVendorField, value: any) => {
        const newVendor = new CmpConfigPublisherCustomPurposeVendor({...purpose.vendor, [field]: value});
        handleChange(CmpConfigPublisherCustomPurposeField.VENDOR, newVendor);
    };

    const handleSubmit = () => {
        if (!formState.isValid) {
            setShowError(true);
            return;
        }
        onSubmit(purpose);
    };

    useEffect(() => {
        let errors: any;
        if (purpose.vendor) {
            errors = validate(purpose.vendor, {
                [CmpConfigPublisherCustomPurposeVendorField.POLICY_URL]: (): any => ({url: true})
            });
        }

        setFormState((prevState) => ({
            ...prevState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [purpose]);

    return (
        <ModalNew onClose={() => onClose()} active={!!initValue}>
            <ModalContent>
                <ModalTitle>{textConfigPurposes(`tab.custom.${initValue?.name ? "edit_custom_purpose" : "add_custom_purpose"}`)}</ModalTitle>
                <FormLayoutRows>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.NAME}`)}
                        required
                    >
                        <InputText
                            value={purpose.name}
                            onChange={(value) => handleChange(CmpConfigPublisherCustomPurposeField.NAME, value)}
                        />
                    </FieldBlock>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.DESCRIPTION}`)}
                        required
                    >
                        <Textarea
                            value={purpose.description}
                            onChange={(value) => handleChange(CmpConfigPublisherCustomPurposeField.DESCRIPTION, value)}
                            rows={10}
                        />
                    </FieldBlock>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.LEGAL_BASIS}`)}
                        required
                    >
                        <Select
                            value={purpose.legalBasis}
                            options={[
                                {value: CmpConfigPublisherLegalBasis.CONSENT, label: textConfigPurposes(`${CmpConfigPublisherLegalBasis.CONSENT}`)},
                                {value: CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST, label: textConfigPurposes(`${CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST}`)}
                            ]}
                            onChange={(option) => option && handleChange(CmpConfigPublisherCustomPurposeField.LEGAL_BASIS, option.value as CmpConfigPublisherLegalBasis)}
                        />
                    </FieldBlock>
                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                        <FieldBlock>
                            <ToggleSwitch
                                label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.VENDOR}.label`)}
                                checked={!!purpose.vendor}
                                onChange={(checked) => handleChange(CmpConfigPublisherCustomPurposeField.VENDOR, checked ? new CmpConfigPublisherCustomPurposeVendor() : undefined)}
                            />
                        </FieldBlock>
                        {
                            purpose.vendor &&
                            <>
                                <FieldBlock
                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.VENDOR}.${CmpConfigPublisherCustomPurposeVendorField.NAME}`)}
                                    required
                                >
                                    <InputText
                                        value={purpose.vendor.name}
                                        onChange={(value) => handleChangeVendor(CmpConfigPublisherCustomPurposeVendorField.NAME, value)}
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.VENDOR}.${CmpConfigPublisherCustomPurposeVendorField.POLICY_URL}`)}
                                    content={{direction: FlexContentDirection.COLUMN}}
                                    required
                                >
                                    <InputText
                                        placeholder={textConfiguration(`placeholder.${CmpConfigPublisherCustomPurposeVendorField.POLICY_URL}`)}
                                        value={purpose.vendor.policyUrl}
                                        onChange={(value) => handleChangeVendor(CmpConfigPublisherCustomPurposeVendorField.POLICY_URL, value)}
                                    />
                                    {isShowError &&
                                        <FormLayoutMessage message={t("error.invalid_url")} severity={AlertSeverity.DANGER}/>
                                    }
                                </FieldBlock>
                            </>
                        }
                    </FormLayoutRows>
                    <FormLayoutMention/>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalCustomPurpose;
