import React, {FunctionComponent} from "react";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {ImageManager} from "../../widget";
import {IconTooltip, LayoutColumns} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type ConfigThemeImagesProps = {
    theme?: CmpConfigTheme;
    field: CmpConfigThemeModeField.LOGO | CmpConfigThemeModeField.SKIN | CmpConfigThemeModeField.WATERMARK;
    onSave: (newImage: string, type: CmpConfigThemeModeField, mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, applyBothMode?: boolean) => void;
    onClear: (type: CmpConfigThemeModeField, mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE) => void;
    maxFileSize?: number;
}

const ConfigThemeImages: FunctionComponent<ConfigThemeImagesProps> = ({theme, field, onSave, onClear, maxFileSize}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    return (
        <LayoutColumns cssClass="theme-images">
            <div className="theme-images__mode">
                <IconTooltip
                    icon={{name: "wb_sunny", cssClass: "theme-images__mode__icon"}}
                    text={textConfiguration("theme_mode.light")}
                />
                <ImageManager
                    field={field}
                    mode={CmpConfigThemeField.LIGHT_MODE}
                    currentImage={theme?.lightMode[field] || ""}
                    onSave={onSave}
                    onRemove={onClear}
                    maxFileSize={maxFileSize}
                    hasApplyBothModes
                />
            </div>
            <div className="theme-images__mode">
                <IconTooltip
                    icon={{name: "brightness_2", cssClass: "theme-images__mode__icon theme-images__mode__icon--dark"}}
                    text={textConfiguration("theme_mode.dark")}
                />
                <ImageManager
                    field={field}
                    mode={CmpConfigThemeField.DARK_MODE}
                    currentImage={theme?.darkMode[field] || ""}
                    onSave={onSave}
                    onRemove={onClear}
                    maxFileSize={maxFileSize}
                    hasApplyBothModes
                />
            </div>
        </LayoutColumns>
    );
};

export default ConfigThemeImages;
