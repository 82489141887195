import axios from "axios";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {CommonApiClient} from "../common/api/CommonApiClient";
import {QueryString} from "../common/api/model/QueryString";
import {StatConsentRatingsResult} from "./model/stat/consent/StatConsentRatingsResult";
import {StatConsentRatings} from "./model/stat/consent/StatConsentRatings";
import {StatConsentGlobalResult} from "./model/stat/consent/StatConsentGlobalResult";
import {StatConsentGlobal} from "./model/stat/consent/StatConsentGlobal";

export class ApiClient extends CommonApiClient {
    public get gatewayRoot(): string {
        return "/cmp-api";
    }

    public async getConsentStatRatings(path: string, options?: any): Promise<StatConsentRatingsResult> {
        try {
            let opts = this.buildRequestConfig(options);
            if (!!options?.queryParams) {
                path += (path.includes("?") ? "&" : "?") + QueryString.build(options.queryParams);
            }
            const resp = await this.httpClient.get(path, opts);
            const result: StatConsentRatingsResult = new StatConsentRatingsResult();
            const items = resp.data;
            for (let configId of Object.keys(items)) {
                result[configId] = new StatConsentRatings(items[configId]);
            }
            return result;
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    public async getConsentStatGlobal(path: string, options?: any): Promise<StatConsentGlobalResult> {
        try {
            let opts = this.buildRequestConfig(options);
            if (!!options?.queryParams) {
                path += (path.includes("?") ? "&" : "?") + QueryString.build(options.queryParams);
            }
            const resp = await this.httpClient.get(path, opts);
            const result: StatConsentGlobalResult = new StatConsentGlobalResult();
            const items = resp.data;
            for (let configId of Object.keys(items)) {
                result[configId] = new StatConsentGlobal(items[configId]);
            }
            return result;
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }
}
