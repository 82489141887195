import {ApiModel} from "../../../../common/api/model/ApiModel";
import {StatConsentRatings} from "./StatConsentRatings";
import {StatConsentDailyRatings} from "./StatConsentDailyRatings";

export class StatConsentRates extends ApiModel {
    ratings: StatConsentRatings = new StatConsentRatings();
    trends: StatConsentRatings = new StatConsentRatings();
    daily: StatConsentDailyRatings = new StatConsentDailyRatings();
    responses: { [key: string]: number } = {};
    clicks: { [key: string]: number } = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObject("ratings", StatConsentRatings);
        this.loadObject("trends", StatConsentRatings);
        this.loadObject("daily", StatConsentDailyRatings);
    }

    getTotalResponses() {
        let total = 0;
        for (let key in this.responses) {
            total += this.responses[key];
        }
        return total;
    }

    getTotalClicks() {
        let total = 0;
        for (let key in this.responses) {
            total += this.responses[key];
        }
        return total;
    }
}

