import clsx from "clsx";
import {Alert, AlertSeverity, Box, BoxRadius, ButtonLinkStyle, DropDownLink, Paragraph, ParagraphAlignment, ToggleSwitch} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigAddOn} from "../../../utils/CmpConfigAddOn";
import {CmpConfigExternalField} from "../../../api/model/cmp/config/CmpConfigExternalField";
import {TranslationPortalFile} from "../../../utils/constants";

type ConfigAddOnProps = {
    addOn: CmpConfigAddOn;
    checked: boolean;
    onToggle: (value: boolean) => void;
}

type AddOnInfo = {
    label: string;
    description: string;
    warnings?: string[];
    note?: string;
    products_indication?: string;
}

const ConfigAddOn: FunctionComponent<ConfigAddOnProps> = ({addOn, checked, onToggle}) => {
    const {t: textConfigAddOns} = useTranslation(TranslationPortalFile.CONFIG_ADD_ONS);
    const [moduleInfo, setModuleInfo] = useState<AddOnInfo>();
    const [isShowMore, setShowMore] = useState(false);

    useEffect(() => {
        const moduleInfo: AddOnInfo = textConfigAddOns(`modules.${addOn.labelKey}`, addOn.textOptions);
        setModuleInfo(moduleInfo);
    }, [addOn, textConfigAddOns]);

    if (!moduleInfo) return <></>;

    return (
        <Box cssClass="add-ons__item" radius={BoxRadius.MD}>
            <div className="add-ons__item__logo">
                <img src={`/images/config/${addOn.logo}`} alt={addOn.logo}/>
            </div>
            <div className="add-ons__item__content">
                <ToggleSwitch
                    name={addOn.field}
                    label={moduleInfo.label}
                    checked={checked}
                    onChange={onToggle}
                    cssClass={"add-ons__item__content__toggle"}
                />
                <Paragraph cssClass="add-ons__description" alignment={ParagraphAlignment.JUSTIFY}><span dangerouslySetInnerHTML={{__html: moduleInfo.description}}/></Paragraph>
                {!!moduleInfo.warnings?.length &&
                    <DropDownLink
                        link={{
                            children: textConfigAddOns(isShowMore ? "read_less" : "read_more"),
                            style: ButtonLinkStyle.MIDNIGHT_LIGHT,
                            cssClass: "add-ons__item__content__toggle-link",
                            reverseUnderline: true,
                            onClick: () => setShowMore((prev) => !prev)
                        }}
                        startOpen={isShowMore}
                    >
                        <div className={clsx("add-ons__item__content__details")}>
                            {moduleInfo.warnings.map((warning) =>
                                <Alert key={warning} text={warning} severity={AlertSeverity.WARNING} fullWidth/>
                            )}
                        </div>
                    </DropDownLink>
                }
                {!!moduleInfo.note &&
                    <div className="add-ons__item__content__note">
                        <label>{textConfigAddOns("note")}</label>
                        <span dangerouslySetInnerHTML={{__html: moduleInfo.note}}/>
                    </div>
                }
                {(!!moduleInfo.products_indication && !!addOn.productLogos) &&
                    <div className="add-ons__item__content__products">
                        <Paragraph cssClass="add-ons__description" alignment={ParagraphAlignment.JUSTIFY}>{moduleInfo.products_indication}</Paragraph>
                        <div className="add-ons__item__content__products__logos">
                            {addOn.productLogos.map((logo) =>
                                <img key={logo} src={`/images/config/${logo}`} alt={logo}/>
                            )}
                        </div>
                    </div>
                }
                {addOn.isShowIndication() &&
                    <Alert text={textConfigAddOns("indication_first_tag_loaded")}/>
                }
                {(addOn.field === CmpConfigExternalField.CONSENT_MODE_FROM_TCF) && checked &&
                    <Alert text={textConfigAddOns(`modules.${addOn.labelKey}.checked_warning`)} severity={AlertSeverity.WARNING}/>
                }
            </div>
        </Box>
    );
};

export default ConfigAddOn;
