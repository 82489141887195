import React, {FunctionComponent} from "react";
import {ColorPicker, IconTooltip, LayoutColumns} from "@sirdata/ui-lib";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type ConfigThemeColorsProps = {
    theme?: CmpConfigTheme;
    field: CmpConfigThemeModeField;
    onChange: (name: string, value: string, mode?: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE) => void;
}

const ConfigThemeColors: FunctionComponent<ConfigThemeColorsProps> = ({theme, field, onChange}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    return (
        <LayoutColumns cssClass="theme-colors">
            <div className="theme-colors__mode">
                <IconTooltip
                    icon={{name: "wb_sunny", cssClass: "theme-colors__mode__icon"}}
                    text={textConfiguration("theme_mode.light_default")}
                />
                <ColorPicker
                    value={theme?.lightMode[field] || ""}
                    onChange={(value) => onChange(field, value, CmpConfigThemeField.LIGHT_MODE)}
                />
            </div>
            <div className="theme-colors__mode">
                <IconTooltip
                    icon={{name: "brightness_2", cssClass: "theme-colors__mode__icon theme-colors__mode__icon--dark"}}
                    text={textConfiguration("theme_mode.dark")}
                />
                <ColorPicker
                    value={theme?.darkMode[field] || ""}
                    placeholder={theme?.lightMode[field]}
                    onChange={(value) => onChange(field, value, CmpConfigThemeField.DARK_MODE)}
                />
            </div>
        </LayoutColumns>
    );
};

export default ConfigThemeColors;
