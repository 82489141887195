import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {AlertSeverity, Box, BoxRadius, CircleStep, ContentBlock, LayoutRows, Loadable, MainContentPageHeader, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";
import {session} from "../../api/ApiSession";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {ConfigAddOn, ConfigHeader} from "../../component/snippet";
import {CmpConfigAddOnsGroup} from "../../utils/CmpConfigAddOnsGroup";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";

function ConfigAddOns() {
    const {t: textConfigAddOns} = useTranslation(TranslationPortalFile.CONFIG_ADD_ONS);
    const {id: configId} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isEditMode, setEditMode] = useState(false);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);

    const handleSave = async () => {
        try {
            if (cmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.ADD_ONS)?.name) {
                cmpConfig.step = CmpConfigStepName.ADD_ONS;
            }
            await session.restCmpConfig.update(cmpConfig);
            setUnsavedChanges(false);
        } catch (e) {
            UIEventManager.alert(textConfigAddOns("error.update_add_ons"), AlertSeverity.DANGER);
        }
    };

    const handleToggleAddOn = (field: string, checked: boolean) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.external[field] = checked;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const newCmpConfig = await session.restCmpConfig.get(configId);
                newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [configId]);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges}/>
            <ConfigHeader
                step={CmpConfigStepName.ADD_ONS}
                editMode={isEditMode}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={handleSave}
            />
            <MainContent cssClass="add-ons">
                <MainContentPageHeader
                    title={textConfigAddOns("title")}
                    icon={!isEditMode ? <CircleStep step={CmpConfigStepper.getStepPosition(CmpConfigStepName.ADD_ONS)}/> : undefined}
                    description={textConfigAddOns("description")}
                    cssClass="configuration-main-title"
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        {CmpConfigAddOnsGroup.values().map(({labelKey, addOns}) =>
                            <Box key={labelKey} radius={BoxRadius.MD}>
                                <h2>{textConfigAddOns(`module_groups.${labelKey}.title`)}</h2>
                                <Paragraph cssClass="add-ons__description" alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                                    <span dangerouslySetInnerHTML={{__html: textConfigAddOns(`module_groups.${labelKey}.description`)}}/>
                                </Paragraph>
                                <ContentBlock>
                                    {addOns.map((addOn) =>
                                        <ConfigAddOn
                                            key={addOn.labelKey}
                                            addOn={addOn}
                                            checked={!!cmpConfig.settings.external[addOn.field]}
                                            onToggle={(value) => handleToggleAddOn(addOn.field, value)}
                                        />
                                    )}
                                </ContentBlock>
                            </Box>
                        )}
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ConfigAddOns;
