import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataList} from "../../api/model/cmp/list/sirdata-list/SirdataList";
import {Action, Button, ButtonSize, ButtonStyle, IconTooltip, TableColumn, TableColumnStyle, TableRow, Tag, TagSize, TagStyle, Tooltip} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {Partner} from "../../utils/Partner";
import {Network} from "../../api/model/cmp/list/network-list/Network";

type PartnerRowProps = {
    partner: Partner;
    networks?: Network[];
    isRemovable: boolean;
    isFreemium: boolean;
    globalVendorList: GlobalVendorList;
    sirdataList: SirdataList;
    onRemove: () => void;
}

const PartnerRow: FunctionComponent<PartnerRowProps> = ({partner, networks, isRemovable, isFreemium, globalVendorList, sirdataList, onRemove}) => {
    const {t: textConfigPartners} = useTranslation(TranslationPortalFile.CONFIG_PARTNERS);
    const [isOpenMore, setOpenMore] = useState<boolean>(false);

    return (
        <>
            <TableRow>
                <TableColumn>
                    <IconTooltip
                        icon={isOpenMore ? Action.COLLAPSE.icon : Action.EXPAND.icon}
                        text={textConfigPartners(isOpenMore ? "vendor_row.hide_details" : "vendor_row.see_details")}
                        onClick={() => setOpenMore((prev) => !prev)}
                    />
                </TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.logo}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.id}</TableColumn>
                <TableColumn>
                    <span>{partner.name}</span>
                    {networks?.map(({name}) =>
                        <Tooltip key={name} text={textConfigPartners("vendor_row.included_in_network_list", {name: name})}><Tag label={name} style={TagStyle.PRIMARY_GREY_LIGHT} size={TagSize.SMALL}/></Tooltip>
                    )}
                    {isFreemium &&
                        <Tooltip text={textConfigPartners("vendor_row.sirdata_partner_text")}><Tag label={textConfigPartners("vendor_row.sirdata_partner")} style={TagStyle.PRIMARY_GREY_LIGHT} size={TagSize.SMALL}/></Tooltip>
                    }
                </TableColumn>
                <TableColumn>
                    {isRemovable ?
                        <Button onClick={onRemove} size={ButtonSize.XSMALL} style={ButtonStyle.DEFAULT_MIDNIGHT}>{textConfigPartners("vendor_row.remove_from_list")}</Button> :
                        <Tooltip text={textConfigPartners("vendor_row.network_partner")}>
                            <Button onClick={() => {}} size={ButtonSize.XSMALL} style={ButtonStyle.DEFAULT_MIDNIGHT} disabled>{textConfigPartners("vendor_row.remove_from_list")}</Button>
                        </Tooltip>
                    }
                </TableColumn>
            </TableRow>
            {isOpenMore &&
                <TableRow>
                    <TableColumn colSpan={5}>
                        <div>
                            {(!!partner.purposes.length || !!partner.specialFeatures.length || !!partner.extraPurposes.length) &&
                                <>
                                    <span>{textConfigPartners("vendor_row.ask_consent_for")} :</span>
                                    <ul>
                                        {partner.purposes.map((purposeId) =>
                                            <li key={purposeId} className="partner__purpose">
                                                <img src="/images/logo_iab_partner_color.png" alt="IAB logo" title="IAB"/>
                                                <span>{textConfigPartners("vendor_row.purpose")} {purposeId} : {globalVendorList.getPurpose(purposeId)?.name}</span>
                                            </li>
                                        )}
                                        {partner.extraPurposes.map((purposeId) =>
                                            <li key={purposeId} className="partner__purpose">
                                                <img src="/images/logo_sirdata_partners_small.png" alt="Sirdata logo" title="Sirdata"/>
                                                <span>{textConfigPartners("vendor_row.purpose")} {purposeId} : {sirdataList.getPurpose(purposeId)?.name}</span>
                                            </li>
                                        )}
                                        {partner.specialFeatures.map((featureId) =>
                                            <li key={featureId} className="partner__purpose">
                                                <img src="/images/logo_iab_partner_color.png" alt="IAB logo" title="IAB"/>
                                                <span>{textConfigPartners("vendor_row.feature_sp")} {featureId} : {globalVendorList.getSpecialFeature(featureId)?.name}</span>
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!partner.legIntPurposes.length || !!partner.legIntExtraPurposes.length) &&
                                <>
                                    <span>{textConfigPartners("vendor_row.legitimate_interest_for")} :</span>
                                    <ul>
                                        {partner.legIntPurposes.map((purposeId) =>
                                            <li key={purposeId} className="partner__purpose">
                                                <img src="/images/logo_iab_partner_color.png" alt="IAB logo" title="IAB"/>
                                                <span>{textConfigPartners("vendor_row.purpose")} {purposeId} : {globalVendorList.getPurpose(purposeId)?.name}</span>
                                            </li>
                                        )}
                                        {partner.legIntExtraPurposes.map((purposeId) =>
                                            <li key={purposeId} className="partner__purpose">
                                                <img src="/images/logo_sirdata_partners_small.png" alt="Sirdata logo" title="Sirdata"/>
                                                <span>{textConfigPartners("vendor_row.purpose")} {purposeId} : {sirdataList.getPurpose(purposeId)?.name}</span>
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!partner.features.length || !!partner.specialPurposes.length) &&
                                <>
                                    <span>{textConfigPartners("vendor_row.features")} :</span>
                                    <ul>
                                        {partner.features.map((featureId) =>
                                            <li key={featureId} className="partner__purpose">
                                                <img src="/images/logo_iab_partner_color.png" alt="IAB logo" title="IAB"/>
                                                <span>{textConfigPartners("vendor_row.feature")} {featureId} : {globalVendorList.getFeature(featureId)?.name}</span>
                                            </li>
                                        )}
                                        {partner.specialPurposes.map((purposeId) =>
                                            <li key={purposeId} className="partner__purpose">
                                                <img src="/images/logo_iab_partner_color.png" alt="IAB logo" title="IAB"/>
                                                <span>{textConfigPartners("vendor_row.purpose_sp")} {purposeId} : {globalVendorList.getSpecialPurpose(purposeId)?.name}</span>
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </div>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default PartnerRow;
