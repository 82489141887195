import {pathAnalytics, pathAnalyticsConsent, pathAnalyticsHits, pathCmp} from "./constants";
import {sirdataDocumentationUrl, sirdataFaqUrl} from "./helper";
import {pathHome} from "../common/utils/constants";
import {MenuItem} from "@sirdata/ui-lib";

export enum Menu {
    HOME = "home",
    CONFIGURATION = "configuration",
    ANALYTICS = "analytics",
    ANALYTICS_CONSENT = "analytics_consent",
    ANALYTICS_HITS = "analytics_hits",
    DOCUMENTATION = "documentation",
    FAQ = "faq"
}

export const MENU: MenuItem[] = [
    {label: Menu.HOME, path: pathHome, icon: {name: "home"}},
    {label: Menu.CONFIGURATION, path: pathCmp, icon: {name: "settings"}},
    {
        label: Menu.ANALYTICS, path: pathAnalytics, icon: {name: "bar_chart"}, items: [
            {label: Menu.ANALYTICS_CONSENT, path: pathAnalyticsConsent, icon: {name: "keyboard_arrow_right"}},
            {label: Menu.ANALYTICS_HITS, path: pathAnalyticsHits, icon: {name: "keyboard_arrow_right"}}
        ]
    },
    {label: Menu.DOCUMENTATION, path: sirdataDocumentationUrl, icon: {name: "description"}, externalUrl: true},
    {label: Menu.FAQ, path: sirdataFaqUrl, icon: {name: "forum"}, externalUrl: true}
];
