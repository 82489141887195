import clsx from "clsx";
import {AlertSeverity, Box, BoxSpacing, SvgSirdataLogoWhite, Wrapper} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {PortalQueryString} from "../../../api/model/PortalQueryString";
import {pathConfigurationFlashPreview, TranslationPortalFile} from "../../../utils/constants";
import {ConfigFlashInfo} from "../../../component/snippet";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {CmpConfigPropertiesCreationType} from "../../../api/model/cmp/config/CmpConfigPropertiesCreationType";

function ConfigurationFlashInformation() {
    const {t: textConfigurationFlash} = useTranslation(TranslationPortalFile.CONFIGURATION_FLASH);
    const location = useLocation();
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState(false);
    const [cmpConfig, setCmpConfig] = useState<CmpConfig>();

    const handleSubmit = async (cmpConfig: CmpConfig) => {
        try {
            setSubmitting(true);
            if (!!cmpConfig.id) {
                await session.restCmpConfig.update(cmpConfig);
            } else {
                cmpConfig.properties.creation_type = CmpConfigPropertiesCreationType.FLASH;
                cmpConfig = await session.restCmpConfig.create(cmpConfig);
            }
            navigate(`${pathConfigurationFlashPreview}?${PortalQueryString.ID}=${cmpConfig.id}`);
            setSubmitting(false);
        } catch (e) {
            UIEventManager.alert(textConfigurationFlash("error.create_cmp"), AlertSeverity.DANGER);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const cmpId = PortalQueryString.getParam(location.search, PortalQueryString.ID);
                if (!!cmpId) {
                    setCmpConfig(await session.restCmpConfig.get(cmpId));
                }
            } catch (e) {
                UIEventManager.alert(textConfigurationFlash("error.get_cmp"), AlertSeverity.DANGER);
            }
        })();
    }, [location, textConfigurationFlash]);

    return (
        <Wrapper cssClass="onboarding">
            <div className="onboarding-page">
                <div className="onboarding-header">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <div className="title">{textConfigurationFlash("title")}</div>
                </div>
                <div className={clsx("onboarding-form", "onboarding-form--fullwidth")}>
                    <Box cssClass="configuration-flash" spacing={BoxSpacing.XLARGE}>
                        <ConfigFlashInfo
                            onSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            initCmpConfig={cmpConfig}
                        />
                    </Box>
                </div>
            </div>
        </Wrapper>
    );
}

export default ConfigurationFlashInformation;
