import {EmailInfo} from "../../../common/api/model/account/EmailInfo";
import {ApiModel} from "../../../common/api/model/ApiModel";

export class IntegrationProcessMailRequest extends ApiModel {
    config_id: string = "";
    site_name: string = "";
    via_gtm: boolean = false;
    email_info: EmailInfo = new EmailInfo();
    cc_emails: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            config_id: this.config_id,
            site_name: this.site_name,
            via_gtm: this.via_gtm,
            email_info: this.email_info,
            cc_emails: this.cc_emails
        };
    }
}
