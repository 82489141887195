import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {Box, BoxProps, BoxRadius, ButtonLink, ButtonLinkStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Trend} from "../index";

type MetricCardProps = {
    header: {
        label: string;
        image?: string;
    };
    value: string;
    trendValue: number;
    showTrend: boolean;
    onClick?: () => void;
};

const MetricCard: FunctionComponent<MetricCardProps> = ({header, value, trendValue, showTrend, onClick}) => {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);

    return (
        <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass={clsx("metric-card", {"clickable": !!onClick})} onClick={onClick}>
            <div className="metric-card__header">
                {header.label}
                {header.image && <img src={header.image} alt=""/>}
            </div>
            <span className="metric-card__value">{value}</span>
            {showTrend && <Trend value={trendValue}/>}
            {!!onClick && <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} cssClass="metric-card__see-more">{textAnalytics("action.see_more")}</ButtonLink>}
        </Box>
    );
};

export default MetricCard;
