import {Rest} from "../../common/api/rest/Rest";
import {Audit} from "../model/partner/Audit";
import {Locale} from "../../common/utils/Locale";
import {ApiClient} from "../ApiClient";
import {StatHit} from "../model/stat/StatHit";
import {MomentInput} from "moment";
import {Formatter} from "../../common/utils/Formatter";
import {StatConsentEnvironment} from "../model/stat/consent/StatConsentEnvironment";
import {StatPeriod} from "../../utils/stat/StatPeriod";
import {StatConsentRatingsResult} from "../model/stat/consent/StatConsentRatingsResult";
import {StatConsentDetails} from "../model/stat/consent/StatConsentDetails";
import {StatConsentGlobalResult} from "../model/stat/consent/StatConsentGlobalResult";

export class RestPartner extends Rest {
    public get rootPath(): string {
        return `${(this._client as ApiClient).gatewayRoot}/partner`;
    }

    private buildConsentStatsParams(period: StatPeriod, startDate: MomentInput, endDate: MomentInput, environment: StatConsentEnvironment = StatConsentEnvironment.WEB) {
        const dateFormat = "YYYYMMDD";
        return {
            period: period.name,
            start: Formatter.formatDate(startDate, dateFormat),
            end: Formatter.formatDate(endDate, dateFormat),
            environment: environment
        };
    };

    getConsentStatsRatings(period: StatPeriod, startDate: MomentInput, endDate: MomentInput, environment: StatConsentEnvironment = StatConsentEnvironment.WEB): Promise<StatConsentRatingsResult> {
        const params = this.buildConsentStatsParams(period, startDate, endDate, environment);
        return (this._client as ApiClient).getConsentStatRatings(`${this.rootPath}/stats-v2/ratings`, {queryParams: params}) as Promise<StatConsentRatingsResult>;
    }

    getConsentStatsGlobal(period: StatPeriod, startDate: MomentInput, endDate: MomentInput, environment: StatConsentEnvironment = StatConsentEnvironment.WEB): Promise<StatConsentGlobalResult> {
        const params = this.buildConsentStatsParams(period, startDate, endDate, environment);
        return (this._client as ApiClient).getConsentStatGlobal(`${this.rootPath}/stats-v2/global`, {queryParams: params}) as Promise<StatConsentGlobalResult>;
    }

    getConsentStatsForConfig(configId: string, period: StatPeriod, startDate: MomentInput, endDate: MomentInput, environment: StatConsentEnvironment = StatConsentEnvironment.WEB): Promise<StatConsentDetails> {
        const params = this.buildConsentStatsParams(period, startDate, endDate, environment);
        return this._client.get(`${this.rootPath}/stats-v2/config/${configId}`, StatConsentDetails, {queryParams: params}) as Promise<StatConsentDetails>;
    }

    getDailyHits(start: string, end: string, configId?: string): Promise<StatHit[]> {
        return this._client.get(`${this.rootPath}/daily-stats/start/${start}/end/${end}`, StatHit, {queryParams: {conf_id: configId}}) as Promise<StatHit[]>;
    }

    getAudit(): Promise<Audit> {
        return this._client.get(`${this.rootPath}/audit?lang=${Locale.getCurrentLocale()}`, Audit) as Promise<Audit>;
    }
}
