const INTEGRATION_TEXTS: { [country: string]: string } = {
    fr: "[Nom de l'entreprise] participe et est conforme à l'ensemble des Spécifications et Politiques du Transparency & Consent Framework de l'IAB Europe. [Il/Elle] utilise la Consent Management Platform n°92." +
        "<br/><br/>\n\nVous pouvez modifier vos choix à tout moment en <a href='javascript:Sddan.cmp.displayUI()'>cliquant ici</a>.",
    en: "[Company name] obtains your consent in accordance with the policies and technical specifications of the IAB Europe Transparency & Consent Framework. It uses the Consent Management Platform n°92." +
        "<br/><br/>\n\nYou can change your choices at any time by <a href='javascript:Sddan.cmp.displayUI()'>clicking here</a>.",
    de: "[Name des Unternehmens] ist an allen Bestimmungen und Richtlinien des Transparency & Consent Framework von IAB Europe beteiligt und hält diese ein. [Il/Es] nutzt die Consent Management Platform Nr. 92." +
        "<br/><br/>\n\nSie können Ihre Auswahl jederzeit ändern, indem Sie <a href='javascript:Sddan.cmp.displayUI()'>hier klicken</a>.",
    it: "[Nome azienda] partecipa e rispetta tutte le Specifiche e le Politiche del Transparency & Consent Framework di IAB Europe. Utilizza la piattaforma di gestione del consenso n°92." +
        "<br/><br/>\n\nPuoi cambiare le tue scelte in qualsiasi momento con <a href='javascript:Sddan.cmp.displayUI()'>cliccando qui</a>.",
    es: "[Nombre de la empresa] participa y está conforme con el conjunto de las Especificaciones y Políticas del Transparency & Consent Framework del IAB Europe. [Él/Ella] utiliza la Consent Management Platform n° 92." +
        "<br/><br/>\n\nPodrá modificar sus elecciones en cualquier momento <a href='javascript:Sddan.cmp.displayUI()'>haciendo clic aquí</a>.",
    pt: "[O/A Nome da empresa] participa e cumpre todas as especificações e políticas da Estrutura de Transparency & Consent Framework do IAB Europe. [Ele/Ela] utiliza a Plataforma de Gestão de Consentimento nº 92." +
        "<br/><br/>\n\nPode alterar as suas escolhas a qualquer momento <a href='javascript:Sddan.cmp.displayUI()'>clicando aqui</a>.",
    nl: "[Naam van het bedrijf] neemt deel en voldoet aan alle Specificaties en Beleid van de Transparency & Consent Framework van de IAB Europe. [Hij/Zij] gebruikt het Consent Management Platform nr. 92." +
        "<br/><br/>\n\nU kunt uw keuzes op elk moment wijzigen door <a href='javascript:Sddan.cmp.displayUI()'>hier te klikken</a>.",
    no: "[Navn på bedriften] deltar og er i samsvar med alle Spesifikasjoner og retningslinjer for IAB Europe Transparency & Consent Framework. [Han/hun] bruker Consent Management Platform n°92." +
        "<br/><br/>\n\nDu kan endre valgene dine når som helst, ved å <a href='javascript:Sddan.cmp.displayUI()'>klikke her</a>.",
    sv: "[Företagsnamn] deltar i och följer samtliga specifikationer och policyer i IAB Europe Transparency & Consent Framework. [Han/Hon] använder Consent Management Platform nr. 92." +
        "<br/><br/>\n\nDu kan ändra dina val när som helst genom att <a href='javascript:Sddan.cmp.displayUI()'>klicka här</a>.",
    bg: "[Име на фирмата] участва и съответства изцяло на Спецификациите и Политиките на Transparency & Consent Framework на IAB Europe. [той/Тя] използва Платформата за съгласувано управление № 92." +
        "<br/><br/>\n\nВие можете да промените вашия избор по всяко време като <a href='javascript:Sddan.cmp.displayUI()'>кликнете тук</a>.",
    ro: "[Numele firmei] participă și este conformă cu ansamblul Specificațiilor și Politicii Transparency & Consent Framework de l'IAB Europe. [El/Ea] utilizează Consent Management Platform n°92." +
        "<br/><br/>\n\nVă puteți modifica opțiunile în orice moment <a href='javascript:Sddan.cmp.displayUI()'>făcând clic aici</a>.",
    pl: "[Nazwa firmy] uczestniczy we wszystkich Specyfikacjach i Politykach Transparency & Consent Framework IAB Europe oraz przestrzega ich. [On/Ona] korzysta z Consent Management Platform nr 92." +
        "<br/><br/>\n\nMożesz zmienić swój wybór w dowolnym momencie <a href='javascript:Sddan.cmp.displayUI()'>klikając tutaj</a>.",
    cs: "[Název společnosti] se podílí a vyhovuje veškerým specifikacím a politikám v rámci Transparency & Consent Framework sdružení IAB Europe. [On/Ona] používá Consent Management Platform č. 92." +
        "<br/><br/>\n\nVaši volbu můžete kdykoliv změnit <a href='javascript:Sddan.cmp.displayUI()'>kliknutím zde</a>.",
    el: "[Όνομα εταιρείας] συμμετέχει και συμμορφώνεται με το σύνολο των Προδιαγραφών και Πολιτικών της Transparency & Consent Framework από την IAB Europe. [Αυτός/Αυτή] χρησιμοποιεί την Consent Management Platform n°92." +
        "<br/><br/>\n\nΜπορείτε ανά πάσα στιγμή να τροποποιήσετε τις επιλογές σας <a href='javascript:Sddan.cmp.displayUI()'>πατώντας εδώ</a>.",
    da: "[Virksomhedens navn] deltager i og lever op til alle krav og regler i Transparency & Consent Framework fra l'IAB Europe. [Han/hun] anvender Consent Management Platform n°92." +
        "<br/><br/>\n\nDu kan ændre dine valg når som helst ved at <a href='javascript:Sddan.cmp.displayUI()'>klikke her</a>.",
    hu: "[Cégnév] részt vesz és betartja az IAB Europe Transparency & Consent Framework összes előírását és irányelvét. [Nevezett] a Consent Management Platform n°92-t használja." +
        "<br/><br/>\n\nPreferenciáit ön bármikor megváltoztathatja, ha <a href='javascript:Sddan.cmp.displayUI()'>ide kattint</a>."
};

export default INTEGRATION_TEXTS;