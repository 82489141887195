import React from "react";
import {MainContent, Wrapper} from "../common/component/widget";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {MainContentStyle, MainContentPageHeader, Paragraph, ParagraphAlignment, Table, TableColumn, TableRow, TableColumnStyle} from "@sirdata/ui-lib";
import {MainHeader} from "../common/component/snippet";
import {Formatter} from "../common/utils/Formatter";

type TermsOfSaleSection = {
    title: string;
    paragraphs: string[];
    noBullet?: boolean;
}

const PRICING_ITEMS = [
    {hits: Formatter.formatNumber(50000, "0,0"), price: "25 €"},
    {hits: Formatter.formatNumber(100000, "0,0"), price: "45 €", price_additional: 6},
    {hits: Formatter.formatNumber(1000000, "0,0"), price: "100 €", price_additional: 5},
    {hits: Formatter.formatNumber(10000000, "0,0"), price: "550 €", price_additional: 4}
];

function TermsOfSale() {
    const {t: textTermsOfSale} = useTranslation(TranslationPortalFile.TERMS_OF_SALE);
    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textTermsOfSale("title")}/>
                <Paragraph withSpacing>
                    <strong><i>{textTermsOfSale("lastUpdated")}</i></strong>
                </Paragraph>
                {(textTermsOfSale("sections", {returnObjects: true}) as TermsOfSaleSection[]).map(({title, paragraphs, noBullet}, sectionIndex) => {
                    const sectionKey = `terms-of-sale-section-${sectionIndex}`;
                    return (
                        <div key={sectionKey}>
                            {title && <h2>{!noBullet && `${sectionIndex}. `}{title}</h2>}
                            {paragraphs.map((item, itemIndex) => {
                                const sectionItemKey = `${sectionKey}.item-${itemIndex}`;
                                return (
                                    item === "[PRICING_TABLE]" ?
                                        <Table
                                            key={sectionItemKey}
                                            columns={[
                                                {width: 20, label: textTermsOfSale("pricing.hits"), styles: TableColumnStyle.ALIGN_CENTER},
                                                {width: 20, label: textTermsOfSale("pricing.price"), styles: TableColumnStyle.ALIGN_CENTER},
                                                {width: 60, label: textTermsOfSale("pricing.price_additional")}
                                            ]}
                                        >
                                            {PRICING_ITEMS.map(({hits, price, price_additional}) =>
                                                <TableRow key={hits}>
                                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{hits}</TableColumn>
                                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{price}</TableColumn>
                                                    <TableColumn>{price_additional ? textTermsOfSale("pricing.price_additional_per_100000", {price: price_additional}) : "-"}</TableColumn>
                                                </TableRow>
                                            )}
                                            <TableRow key={50000000}>
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatNumber(50000000, "0,0")}</TableColumn>
                                                <TableColumn colSpan={2} styles={TableColumnStyle.ALIGN_CENTER}>{textTermsOfSale("pricing.on_demand")}</TableColumn>
                                            </TableRow>
                                        </Table> :
                                        <Paragraph key={sectionItemKey} alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                                            {!noBullet && `${sectionIndex}.${itemIndex + 1}. `}<span dangerouslySetInnerHTML={{__html: item}}/>
                                        </Paragraph>
                                );
                            })}
                        </div>
                    );
                })}
            </MainContent>
        </Wrapper>
    );
}

export default TermsOfSale;
