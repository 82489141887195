import React, {FunctionComponent, useEffect, useState} from "react";
import {Action, ActionsMenu, Color, IconTooltip, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigStatus} from "../../api/model/cmp/config/CmpConfigStatus";
import {TranslationPortalFile} from "../../utils/constants";
import {CmpPreviewHelper} from "../../utils/CmpPreviewHelper";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";

type CmpRowProps = {
    cmp: CmpConfig;
    onCopy: () => void;
    onPublish: () => void;
    onDelete: () => void;
}

const CmpRow: FunctionComponent<CmpRowProps> = ({cmp, onCopy, onPublish, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const navigate = useNavigate();
    const [nextStep, setNextStep] = useState<CmpConfigStepName>();

    useEffect(() => {
        if (cmp.status === CmpConfigStatus.INACTIVE) {
            const nextStep = cmp.getNextStep();
            if (nextStep) {
                setNextStep(nextStep.name as CmpConfigStepName);
            }
        }
    }, [cmp]);

    const handleOpenPreview = () => {
        CmpPreviewHelper.openPreview(cmp);
    };

    const buildCmpConfigStepItems = (cmp: CmpConfig) => {
        return CmpConfigStepper.steps.filter((it) => it.name !== CmpConfigStepName.INTEGRATION).map((step) =>
            ({label: t(`list.action.edit_step.${step.name}`), onClick: () => navigate(step.getPath(cmp.id))})
        );
    };

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                {cmp.status === CmpConfigStatus.ACTIVE ?
                    <StatusLabel icon={{name: "circle", colorIcon: Color.GREEN}} label={t("list.status_active")}/> :
                    (!!cmp.step ?
                        <StatusLabel icon={{name: "circle", colorIcon: Color.GREY}} label={t("list.status_inprogress")}/> :
                        <StatusLabel icon={{name: "circle", colorIcon: Color.ORANGE}} label={t("list.status_inactive")}/>
                    )
                }
            </TableColumn>
            <TableColumn>{cmp.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{cmp.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{cmp.domain}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                {
                    cmp.status === CmpConfigStatus.ACTIVE ?
                        <IconTooltip
                            icon={{name: "visibility"}}
                            text={t("list.action.preview")}
                            cssClass={TableColumnStyle.HIDE_SCREEN_MEDIUM}
                            onClick={handleOpenPreview}
                        /> : <></>
                }
                {
                    !!nextStep ?
                        <IconTooltip
                            icon={Action.EDIT.icon}
                            text={t("list.action.resume")}
                            onClick={() => navigate(cmp.getPath(nextStep))}
                        /> :
                        <ActionsMenu
                            iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                            items={buildCmpConfigStepItems(cmp)}
                        />
                }
                {
                    !cmp.step ?
                        (cmp.status === CmpConfigStatus.ACTIVE ?
                            <ActionsMenu
                                iconTooltip={{
                                    icon: Action.MORE_ACTIONS.icon,
                                    text: textCommon(Action.MORE_ACTIONS.labelKey)
                                }}
                                items={[
                                    {label: t(`list.action.edit_step.${CmpConfigStepName.INTEGRATION}`), onClick: () => navigate(cmp.getPath(CmpConfigStepName.INTEGRATION))},
                                    {label: t("list.action.disable"), onClick: onPublish},
                                    {label: textCommon(Action.DUPLICATE.labelKey), onClick: onCopy},
                                    {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true},
                                    {label: t("list.action.preview"), onClick: handleOpenPreview, separator: true, cssClass: TableColumnStyle.HIDE_SCREEN_LARGE}
                                ]}
                            /> :
                            <ActionsMenu
                                iconTooltip={{
                                    icon: Action.MORE_ACTIONS.icon,
                                    text: textCommon(Action.MORE_ACTIONS.labelKey)
                                }}
                                items={[
                                    {label: t(`list.action.edit_step.${CmpConfigStepName.INTEGRATION}`), onClick: () => navigate(cmp.getPath(CmpConfigStepName.INTEGRATION))},
                                    {label: t("list.action.enable"), onClick: onPublish},
                                    {label: textCommon(Action.DUPLICATE.labelKey), onClick: onCopy},
                                    {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true}
                                ]}
                            />) :
                        (
                            <ActionsMenu
                                iconTooltip={{
                                    icon: Action.MORE_ACTIONS.icon,
                                    text: textCommon(Action.MORE_ACTIONS.labelKey)
                                }}
                                items={[
                                    {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true},
                                    {label: t("list.action.preview"), onClick: () => handleOpenPreview, separator: true}
                                ]}
                            />
                        )
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default CmpRow;
