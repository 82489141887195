import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {GoogleACProvider} from "./GoogleACProvider";
import {GoogleACProviderListField} from "./GoogleACProviderListField";

export class GoogleACProviderList extends ApiModel {
    lastUpdated: string = "";
    providers: GoogleACProvider[] = [];

    static TCF_PURPOSES = [1, 3, 4];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(GoogleACProviderListField.PROVIDERS, GoogleACProvider);
    }
}
