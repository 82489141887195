import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Action, Button, ButtonLink, ButtonLinkStyle, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalNew, ModalTitle} from "@sirdata/ui-lib";

type ModalCreateConfigConfirmationProps = {
    active: boolean;
    onClose: () => void;
    onOpenPreview: () => void;
}

const ModalCreateConfigConfirmation: FunctionComponent<ModalCreateConfigConfirmationProps> = ({active, onClose, onOpenPreview}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    return (
        <ModalNew active={active}>
            <ModalContent>
                <ModalTitle>{textConfiguration("modal.creation_confirmation.title")}</ModalTitle>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textConfiguration("modal.creation_confirmation.text")}}/>
                    </ModalDescription>
                    <FormLayoutButtons>
                        <ButtonLink icon={Action.OPEN.icon} style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={onOpenPreview}>
                            {textConfiguration("modal.creation_confirmation.button_preview")}
                        </ButtonLink>
                        <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={onClose}>
                            {textConfiguration("modal.creation_confirmation.button_install")}
                        </Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalCreateConfigConfirmation;
