import {FunctionComponent} from "react";
import {CMP_CONFIG_THEME_POSITIONS, CmpConfigThemePosition} from "../../../api/model/cmp/config/theme/CmpConfigThemePosition";
import {FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, RadioButton} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";

type ConfigThemePositionProps = {
    value: CmpConfigThemePosition;
    onChange: (position: CmpConfigThemePosition) => void;
};

const ConfigThemePosition: FunctionComponent<ConfigThemePositionProps> = ({value, onChange}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.LARGE}
            alignment={FlexContentAlignment.CENTER}
            allowWrap
            cssClass="theme-position"
        >
            <div className="theme-position__preview">
                <img src={`/images/preview/cmp_preview_${value.toLowerCase()}.svg`} alt={value}/>
            </div>
            <div className="theme-position__options">
                {CMP_CONFIG_THEME_POSITIONS.map((it) =>
                    <RadioButton
                        key={it}
                        id={it}
                        value={it}
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.POSITION}.${it}`)}
                        checked={value === it}
                        onChange={() => onChange(it)}
                    />
                )}
            </div>
        </FlexContent>
    );
};

export default ConfigThemePosition;
