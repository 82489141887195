import {ApiModel} from "../../../common/api/model/ApiModel";
import {Color, IconProps} from "@sirdata/ui-lib";

export class Audit extends ApiModel {
    domain: string = "";
    score_gdpr: number = 0;
    items: AuditItem[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class AuditItem extends ApiModel {
    id: number = 0;
    title: string = "";
    level: AuditItemLevel | undefined;
    good_answer: boolean = false;
}

export enum AuditItemLevel {
    MINOR = "MINOR",
    MAJOR = "MAJOR",
    CRITICAL = "CRITICAL"
}

export const AUDIT_ITEM_LEVELS = [
    {value: AuditItemLevel.MINOR, color: Color.YELLOW, icon: {name: "error", outlined: true} as IconProps},
    {value: AuditItemLevel.MAJOR, color: Color.ORANGE, icon: {name: "warning", outlined: true} as IconProps},
    {value: AuditItemLevel.CRITICAL, color: Color.RED, icon: {name: "warning", outlined: true} as IconProps}
];
