import {ApiModel} from "../../../../common/api/model/ApiModel";

export class StatConsentRatings extends ApiModel {
    consent: number = 0;
    object: number = 0;
    no_choice: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

