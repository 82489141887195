import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {Color} from "@sirdata/ui-lib";

type ScoreCircleProps = {
    title: string;
    value: number;
    site: string;
};

const ScoreCircle: FunctionComponent<ScoreCircleProps> = (props) => {
    const {
        title,
        value,
        site
    } = props;
    const score = Math.round(value);
    const color = score > 75 ? Color.GREEN :  (score > 50 ? Color.ORANGE : Color.RED);

    return (
        <div className="score-circle-container">
            <div className={clsx("score-circle", `p${score}`)}>
                <div className="score-circle-value">
                    <span className="score-circle-label">{title}</span>
                    <span className="score-circle-number">{score}%</span>
                </div>
                <div className="score-circle-track">
                    <div className="score-circle-run" style={{borderColor: color}}/>
                    <div className="score-circle-fixed"/>
                </div>
            </div>
            <div className="score-circle-title">{site}</div>
        </div>
    );
};

export default ScoreCircle;
