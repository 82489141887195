import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class SirdataStack extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    purposes: number[] = [];
    specialFeatures: number[] = [];
    requiredPurposes: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
