import {ApiClient} from "./ApiClient";
import {Authorization} from "./model/account/Authorization";
import {CmpConfig} from "./model/cmp/config/CmpConfig";
import {CommonApiSession} from "../common/api/CommonApiSession";
import {RestCmpConfig} from "./rest/RestCmpConfig";
import {RestPortalCmp} from "./rest/RestPortalCmp";
import {RestPartner} from "./rest/RestPartner";
import {RestList} from "./rest/RestList";
import {RestMailing} from "./rest/RestMailing";

export class ApiSession extends CommonApiSession {

    _client: ApiClient;

    private _cmpConfigs: CmpConfig[] | undefined;

    private _restCmpConfig: RestCmpConfig | undefined;
    private _restList: RestList | undefined;
    private _restMailing: RestMailing | undefined;
    private _restPartner: RestPartner | undefined;
    private _restPortalCmp: RestPortalCmp | undefined;

    constructor() {
        super();
        this._client = new ApiClient();
    }

    get restCmpConfig(): RestCmpConfig {
        return this._restCmpConfig !== undefined ? this._restCmpConfig : this._restCmpConfig = new RestCmpConfig(this._client);
    }

    get restList(): RestList {
        return this._restList !== undefined ? this._restList : this._restList = new RestList(this._client);
    }

    get restMailing(): RestMailing {
        return this._restMailing !== undefined ? this._restMailing : this._restMailing = new RestMailing(this._client);
    }

    get restPartner(): RestPartner {
        return this._restPartner !== undefined ? this._restPartner : this._restPartner = new RestPartner(this._client);
    }

    get restPortalCmp(): RestPortalCmp {
        return this._restPortalCmp !== undefined ? this._restPortalCmp : this._restPortalCmp = new RestPortalCmp(this._client);
    }

    async getCmpConfigs(): Promise<CmpConfig[]> {
        if (this._cmpConfigs === undefined) {
            this._cmpConfigs = await this.restCmpConfig.list();
        }
        return this._cmpConfigs as CmpConfig[];
    }

    async isPremium(): Promise<boolean> {
        const account = await this.getAccount();
        return account?.hasAuthorization(Authorization.PREMIUM);
    }

    async hasOutdatedConfig(): Promise<boolean> {
        const cmpConfigs = await this.getCmpConfigs();
        return !!cmpConfigs.find((it) => it.isOutdated());
    }
}

export const session = new ApiSession();
