import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {pathCmp, TranslationPortalFile} from "../../../utils/constants";
import {Action, Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, Icon, MainHeaderSubLayout, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalNew, SplitButton, TranslationLibFile, TranslationLibKey} from "@sirdata/ui-lib";
import {ConfigHeaderMenu} from "../index";
import {CmpConfigStepName} from "../../../utils/CmpConfigStepper";

type ConfigHeaderEditProps = {
    step: CmpConfigStepName;
    hasUnsavedChanges: boolean;
    onSave: () => Promise<void>;
    onOpenPreview?: () => void;
}

const ConfigHeaderEdit: FunctionComponent<ConfigHeaderEditProps> = ({step, hasUnsavedChanges, onSave, onOpenPreview = () => {}}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const navigate = useNavigate();

    const [isShowEditWarning, setShowEditWarning] = useState(false);
    const [shouldConfirmBeforeQuit, setShouldConfirmBeforeQuit] = useState(hasUnsavedChanges);
    const [goToPath, setGoToPath] = useState("");
    const [isChangesSaved, setChangesSaved] = useState(false);

    const handleSave = async () => {
        await onSave();
        setChangesSaved(true);
        setTimeout(() => setChangesSaved(false), 1000);
    };

    const handleGoTo = (path: string) => {
        if (shouldConfirmBeforeQuit) {
            setGoToPath(path);
            setShowEditWarning(true);
        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        setShouldConfirmBeforeQuit(hasUnsavedChanges);
    }, [hasUnsavedChanges]);

    return (
        <MainHeaderSubLayout cssClass="config-header">
            <ConfigHeaderMenu step={step} onChangePath={handleGoTo}/>
            <div className="config-header__buttons">
                {isChangesSaved &&
                    <span className="config-header__buttons__label">
                        <Icon name="check"/>{textConfiguration("message.changes_saved")}
                    </span>
                }
                {step === CmpConfigStepName.INTEGRATION ?
                    <>
                        <SplitButton
                            size={ButtonSize.SMALL}
                            style={ButtonStyle.PRIMARY_WHITE}
                            actions={[
                                {label: textConfiguration("action.back_to_sites"), onClick: () => handleGoTo(pathCmp)},
                                {label: textConfiguration("action.preview"), onClick: onOpenPreview}
                            ]}
                        />
                        <div className="inline-buttons">
                            <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_WHITE} icon={{name: "visibility"}} onClick={onOpenPreview}>
                                {textConfiguration("action.preview")}
                            </Button>
                            <Button
                                size={ButtonSize.SMALL}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                icon={Action.BACK.icon}
                                onClick={() => handleGoTo(pathCmp)}
                            >
                                {textConfiguration("action.back_to_sites")}
                            </Button>
                        </div>
                    </> :
                    <>
                        <SplitButton
                            size={ButtonSize.SMALL}
                            style={ButtonStyle.PRIMARY_GREEN}
                            actions={[
                                {label: textCommon(Action.SAVE.labelKey), onClick: handleSave},
                                {label: textConfiguration("action.back_to_sites"), onClick: () => handleGoTo(pathCmp)}
                            ]}
                        />
                        <div className="inline-buttons">
                            <Button
                                size={ButtonSize.SMALL}
                                icon={Action.BACK.icon}
                                style={ButtonStyle.PRIMARY_WHITE}
                                onClick={() => handleGoTo(pathCmp)}
                            >
                                {textConfiguration("action.back_to_sites")}
                            </Button>
                            <Button
                                size={ButtonSize.SMALL}
                                icon={Action.SAVE.icon}
                                style={ButtonStyle.PRIMARY_GREEN}
                                onClick={handleSave}
                            >
                                {textCommon(Action.SAVE.labelKey)}
                            </Button>
                        </div>
                    </>
                }
            </div>
            <ModalNew active={isShowEditWarning} onClose={() => setShowEditWarning(false)}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textConfiguration("message.warning_edit")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setShowEditWarning(false)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={() => navigate(goToPath)}
                            >
                                {textCommon(TranslationLibKey.ACTION_QUIT)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </MainHeaderSubLayout>
    );
};

export default ConfigHeaderEdit;
