import React, {ReactNode} from "react";
import {pathCmp} from "../../../utils/constants";
import {sirdataDocumentationTagManagementUrl} from "../../../utils/helper";
import {Color} from "@sirdata/ui-lib";

const colorNo = "#FF0000";
const colorYes = "#33AA33";
const styleBlack = {
    background: Color.MIDNIGHT,
    color: Color.GREY,
    border: `1px solid ${Color.MIDNIGHT}`
};
const styleWhite = {
    background: Color.WHITE,
    border: "1px solid #505050",
    color: Color.BLACK_LIGHT
};
const styleGreen = {
    background: Color.WHITE,
    color: Color.GREEN,
    border: `1px solid ${Color.GREEN}`
};

function item(id: string, label: ReactNode, position: { x: number; y: number }, style: any, type?: string | undefined) {
    return {
        id: id,
        type: type,
        data: {
            label: label
        },
        style: style,
        position: position
    };
}

function itemBlack(id: string, label: ReactNode, position: { x: number; y: number }, width: number = 180, height?: number | undefined) {
    return item(id, label, position, {...styleBlack, ...{width: width, height: height}});
}

function itemWhite(id: string, label: ReactNode, position: { x: number; y: number }, width: number = 180, height?: number | undefined) {
    return item(id, label, position, {...styleWhite, ...{width: width, height: height}});
}

function itemGreen(id: string, label: ReactNode, position: { x: number; y: number }, width: number = 180, height?: number | undefined) {
    return item(id, label, position, {...styleGreen, ...{width: width, height: height}}, "output");
}

function link(source: string, target: string, label: string, color: any, animated: boolean = false) {
    return {
        id: `${source}-${target}`,
        source: source,
        target: target,
        label: label,
        type: "step",
        style: {stroke: color},
        labelStyle: {fill: "#FFFFFF", fontWeight: 700},
        labelBgStyle: {fill: color},
        animated: animated
    };
}

function linkSimple(source: string, target: string, animated: boolean = false) {
    return link(source, target, "", undefined, animated);
}

function linkYes(source: string, target: string, reverse: boolean = false) {
    return link(source, target, "YES", reverse ? colorNo : colorYes, !reverse);
}

function linkNo(source: string, target: string, reverse: boolean = false) {
    return link(source, target, "NO", reverse ? colorYes : colorNo, reverse);
}

const GoogleComplianceFlowChartConfigEn = {
    nodes: [
        item("1",
            <>
                <strong>Do you use Sirdata CMP on your website(s)?</strong>
            </>,
            {x: 575, y: 35}, {...styleWhite, width: 315, height: 65, fontSize: "18px"}, "input"),
        itemGreen("1a",
            <>
                <a href={pathCmp} target={"_blank"} rel="noopener noreferrer"><u>Create and deploy your CMP</u></a>
            </>,
            {x: 485, y: 165}),
        itemBlack("1b",
            <>
                Is Google activated?
            </>,
            {x: 800, y: 165}),
        itemWhite("2",
            <>
                Did you activate Google <strong>Google Advertising Products</strong> (ID 755) with the required purposes (1,2,3,4,7,9,10)?
            </>,
            {x: 800, y: 205}),
        itemGreen("2a",
            <>
                Add <strong>Google Advertising Products</strong> to your partners list in your CMP
            </>,
            {x: 1110, y: 235}),
        itemBlack("3",
            <>
                Do you have a Google errors report?
            </>,
            {x: 485, y: 235}),
        itemGreen("3a",
            <>
                Access to your AdSense or Ad Manager account to download the errors report
            </>,
            {x: 235, y: 350}),
        itemBlack("4",
            <>
                Which of your domains are concerned by these errors?
            </>,
            {x: 800, y: 350}),
        itemBlack("4a",
            <>
                Your domains
            </>,
            {x: 520, y: 450}),
        itemBlack("4b",
            <>
                Unknown domain(s)
            </>,
            {x: 1050, y: 450}),
        itemBlack("4c",
            <>
                Google AMP
            </>,
            {x: 1300, y: 450}),
        itemWhite("4ca",
            <>
                <strong>AMP</strong>
                <br/>(Accelerated Mobile Pages)
                <br/><br/>Did you integrate the special Sirdata CMP v2 script for AMP yet?
            </>,
            {x: 1300, y: 490}),
        itemGreen("4caa",
            <>
                Just go and get your special AMP script directly in your CMP edition
            </>,
            {x: 1180, y: 825}),
        item("4cab",
            <>
                <strong>Your integration is correct.</strong>
                <br/>
                <br/>Unfortunately, the AMP Framework is not yet enabling any conditioning for advertising calls. We simply asked Google to take into account the signal shared by the CMP without counting any class 1 (1.1 etc.) or 6 (6.1 etc.) error.
                <br/>Until they technically take into account this request and any AMP evolution moving forward, Google confirmed us these registered errors for AMP can be ignored.
            </>,
            {x: 1300, y: 945}, {...styleWhite, ...{width: 180, border: "5px solid #FF0000"}}),
        itemWhite("4ba",
            <>
                Errors for "unknown" domains are errors triggered within <strong>iframes</strong> (partners under delegation) or <strong>adservers</strong> in iframe.
            </>,
            {x: 1050, y: 490}),
        itemBlack("5a",
            <>
                <strong>Class 1 errors (1.1. to 1.3)</strong>
            </>,
            {x: 235, y: 520}, undefined, 65),
        itemWhite("5aa",
            <>
                Did you condition your AdSense or GPT tag to call Google only in case of consent?
            </>,
            {x: 235, y: 590}),
        itemBlack("5b",
            <>
                <strong>Class 2 errors (2.1a to 2.2c), 3 (3.1 to 3.3), 4.1 , 7 (7.1 to 7.9) & 8 (8.1 to 8.2)</strong>
            </>,
            {x: 425, y: 520}, undefined, 65),
        itemWhite("5ba",
            <>
                Make sure the CMP scripts are placed on top of your code, in the &lt;head/&gt; before any other tags and in particular Google's ones
            </>,
            {x: 425, y: 590}),
        itemWhite("5bb",
            <>
                <strong>Important :</strong>
                <br/>This class errors resolution doesn't avoid class 1, and in particular, 1.1 errors to happen.
                <br/>We strongly recommend you to condition your tags following the errors class 1 resolution procedure.
            </>,
            {x: 425, y: 695}),
        itemBlack("5c",
            <>
                <strong>Class 5 errors (5.1 to 5.2)</strong>
            </>,
            {x: 615, y: 520}, undefined, 65),
        itemWhite("5ca",
            <>
                Make sure you don't use the "global" scope.
                <br/>
                <br/>To do so, access to your CMP's display edition, and go to the <strong>Advanced settings</strong> section.
                <br/>In the <strong>Technical setting</strong> party, check the field <strong>Apply user choices</strong> is not defined as <i>"On all sites using the IAB TCF global scope"</i>.
                <br/>
                <br/>Indeed, Google refuses to work under this scope.
                <br/>The scope used by default <i>"On the sites included in Sirdata network consentframework.com"</i> is the best alternative to the global scope.
            </>,
            {x: 615, y: 590}),
        itemBlack("5d",
            <>
                <strong>Class 6.1 errors </strong>
            </>,
            {x: 805, y: 520}, undefined, 65),
        itemWhite("5da",
            <>
                Delete from your website any component related to the IAB Europe <i>Transparency and Consent Framework</i> v1 (whever it is Sirdata CMP or another).
                <br/>
                <br/>Empty your caches.
            </>,
            {x: 805, y: 590}),
        itemBlack("5e",
            <>
                <strong>Source of the traffic</strong>
            </>,
            {x: 1050, y: 640}),
        itemWhite("5ea",
            <>
                Do you have any traffic coming from
                <br/><strong>social networks</strong>, from <strong>readers</strong> or from news <strong>applications</strong> (examples : Facebook, News Republic, Squid, Newsify...)?
            </>,
            {x: 1050, y: 680}),
        itemGreen("5eaa",
            <>
                Follow the class 1 errors resolution procedure
            </>,
            {x: 830, y: 760}),
        itemWhite("6a",
            <>
                <strong>Condition AdSense tag</strong><br/>adsbygoogle.js<br/><br/>If the tag includes your <strong>ca-pub</strong> identifier, you have to replace it by the script provided for the conditionning and indicate your <strong>ca-pub</strong> identifier
            </>,
            {x: 130, y: 750}),
        itemGreen("6aa",
            <>
                <a href={sirdataDocumentationTagManagementUrl} target={"_blank"} rel="noopener noreferrer"><u>Condition the AdSense and GPT scripts</u></a>
            </>,
            {x: 15, y: 900}, 100),
        itemWhite("6b",
            <>
                <strong>Condition the GPT tag</strong><br/>gpt.js
            </>,
            {x: 130, y: 920}),
        itemBlack("7",
            <>
                <strong>Redundancies</strong>
            </>,
            {x: 130, y: 1015}, 500),
        itemWhite("7a",
            <>
                <strong>Do you have only one adsbygoogle.js and/or gpt.js tag on your pages?</strong>
                <br/>
                <br/>Make sure you don't have any duplicate tag because each tag loaded on your page without being conditionned will generate errors.
                <br/>As a reminder, set several adsbygoogle.js or gpt.js tags on your pages is useless. This action doesn't drive further advertising calls and so not further revenues.
            </>,
            {x: 130, y: 1055}, 500),
        itemGreen("7aa",
            <>
                <strong>Delete redundancies</strong>
            </>,
            {x: 130, y: 1215}),
        itemBlack("8",
            <>
                <strong>Involuntary redundancies</strong>
            </>,
            {x: 130, y: 1305}, 500),
        itemWhite("8a",
            <>
                If you still meet errors despite the tags being conditionned and the non redundancy of your source code, this means a third party is injecting one of its tags while the pages are loading.
                <br/>Most of the time, these are advertising agencies or networks monetizing your inventory and which are using Google tools for their own usages or to manage your ad placements.
                <br/>If these last do not condition the tags, you can do it by yourself for them.
                <br/>
                <br/><strong>How to identify these tags?</strong>
                <br/>
                <br/>- Start Google Chrome in Incognito mode
                <br/>- Click on <strong>F12</strong>
                <br/>- In <strong>Network</strong>, filter results on <i>"ads?"</i>
                <br/>- Look at the tags fired when you click to refuse to give your consent
                <br/>- Identify the responsibles of these tags
            </>,
            {x: 130, y: 1345}, 500),
        itemBlack("9",
            <>
                <strong>Delegation to third parties</strong>
            </>,
            {x: 830, y: 905}, 400),
        itemWhite("9a",
            <>
                Third parties can inject AdSense ads or ads using GPT.
                <br/>This drives errors displayed on your account.
                <br/>
                <br/><strong>Did you make this kind of delegation to these partners (ad agencies)?</strong>
                <br/>
                <br/><strong>To identify them :</strong>
                <br/>
                <br/>- Start Google Chrome in Incognito mode
                <br/>- Click on <strong>F12</strong>
                <br/>- In <strong>Network</strong>, filter results on <i>"ads?"</i>
                <br/>- Look at the tags fired when you click to refuse to give your consent.
                <br/>- Identify the responsibles of these tags if you see some
                <br/>
                <br/><strong>AND / OR</strong>
                <br/>
                <br/>In your mail box, search for one or several emails sent by <strong>Google Ad Manager</strong> &lt;noreply-adx@google.com&gt; that invite you to accept an invitation for registering.
                <br/>
                <br/><strong>AND / OR</strong>
                <br/>
                <br/>If some of your partners appear to be in the list of conditionned tags, note that most of the time, these companies use this kind of practice.
            </>,
            {x: 830, y: 945}, 400),
        itemGreen("10",
            <>
                <strong>Condition your tags</strong>
                <br/>
                <br/>In the documentation, you will find the procedure to condition your tags easily, as well as examples.
                <br/>
                <br/><a href={sirdataDocumentationTagManagementUrl} target={"_blank"} rel="noopener noreferrer"><u>Go to the script management</u></a>
            </>,
            {x: 675, y: 1410}, 300),
        item("11",
            <>
                <strong><u>Attention:</u></strong>
                <br/>
                <br/>Use of Google show_ads :
                <br/>
                <br/>As the CMP is asynchronous, AdSense and Ad Manager synchronous tags (ex: show_ads.js) are not compatibles. we suggest you to switch your Google scripts into an asnchronous mode and follow the steps above.
            </>,
            {x: 1100, y: 1480}, {...styleWhite, ...{width: 400, border: "5px solid #FF0000"}})
    ],
    edges: [
        linkNo("1", "1a"),
        linkYes("1", "1b"),
        linkNo("2", "2a"),
        linkYes("2", "3"),
        linkNo("3", "3a"),
        linkYes("3", "4"),
        linkSimple("4", "4a", true),
        linkSimple("4", "4b", true),
        linkSimple("4", "4c", true),
        linkSimple("4a", "5a", true),
        linkSimple("4a", "5b", true),
        linkSimple("4a", "5c", true),
        linkSimple("4a", "5d", true),
        linkSimple("4b", "5e", true),
        linkNo("4ca", "4caa"),
        linkYes("4ca", "4cab"),
        linkSimple("5a", "5aa"),
        linkSimple("5b", "5ba"),
        linkSimple("5ba", "5bb"),
        linkSimple("5c", "5ca"),
        linkSimple("5d", "5da"),
        linkSimple("5e", "5ea"),
        linkNo("5aa", "6a"),
        linkYes("5aa", "7"),
        linkYes("5ea", "5eaa", true),
        linkNo("5ea", "9", true),
        linkSimple("6a", "6aa"),
        linkSimple("6a", "6b", true),
        linkSimple("6b", "6aa"),
        linkSimple("6b", "7", true),
        linkSimple("7", "7a"),
        linkNo("7a", "7aa"),
        linkYes("7a", "8"),
        linkSimple("8", "8a"),
        linkSimple("8a", "10", true),
        linkSimple("9", "9a"),
        linkSimple("9a", "10", true)
    ]
};

export default GoogleComplianceFlowChartConfigEn;
