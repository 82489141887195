import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpConfigPublisherRestrictionType} from "./CmpConfigPublisherRestrictionType";

export class CmpConfigPublisherRestriction extends ApiModel {
    purpose: number = 0;
    restrictionType: CmpConfigPublisherRestrictionType = CmpConfigPublisherRestrictionType.NOT_ALLOWED;
    vendors: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            purpose: this.purpose,
            restrictionType: this.restrictionType,
            vendors: this.vendors
        };
    }
}
