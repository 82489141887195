import React, {ReactNode} from "react";
import {pathCmp} from "../../../utils/constants";
import {sirdataDocumentationTagManagementUrl} from "../../../utils/helper";
import {Color} from "@sirdata/ui-lib";

const colorNo = "#FF0000";
const colorYes = "#33AA33";
const styleBlack = {
    background: Color.MIDNIGHT,
    color: Color.GREY,
    border: `1px solid ${Color.MIDNIGHT}`
};
const styleWhite = {
    background: Color.WHITE,
    border: "1px solid #505050",
    color: Color.BLACK_LIGHT
};
const styleGreen = {
    background: Color.WHITE,
    color: Color.GREEN,
    border: `1px solid ${Color.GREEN}`
};

function item(id: string, label: ReactNode, position: { x: number; y: number }, style: any, type?: string | undefined) {
    return {
        id: id,
        type: type,
        data: {
            label: label
        },
        style: style,
        position: position
    };
}

function itemBlack(id: string, label: ReactNode, position: { x: number; y: number }, width: number = 180, height?: number | undefined) {
    return item(id, label, position, {...styleBlack, ...{width: width, height: height}});
}

function itemWhite(id: string, label: ReactNode, position: { x: number; y: number }, width: number = 180, height?: number | undefined) {
    return item(id, label, position, {...styleWhite, ...{width: width, height: height}});
}

function itemGreen(id: string, label: ReactNode, position: { x: number; y: number }, width: number = 180, height?: number | undefined) {
    return item(id, label, position, {...styleGreen, ...{width: width, height: height}}, "output");
}

function link(source: string, target: string, label: string, color: any, animated: boolean = false) {
    return {
        id: `${source}-${target}`,
        source: source,
        target: target,
        label: label,
        type: "step",
        style: {stroke: color},
        labelStyle: {fill: "#FFFFFF", fontWeight: 700},
        labelBgStyle: {fill: color},
        animated: animated
    };
}

function linkSimple(source: string, target: string, animated: boolean = false) {
    return link(source, target, "", undefined, animated);
}

function linkYes(source: string, target: string, reverse: boolean = false) {
    return link(source, target, "OUI", reverse ? colorNo : colorYes, !reverse);
}

function linkNo(source: string, target: string, reverse: boolean = false) {
    return link(source, target, "NON", reverse ? colorYes : colorNo, reverse);
}

const GoogleComplianceFlowChartConfigFr = {
    nodes: [
        item("1",
            <>
                <strong>Utilisez-vous Sirdata CMP sur votre (vos) site(s) ?</strong>
            </>,
            {x: 575, y: 35}, {...styleWhite, width: 315, height: 65, fontSize: "18px"}, "input"),
        itemGreen("1a",
            <>
                <a href={pathCmp} target={"_blank"} rel="noopener noreferrer"><u>Créez et déployez votre CMP</u></a>
            </>,
            {x: 485, y: 165}),
        itemBlack("1b",
            <>
                Google est-il activé ?
            </>,
            {x: 800, y: 165}),
        itemWhite("2",
            <>
                Avez-vous activé <strong>Google Advertising Products</strong> (ID 755) avec les finalités requises (1,2,3,4,7,9,10) ?
            </>,
            {x: 800, y: 205}),
        itemGreen("2a",
            <>
                Ajoutez <strong>Google Advertising Products</strong> à la liste des partenaires dans votre CMP
            </>,
            {x: 1110, y: 235}),
        itemBlack("3",
            <>
                Disposez-vous d'un rapport d'erreurs Google ?
            </>,
            {x: 485, y: 235}),
        itemGreen("3a",
            <>
                Accédez à votre console AdSense ou Ad Manager pour télécharger le rapport d'erreurs
            </>,
            {x: 235, y: 350}),
        itemBlack("4",
            <>
                Quels sont les domaines concernés par les erreurs ?
            </>,
            {x: 800, y: 350}),
        itemBlack("4a",
            <>
                Vos domaines
            </>,
            {x: 520, y: 450}),
        itemBlack("4b",
            <>
                Domaine(s) inconnu(s)
            </>,
            {x: 1050, y: 450}),
        itemBlack("4c",
            <>
                Google AMP
            </>,
            {x: 1300, y: 450}),
        itemWhite("4ca",
            <>
                <strong>AMP</strong>
                <br/>(Accelerated Mobile Pages)
                <br/><br/>Avez-vous bien intégré le script Sirdata CMP v2 spécifique pour AMP ?
            </>,
            {x: 1300, y: 490}),
        itemGreen("4caa",
            <>
                Rendez-vous dans l'édition de votre CMP pour récupérer le code spécfique pour AMP.
            </>,
            {x: 1180, y: 825}),
        item("4cab",
            <>
                <strong>Votre intégration est correcte.</strong>
                <br/>
                <br/>Le Framework AMP a malheureusement un fonctionnement qui rend impossible à date le conditionnement des appels de publicités. Nous avons demandé à Google de simplement tenir compte du signal transmis par la CMP sans comptabiliser d'erreurs de classe 1 (1.1 etc.) ou 6 (6.1 etc.).
                <br/>En attendant la prise en compte technique de cette demande et des évolutions nécessaires d'AMP, Google nous a confirmé que ces erreurs comptabilisées pour AMP peuvent être ignorées.
            </>,
            {x: 1300, y: 945}, {...styleWhite, ...{width: 180, border: "5px solid #FF0000"}}),
        itemWhite("4ba",
            <>
                Les erreurs en domaine "inconnu" sont des erreurs qui sont déclenchées dans des <strong>iframes</strong> (partenaires sous délégation) ou <strong>adservers</strong> en iframe.
            </>,
            {x: 1050, y: 490}),
        itemBlack("5a",
            <>
                <strong>Erreurs de classe 1 (1.1. à 1.3)</strong>
            </>,
            {x: 235, y: 520}, undefined, 65),
        itemWhite("5aa",
            <>
                Avez-vous conditionné votre tag AdSense ou GPT pour n'appeler Google qu'en cas de consentement et respecter leur volonté ?
            </>,
            {x: 235, y: 590}),
        itemBlack("5b",
            <>
                <strong>Erreurs de classes 2 (2.1a à 2.2c), 3 (3.1 à 3.3), 4.1 , 7 (7.1 à 7.9) & 8 (8.1 à 8.2)</strong>
            </>,
            {x: 425, y: 520}, undefined, 65),
        itemWhite("5ba",
            <>
                Assurez-vous que les scripts de la CMP se trouvent tout en haut dans votre code, dans le &lt;head/&gt; avant tous les autres tags et en particulier ceux de Google.
            </>,
            {x: 425, y: 590}),
        itemWhite("5bb",
            <>
                <strong>Important :</strong>
                <br/>La résolution de cette classe d'erreurs n'empêche pas les erreurs de classe 1 et en particulier l'erreur 1.1 de se produire.
                <br/>Nous vous conseillons de tout de suite procéder au conditionnement des tags en suivant la procédure de résolution d'erreurs de classe 1.
            </>,
            {x: 425, y: 710}),
        itemBlack("5c",
            <>
                <strong>Erreurs de classe 5 (5.1 à 5.2)</strong>
            </>,
            {x: 615, y: 520}, undefined, 65),
        itemWhite("5ca",
            <>
                Assurez-vous que vous n'utilisez pas le scope "global".
                <br/>
                <br/>Pour cela, accédez à l'édition de l'affichage de votre CMP, puis rendez-vous dans la section <strong>Paramètres avancés</strong>.
                <br/>Dans la partie <strong>Paramètrage technique</strong>, vérifiez que le champs <strong>Appliquer le choix des utilisateurs</strong> ne soit pas défini sur <i>"Sur tous les sites utilisant le scope global du TCF de l'IAB"</i>.
                <br/>
                <br/>En effet, Google refuse de travailler sur ce scope.
                <br/>Le scope utilisé par défaut <i>"Sur les sites du réseau Sirdata consentframework.com"</i> est la meilleure alternative au scope global.
            </>,
            {x: 615, y: 590}),
        itemBlack("5d",
            <>
                <strong>Erreurs de classe 6.1</strong>
            </>,
            {x: 805, y: 520}, undefined, 65),
        itemWhite("5da",
            <>
                Supprimez de votre site tous les éléments en lien avec la v1 du <i>Transparency and Consent Framework</i> de l'IAB Europe (que ce soit Sirdata CMP ou une autre CMP).
                <br/>
                <br/>Pensez à vider vos caches.
            </>,
            {x: 805, y: 590}),
        itemBlack("5e",
            <>
                <strong>Provenance du trafic</strong>
            </>,
            {x: 1050, y: 640}),
        itemWhite("5ea",
            <>
                Avez-vous du trafic provenant
                <br/>de <strong>réseaux sociaux</strong>, de <strong>readers</strong> ou d'<strong>applications</strong> de news (exemples : Facebook, News Republic, Squid, Newsify...) ?
            </>,
            {x: 1050, y: 680}),
        itemGreen("5eaa",
            <>
                Suivez la procédure de résolution des erreurs de classe 1
            </>,
            {x: 830, y: 760}),
        itemWhite("6a",
            <>
                <strong>Conditionnez le tag AdSense</strong><br/>adsbygoogle.js<br/><br/>Si le tag contient votre identifiant <strong>ca-pub</strong>, vous devez le remplacer par le script de conditionnement fourni en y renseignant votre identifiant <strong>ca-pub</strong>
            </>,
            {x: 130, y: 750}),
        itemGreen("6aa",
            <>
                <a href={sirdataDocumentationTagManagementUrl} target={"_blank"} rel="noopener noreferrer"><u>Conditionner les scripts AdSense et GPT</u></a>
            </>,
            {x: 15, y: 900}, 100),
        itemWhite("6b",
            <>
                <strong>Conditionnez le tag GPT</strong><br/>gpt.js
            </>,
            {x: 130, y: 935}),
        itemBlack("7",
            <>
                <strong>Redondances</strong>
            </>,
            {x: 130, y: 1015}, 500),
        itemWhite("7a",
            <>
                <strong>Avez-vous bien un seul tag adsbygoogle.js et/ou gpt.js sur vos pages ?</strong>
                <br/>
                <br/>Assurez-vous de ne pas avoir de doublon car chaque tag chargé sans conditionnement sur votre page génère alors des erreurs.
                <br/>Pour rappel, mettre plusieurs tags adsbygoogle.js ou gpt.js sur vos pages est inutile. Cette action n'engendre pas plus d'affichages publicitaires et donc pas plus de revenus.
            </>,
            {x: 130, y: 1055}, 500),
        itemGreen("7aa",
            <>
                <strong>Supprimez les redondances</strong>
            </>,
            {x: 130, y: 1215}),
        itemBlack("8",
            <>
                <strong>Redondances involontaires</strong>
            </>,
            {x: 130, y: 1305}, 500),
        itemWhite("8a",
            <>
                Si vous avez toujours des erreurs malgré le conditionnement des tags et la non-redondance dans votre code source, c'est nécessairement qu'un tiers injecte un de ses tags au chargement des pages.
                <br/>Il s'agit de régies qui monétisent votre inventaire et qui utilisent des outils Google pour leur propre compte ou pour gérer vos emplacements.
                <br/>Si ces derniers ne conditionnent pas leurs tags Google, vous pouvez le faire à leur place.
                <br/>
                <br/><strong>Comment identifier ces tags ?</strong>
                <br/>
                <br/>- Démarrez Google Chrome en navigation privée
                <br/>- Appuyez sur <strong>F12</strong>
                <br/>- Dans <strong>Network</strong>, filtrez les résultats sur <i>"ads?"</i>
                <br/>- Observez les tags qui se déclenchent lorsque vous cliquez pour refuser de donner votre consentement
                <br/>- Identifiez les responsables de ces tags
            </>,
            {x: 130, y: 1345}, 500),
        itemBlack("9",
            <>
                <strong>Délégations à des tiers</strong>
            </>,
            {x: 830, y: 905}, 400),
        itemWhite("9a",
            <>
                Des tiers peuvent injecter des publicités AdSense ou utilisant GPT.
                <br/>Cela génère des erreurs qui remontent sur votre compte.
                <br/>
                <br/><strong>Avez-vous fait ce type de délégation à des partenaires (régies) ?</strong>
                <br/>
                <br/><strong>Pour les identifier :</strong>
                <br/>
                <br/>- Démarrez Google Chrome en navigation privée
                <br/>- Appuyez sur <strong>F12</strong>
                <br/>- Dans <strong>Network</strong>, filtrez les résultats sur <i>"ads?"</i>
                <br/>- Observez si des publicités Google sont appelées lorsque vous cliquez pour refuser de donner votre consentement.
                <br/>- Identifiez les responsables si vous voyez ces tags
                <br/>
                <br/><strong>ET / OU</strong>
                <br/>
                <br/>Recherchez dans vos historiques un ou plusieurs emails envoyés par <strong>Google Ad Manager</strong> &lt;noreply-adx@google.com&gt; qui vous invitent à accepter une invitation à s'enregistrer.
                <br/>
                <br/><strong>ET / OU</strong>
                <br/>
                <br/>Si vous avez des partenaires parmi la liste de nos tags de conditionnement, ces sociétés ont très souvent recours à ce type de pratique.
            </>,
            {x: 830, y: 945}, 400),
        itemGreen("10",
            <>
                <strong>Conditionnez vos tags</strong>
                <br/>
                <br/>Vous trouverez dans la documentation la procédure pour conditionner facilement vos tags, ainsi que plusieurs exemples.
                <br/>
                <br/><a href={sirdataDocumentationTagManagementUrl} target={"_blank"} rel="noopener noreferrer"><u>Accéder à la gestion des scripts</u></a>
            </>,
            {x: 675, y: 1410}, 300),
        item("11",
            <>
                <strong><u>A noter :</u></strong>
                <br/>
                <br/>Utilisation de Google show_ads :
                <br/>
                <br/>La CMP ayant un fonctionnement asynchrone, les tags synchrones d'AdSense et Ad Manager (ex: show_ads.js) ne sont pas compatibles. Nous vous suggérons de passer vos scripts Google en asynchrone et suivre les étapes ci-dessus.
            </>,
            {x: 1100, y: 1480}, {...styleWhite, ...{width: 400, border: "5px solid #FF0000"}})
    ],
    edges: [
        linkNo("1", "1a"),
        linkYes("1", "1b"),
        linkNo("2", "2a"),
        linkYes("2", "3"),
        linkNo("3", "3a"),
        linkYes("3", "4"),
        linkSimple("4", "4a", true),
        linkSimple("4", "4b", true),
        linkSimple("4", "4c", true),
        linkSimple("4a", "5a", true),
        linkSimple("4a", "5b", true),
        linkSimple("4a", "5c", true),
        linkSimple("4a", "5d", true),
        linkSimple("4b", "5e", true),
        linkNo("4ca", "4caa"),
        linkYes("4ca", "4cab"),
        linkSimple("5a", "5aa"),
        linkSimple("5b", "5ba"),
        linkSimple("5ba", "5bb"),
        linkSimple("5c", "5ca"),
        linkSimple("5d", "5da"),
        linkSimple("5e", "5ea"),
        linkNo("5aa", "6a"),
        linkYes("5aa", "7"),
        linkYes("5ea", "5eaa", true),
        linkNo("5ea", "9", true),
        linkSimple("6a", "6aa"),
        linkSimple("6a", "6b", true),
        linkSimple("6b", "6aa"),
        linkSimple("6b", "7", true),
        linkSimple("7", "7a"),
        linkNo("7a", "7aa"),
        linkYes("7a", "8"),
        linkSimple("8", "8a"),
        linkSimple("8a", "10", true),
        linkSimple("9", "9a"),
        linkSimple("9a", "10", true)
    ]
};

export default GoogleComplianceFlowChartConfigFr;

