import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {
    Action,
    ActionsMenu,
    Alert,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxSpacing,
    Button,
    ButtonSize,
    ButtonStyle,
    Checkbox,
    CircleStep,
    ContentBlock,
    FlexContent,
    FlexContentDirection,
    FlexContentSpacing,
    LayoutColumns,
    LayoutRows,
    MainContentPageHeader,
    Tabs,
    TranslationLibFile
} from "@sirdata/ui-lib";
import clsx from "clsx";
import {session} from "../../api/ApiSession";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigPublisherRestriction} from "../../api/model/cmp/config/CmpConfigPublisherRestriction";
import {CmpConfigPublisherRestrictionType} from "../../api/model/cmp/config/CmpConfigPublisherRestrictionType";
import {CmpConfigPublisher} from "../../api/model/cmp/config/publisher/CmpConfigPublisher";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigVendorList} from "../../api/model/cmp/config/CmpConfigVendorList";
import {CmpConfigVendorListField} from "../../api/model/cmp/config/CmpConfigVendorListField";
import {FreemiumList} from "../../api/model/cmp/list/FreemiumList";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {Purpose} from "../../api/model/cmp/list/global-vendor-list/Purpose";
import {Vendor} from "../../api/model/cmp/list/global-vendor-list/Vendor";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {ConfigHeader, PublisherPurposes, PublisherRestrictions, PurposesDisplay} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {GoogleACProviderList} from "../../api/model/cmp/list/google-ac-provider-list/GoogleACProviderList";
import {CmpConfigVendorListDisplayMode} from "../../api/model/cmp/config/CmpConfigVendorListDisplayMode";
import {sortNumbers} from "../../common/utils/helper";

function ConfigPurposes() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const {id: configId} = useParams() as {id: string};

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isEditMode, setEditMode] = useState(false);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);

    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [freemiumList, setFreemiumList] = useState<FreemiumList>(new FreemiumList());
    const [isFreemiumLicense, setFreemiumLicense] = useState(false);

    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [purposes, setPurposes] = useState<number[]>([]);
    const [specialFeatures, setSpecialFeatures] = useState<number[]>([]);

    const [isPurposeEditEnabled, setPurposeEditEnabled] = useState(false);
    const [activePurposeForRestrictions, setActivePurposeForRestrictions] = useState<Purpose>();

    const PURPOSE_1_ID = 1;
    const SPECIAL_FEATURE_2_ID = 2;

    const handleChangeConsentOnly = (checked: boolean) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.vendorList.consentOnly = checked;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleDisablePurpose = (purposeId: number) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        const newCmpConfigVendorList = new CmpConfigVendorList(newCmpConfig.settings.vendorList);
        const newDisabledPurposes = [...(newCmpConfigVendorList.disabledPurposes || [])];

        if (newDisabledPurposes.includes(purposeId)) {
            newDisabledPurposes.splice(newDisabledPurposes.findIndex((it) => it === purposeId), 1);
        } else {
            newDisabledPurposes.push(purposeId);
        }
        newCmpConfigVendorList.disabledPurposes = newDisabledPurposes;
        newCmpConfig.settings.vendorList = newCmpConfigVendorList;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);

        if (activePurposeForRestrictions?.id === purposeId) {
            setActivePurposeForRestrictions(undefined);
        }
    };

    const handleDisableSpecialFeature = (specialFeatureId: number) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        const newCmpConfigVendorList = new CmpConfigVendorList(newCmpConfig.settings.vendorList);
        const newDisabledSpecialFeatures = [...(newCmpConfigVendorList.disabledSpecialFeatures || [])];

        if (newDisabledSpecialFeatures.includes(specialFeatureId)) {
            newDisabledSpecialFeatures.splice(newDisabledSpecialFeatures.findIndex((it) => it === specialFeatureId), 1);
        } else {
            newDisabledSpecialFeatures.push(specialFeatureId);
        }
        newCmpConfigVendorList.disabledSpecialFeatures = newDisabledSpecialFeatures;
        newCmpConfig.settings.vendorList = newCmpConfigVendorList;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleEditPublisherRestrictions = (purposeId: number) => {
        if (activePurposeForRestrictions?.id === purposeId) {
            setActivePurposeForRestrictions(undefined);
        } else {
            setActivePurposeForRestrictions(globalVendorList?.getPurpose(purposeId));
        }
    };

    const handleChangePublisherRestrictions = (publisherRestrictions: CmpConfigPublisherRestriction[]) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.publisherRestrictions = publisherRestrictions;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleChangePublisherPurposes = (publisherPurposes: CmpConfigPublisher) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.publisherPurposes = publisherPurposes;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleChangeDisplayMode = (displayMode: CmpConfigVendorListDisplayMode) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.vendorList.displayMode = displayMode;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleSave = async () => {
        try {
            if (isEditMode && !hasUnsavedChanges) {
                return;
            }

            if (cmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.PURPOSES)?.name) {
                cmpConfig.step = CmpConfigStepName.PURPOSES;
            }
            cmpConfig.settings = new CmpConfigSettings(cmpConfig.settings);
            const {publisherPurposes, vendorList} = cmpConfig.settings;

            if (!vendorList.disabledPurposes?.length) {
                vendorList.disabledPurposes = undefined;
            }
            if (!vendorList.disabledSpecialFeatures?.length) {
                vendorList.disabledSpecialFeatures = undefined;
            }

            let newPublisherRestrictions = [...cmpConfig.settings.publisherRestrictions || []];
            if (vendorList.consentOnly) {
                newPublisherRestrictions = newPublisherRestrictions.filter((item) => item.restrictionType !== CmpConfigPublisherRestrictionType.REQUIRE_LEGITIMATE_INTEREST);
            }
            cmpConfig.settings.publisherRestrictions = newPublisherRestrictions.length ? newPublisherRestrictions : undefined;

            if (publisherPurposes) {
                if (!publisherPurposes.standardPurposes?.length) {
                    publisherPurposes.standardPurposes = undefined;
                }
                if (!publisherPurposes.customPurposes?.length) {
                    publisherPurposes.customPurposes = undefined;
                }
            }

            await session.restCmpConfig.update(cmpConfig);
            setUnsavedChanges(false);
        } catch (e) {
            UIEventManager.alert(textConfigPurposes("error.update_purposes"), AlertSeverity.DANGER);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const newCmpConfig = await session.restCmpConfig.get(configId);
                newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);

                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);

                const globalVendorList = await session.restList.getGlobalVendorList();
                setGlobalVendorList(globalVendorList);

                const isPremiumLicense = await session.isPremium();
                setFreemiumLicense(!isPremiumLicense);

                const freemiumList = await session.restList.getFreemiumList();
                setFreemiumList(freemiumList);

                const selectedVendorIds = [...newCmpConfig.settings.vendorList?.vendors || []];
                if (!isPremiumLicense) {
                    selectedVendorIds.push(...freemiumList.vendors);
                }
                const selectedVendors = globalVendorList.vendors.filter((it) => selectedVendorIds.includes(it.id));
                if (selectedVendors.length) {
                    setVendors(selectedVendors);
                } else {
                    setVendors(globalVendorList.vendors);
                }
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            }
        })();
    }, [configId]);

    useEffect(() => {
        (async () => {
            try {
                let purposes: number[] = [];
                let specialFeatures: number[] = [];

                const {vendorList} = new CmpConfigSettings(cmpConfig.settings);
                const hasSelectedPartners = !!((vendorList.vendors?.length || 0) + (vendorList.googleProviders?.length || 0) + (vendorList.sirdataVendors?.length || 0));

                if ((!isFreemiumLicense && !hasSelectedPartners) || vendorList.networks?.length) { // Network lists are likely to contain all purposes
                    purposes = globalVendorList.purposes.map((it) => it.id);
                    specialFeatures = globalVendorList.specialFeatures.map((it) => it.id);
                } else {
                    vendors.forEach((vendor) => {
                        purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                        specialFeatures = [...specialFeatures, ...vendor.specialFeatures];
                    });
                    if (vendorList.googleProviders?.length) {
                        purposes = [...purposes, ...GoogleACProviderList.TCF_PURPOSES];
                    }

                    const sirdataList = await session.restList.getSirdataList();
                    vendorList.sirdataVendors?.forEach((vendorId) => {
                        const vendor = sirdataList.getVendor(vendorId);
                        if (vendor) {
                            purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                        }
                    });
                    if (isFreemiumLicense) {
                        freemiumList.sirdataVendors.forEach((vendorId) => {
                            const vendor = sirdataList.getVendor(vendorId);
                            if (vendor) {
                                purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                            }
                        });
                    }
                }

                purposes = Array.from(new Set([...purposes]));
                specialFeatures = Array.from(new Set([...specialFeatures]));
                setPurposes(sortNumbers(purposes));
                setSpecialFeatures(sortNumbers(specialFeatures));
            } catch (e) {
                UIEventManager.alert(textConfigPurposes("error.get_purposes"), AlertSeverity.DANGER);
            }
        })();
    }, [vendors, globalVendorList, freemiumList, isFreemiumLicense, cmpConfig, textConfigPurposes]);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges}/>
            <ConfigHeader
                step={CmpConfigStepName.PURPOSES}
                editMode={isEditMode}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={handleSave}
            />
            <MainContent>
                <MainContentPageHeader
                    title={textConfigPurposes(isEditMode ? "title_edit" : "title")}
                    icon={!isEditMode ? <CircleStep step={CmpConfigStepper.getStepPosition(CmpConfigStepName.PURPOSES)}/> : undefined}
                    description={textConfigPurposes("description")}
                    cssClass="configuration-main-title"
                />
                <LayoutRows>
                    <div>
                        <Checkbox
                            name={CmpConfigVendorListField.CONSENT_ONLY}
                            label={textConfigPurposes("apply_consent_only")}
                            checked={!!cmpConfig.settings.vendorList.consentOnly}
                            onChange={handleChangeConsentOnly}
                        />
                        {cmpConfig.settings.vendorList.consentOnly && <Alert severity={AlertSeverity.WARNING} text={textConfigPurposes("apply_consent_only_warning")}/>}
                    </div>
                    <Tabs
                        headers={[
                            {label: textConfigPurposes("tab.partner.title")},
                            {label: textConfigPurposes("tab.custom.title")},
                            {label: textConfigPurposes("tab.display.title")}
                        ]}
                    >
                        <LayoutColumns>
                            <ContentBlock
                                cssClass={"purposes-section"}
                                header={{
                                    title: {label: textConfigPurposes("tab.partner.partners_selected")},
                                    actions: (
                                        <div style={{visibility: isPurposeEditEnabled ? "hidden" : "visible"}}>
                                            <Button size={ButtonSize.SMALL} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => setPurposeEditEnabled((prev) => !prev)}>
                                                {textConfigPurposes("tab.partner.action.update")}
                                            </Button>
                                        </div>
                                    )
                                }}
                            >
                                {!!cmpConfig.settings.vendorList?.disabledPurposes?.length &&
                                    <Alert severity={AlertSeverity.WARNING} text={textConfigPurposes("tab.partner.disabled_purposes_warning")}/>
                                }
                                {!cmpConfig.settings.vendorList?.disabledSpecialFeatures?.includes(SPECIAL_FEATURE_2_ID) &&
                                    <Alert severity={AlertSeverity.WARNING} text={textConfigPurposes("tab.partner.special_feature_2_warning")}/>
                                }
                                <Box spacing={BoxSpacing.MEDIUM} radius={BoxRadius.MD}>
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL} cssClass={"purposes-section__list"}>
                                        {purposes.map((id) =>
                                            <div
                                                key={id}
                                                className={clsx("purposes-section__item purposes-section__item--partner",
                                                    {"purposes-section__item--partner--active": !cmpConfig.settings.vendorList?.disabledPurposes?.includes(id) && (isPurposeEditEnabled && activePurposeForRestrictions?.id === id)},
                                                    {"purposes-section__item--partner--blocked": cmpConfig.settings.vendorList?.disabledPurposes?.includes(id)})}
                                            >
                                                <div>
                                                    <strong>{textConfigPurposes("purpose")} {id}</strong> : {globalVendorList.getPurpose(id)?.name}
                                                    {cmpConfig.settings.publisherRestrictions?.some((it) => it.purpose === id) &&
                                                    <span className="purposes-section__item__legal-basis">{textConfigPurposes("tab.partner.count_restrict", {count: cmpConfig.settings.publisherRestrictions.filter((it) => it.purpose === id).length})}</span>
                                                    }
                                                </div>
                                                {isPurposeEditEnabled &&
                                                (!cmpConfig.settings.vendorList?.disabledPurposes?.includes(id) && id !== PURPOSE_1_ID ?
                                                    <ActionsMenu
                                                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                                        items={[
                                                            {label: cmpConfig.settings.vendorList?.disabledPurposes?.includes(id) ? textConfigPurposes("tab.partner.action.allow_purpose") : textConfigPurposes("tab.partner.action.block_purpose"), onClick: () => handleDisablePurpose(id)},
                                                            {label: textConfigPurposes("tab.partner.action.apply_restrict"), onClick: () => handleEditPublisherRestrictions(id)}
                                                        ]}
                                                    /> :
                                                    <ActionsMenu
                                                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                                        items={[
                                                            {label: cmpConfig.settings.vendorList?.disabledPurposes?.includes(id) ? textConfigPurposes("tab.partner.action.allow_purpose") : textConfigPurposes("tab.partner.action.block_purpose"), onClick: () => handleDisablePurpose(id)}
                                                        ]}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {specialFeatures.map((id) =>
                                            <div key={id} className={clsx("purposes-section__item purposes-section__item--partner", {"purposes-section__item--partner--blocked": cmpConfig.settings.vendorList?.disabledSpecialFeatures?.includes(id)})}>
                                                <div>
                                                    <strong>{textConfigPurposes("tab.partner.feature_sp")} {id}</strong> : {globalVendorList.getSpecialFeature(id)?.name}
                                                </div>
                                                {isPurposeEditEnabled &&
                                                <ActionsMenu
                                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                                    items={[
                                                        {
                                                            label: cmpConfig.settings.vendorList?.disabledSpecialFeatures?.includes(id) ? textConfigPurposes("tab.partner.action.allow_feature") : textConfigPurposes("tab.partner.action.block_feature"),
                                                            onClick: () => handleDisableSpecialFeature(id)
                                                        }
                                                    ]}
                                                />
                                                }
                                            </div>
                                        )}
                                    </FlexContent>
                                </Box>
                            </ContentBlock>
                            {!!activePurposeForRestrictions &&
                            <PublisherRestrictions
                                publisherRestrictions={cmpConfig.settings.publisherRestrictions || []}
                                vendors={vendors}
                                onChange={handleChangePublisherRestrictions}
                                currentPurpose={activePurposeForRestrictions}
                            />
                            }
                        </LayoutColumns>
                        <PublisherPurposes
                            publisherPurposes={cmpConfig.settings.publisherPurposes || new CmpConfigPublisher()}
                            globalVendorList={globalVendorList}
                            onChange={handleChangePublisherPurposes}
                        />
                        <PurposesDisplay
                            cmpConfig={cmpConfig}
                            onChange={handleChangeDisplayMode}
                        />
                    </Tabs>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ConfigPurposes;
