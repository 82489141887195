import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {Vendor} from "./Vendor";
import {Purpose} from "./Purpose";
import {Stack} from "./Stack";
import {GlobalVendorListField} from "./GlobalVendorListField";

export class GlobalVendorList extends ApiModel {
    gvlSpecificationVersion: number = 0;
    vendorListVersion: number = 0;
    tcfPolicyVersion: number = 0;
    lastUpdated: string = "";
    purposes: Purpose[] = [];
    specialPurposes: Purpose[] = [];
    features: Purpose[] = [];
    specialFeatures: Purpose[] = [];
    stacks: Stack[] = [];
    vendors: Vendor[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(GlobalVendorListField.PURPOSES, Purpose);
        this.loadObjects(GlobalVendorListField.SPECIAL_PURPOSES, Purpose);
        this.loadObjects(GlobalVendorListField.FEATURES, Purpose);
        this.loadObjects(GlobalVendorListField.SPECIAL_FEATURES, Purpose);
        this.loadObjects(GlobalVendorListField.STACKS, Stack);
        this.loadObjects(GlobalVendorListField.VENDORS, Vendor);
    }

    getPurpose(id: number) {
        return this.purposes.find((it) => it.id === id);
    }

    getSpecialPurpose(id: number) {
        return this.specialPurposes.find((it) => it.id === id);
    }

    getFeature(id: number) {
        return this.features.find((it) => it.id === id);
    }

    getSpecialFeature(id: number) {
        return this.specialFeatures.find((it) => it.id === id);
    }

    getStack(id: number) {
        return this.stacks.find((it) => it.id === id);
    }

    getVendor(id: number) {
        return this.vendors.find((it) => it.id === id);
    }
}
