import {Button, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalNew, ModalTitle, Paragraph} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {TranslationCommonFile} from "../../common/utils/constants";
import {ModalContactUiEvent} from "../../common/component/modal/ModalContact";

const CONTACT_TAG_GOOGLE_CLIENT = "GoogleClient";

const ModalSupport: FunctionComponent = () => {
    const {t: textContact} = useTranslation(TranslationCommonFile.CONTACT);
    const {t: textSupport} = useTranslation(TranslationPortalFile.SUPPORT);
    const [isActive, setActive] = useState<boolean>(false);


    useEffect(() => {
        UIEventManager.addListener(ModalSupportUiEvent, () => {
            setActive(true);
        });

        return () => {
            UIEventManager.removeAllListeners(ModalSupportUiEvent);
        };
    }, []);

    const handleClick = (isGoogleClient: boolean) => {
        UIEventManager.emit(ModalContactUiEvent, {tags: isGoogleClient ? [CONTACT_TAG_GOOGLE_CLIENT] : undefined});
        setActive(false);
    };

    const handleClose = () => {
        setActive(false);
    };

    return (
        <>
            <ModalNew onClose={handleClose} active={isActive} keepMounted>
                <ModalContent centered>
                    <ModalTitle>{textContact("title")}</ModalTitle>
                    <FormLayoutRows>
                        <Paragraph>{textSupport("google_customer")}</Paragraph>
                        <FormLayoutButtons>
                            <Button
                                onClick={() => handleClick(true)}
                                style={ButtonStyle.DEFAULT_GREEN}
                            >
                                {textSupport("action.yes")}
                            </Button>
                            <Button
                                onClick={() => handleClick(false)}
                                style={ButtonStyle.DEFAULT_GREEN}
                            >
                                {textSupport("action.no")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalSupport;
export const ModalSupportUiEvent = "ModalSupport";
