import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {
    Action,
    Button,
    ButtonSize,
    ButtonStyle,
    FormLayoutButtons,
    FormLayoutRows,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";

type ModalDeleteConfigProps = {
    cmpConfig: CmpConfig | undefined;
    onClose: () => void;
    onDelete: () => void;
}

const ModalDeleteConfig: FunctionComponent<ModalDeleteConfigProps> = ({cmpConfig, onClose, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <ModalNew onClose={onClose} active={!!cmpConfig}>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                        {t("list.confirm_delete")}
                    </ModalDescription>
                    <FormLayoutButtons>
                        <Button
                            size={ButtonSize.BIG}
                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                            onClick={onClose}
                        >
                            {textCommon(Action.CANCEL.labelKey)}
                        </Button>
                        <Button
                            size={ButtonSize.BIG}
                            style={ButtonStyle.DEFAULT_MIDNIGHT}
                            onClick={onDelete}
                        >
                            {textCommon(Action.DELETE.labelKey)}
                        </Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalDeleteConfig;
