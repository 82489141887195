import {Rest} from "../../common/api/rest/Rest";
import {CmpConfig} from "../model/cmp/config/CmpConfig";
import {CmpConfigUploadImageResponse} from "../model/cmp/config/CmpConfigUploadImageResponse";
import {Model} from "../../common/api/interface/Model";
import {ApiClient} from "../ApiClient";
import {CmpConfigImageSelection} from "../../utils/CmpConfigImageSelection";

export class RestCmpConfig extends Rest {
    public get rootPath(): string {
        return `${(this._client as ApiClient).gatewayRoot}/partner/config`;
    }

    async list(): Promise<CmpConfig[]> {
        return this._client.get(`${this.rootPath}`, CmpConfig) as Promise<CmpConfig[]>;
    }

    async get(configId: string): Promise<CmpConfig> {
        return this._client.get(`${this.rootPath}/${configId}`, CmpConfig) as Promise<CmpConfig>;
    }

    async create(cmpConfig: CmpConfig): Promise<CmpConfig> {
        return this._client.post(`${this.rootPath}`, cmpConfig, CmpConfig) as Promise<CmpConfig>;
    }

    async update(cmpConfig: CmpConfig): Promise<CmpConfig> {
        return this._client.put(`${this.rootPath}/${cmpConfig.id}`, cmpConfig, CmpConfig) as Promise<CmpConfig>;
    }

    async uploadImage(file: File): Promise<CmpConfigUploadImageResponse> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        return this._client.post(`${this.rootPath}/image`, formData, CmpConfigUploadImageResponse) as Promise<CmpConfigUploadImageResponse>;
    }

    async delete(configId: string): Promise<Model> {
        return this._client.delete(`${this.rootPath}/${configId}`);
    }

    async getImageSelection(): Promise<CmpConfigImageSelection> {
        return this._client.get(`${this.rootPath}/theme`, CmpConfigImageSelection) as Promise<CmpConfigImageSelection>;
    }
}
