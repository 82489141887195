import {CmpConfigThemeModeField} from "../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {ApiModel} from "../common/api/model/ApiModel";

type CmpConfigImage = {
    url: string;
}

export class CmpConfigImageSelection extends ApiModel {
    logo: CmpConfigImage[] = [];
    skin: CmpConfigImage[] = [];
    watermark: CmpConfigImage[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    forField(field: CmpConfigThemeModeField): CmpConfigImage[] {
        switch (field) {
            case CmpConfigThemeModeField.LOGO:
                return this.logo;
            case CmpConfigThemeModeField.SKIN:
                return this.skin;
            case CmpConfigThemeModeField.WATERMARK:
                return this.watermark;
            default:
                return [];
        }
    }
}
