import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import "./utils/ChartJS";

import CmpConfigStepper, {CmpConfigStepName} from "./utils/CmpConfigStepper";
import ModalSupport from "./component/modal/ModalSupport";
import PrivateRoute from "./component/guard/PrivateRoute";
import {Error403, Error404, Error500} from "./common/screen";
import {ApiEventListener} from "./common/component/snippet";
import {
    Access,
    AnalyticsConsent,
    AnalyticsConsentDetails,
    AnalyticsHits,
    BarterAgreement,
    Cmp,
    ConfigAddOns,
    ConfigBanner,
    ConfigImages,
    ConfigIntegration,
    ConfigPartners,
    ConfigPurposes,
    ConfigSettings,
    ConfigurationFlashInformation,
    ConfigurationFlashPreview,
    ConfigurationMode,
    GoogleErrors,
    Home,
    LegalNotice,
    Login,
    Register,
    Sso,
    TermsOfSale
} from "./screen";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import {
    pathAnalytics,
    pathAnalyticsConsent,
    pathAnalyticsConsentWithId,
    pathAnalyticsHits,
    pathAnalyticsHitsWithId,
    pathBarterAgreement,
    pathCmp,
    pathConfigurationFlashInformation,
    pathConfigurationFlashPreview,
    pathConfigurationMode,
    pathGoogleErrors,
    pathTermsOfSale,
    pathTermsOfUse,
    TranslationPortalFile
} from "./utils/constants";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {session} from "./api/ApiSession";

const App: FunctionComponent = () => {
    const {t, ready} = useTranslation(TranslationPortalFile.TRANSLATION, {useSuspense: false});
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    useEffect(() => {
        if (ready) {
            document.title = t("head.title");
        }
    }, [t, ready]);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<RouterMainLayout/>}>
                                <Route path={pathSso} element={<Sso/>}/>
                                <Route path={pathError403} element={<Error403/>}/>
                                <Route path={pathError404} element={<Error404/>}/>
                                <Route path={pathError500} element={<Error500/>}/>

                                <Route path="/" element={<Navigate to={pathLogin}/>}/>
                                <Route path={pathLogin} element={<Login/>}/>
                                <Route path={pathBarterAgreement} element={<BarterAgreement/>}/>
                                <Route path={pathTermsOfSale} element={<TermsOfSale/>}/>
                                <Route path={pathTermsOfUse} element={<TermsOfSale/>}/>
                                <Route path={pathLegalNotice} element={<LegalNotice/>}/>
                                <Route path={pathAccess} element={<Access/>}/>
                                <Route path={pathGoogleErrors} element={<GoogleErrors/>}/>

                                <Route path={pathHome} element={<PrivateRoute children={<Home/>}/>}/>
                                <Route path={pathCmp} element={<PrivateRoute children={<Cmp/>}/>}/>

                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.BANNER)?.path} element={<PrivateRoute children={<ConfigBanner/>}/>}/>
                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.IMAGES)?.path} element={<PrivateRoute children={<ConfigImages/>}/>}/>
                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.SETTINGS)?.path} element={<PrivateRoute children={<ConfigSettings/>}/>}/>
                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.ADD_ONS)?.path} element={<PrivateRoute children={<ConfigAddOns/>}/>}/>
                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.PARTNERS)?.path} element={<PrivateRoute children={<ConfigPartners/>}/>}/>
                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.PURPOSES)?.path} element={<PrivateRoute children={<ConfigPurposes/>}/>}/>
                                <Route path={CmpConfigStepper.getStep(CmpConfigStepName.INTEGRATION)?.path} element={<PrivateRoute children={<ConfigIntegration/>}/>}/>

                                <Route path={pathAnalytics} element={<Navigate to={pathAnalyticsConsent}/>}/>
                                <Route path={pathAnalyticsConsent} element={<PrivateRoute children={<AnalyticsConsent/>}/>}/>
                                <Route path={pathAnalyticsConsentWithId} element={<PrivateRoute children={<AnalyticsConsentDetails/>}/>}/>
                                <Route path={pathAnalyticsHits} element={<PrivateRoute children={<AnalyticsHits/>}/>}/>
                                <Route path={pathAnalyticsHitsWithId} element={<PrivateRoute children={<AnalyticsHits/>}/>}/>

                                <Route path={pathConfigurationMode} element={<PrivateRoute children={<ConfigurationMode/>}/>}/>
                                <Route path={pathConfigurationFlashInformation} element={<PrivateRoute children={<ConfigurationFlashInformation/>}/>}/>
                                <Route path={pathConfigurationFlashPreview} element={<PrivateRoute children={<ConfigurationFlashPreview/>}/>}/>

                                <Route path="/register" element={<Register/>}/>

                                <Route path="*" element={<Error404/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;

function RouterMainLayout() {
    return (
        <>
            <ApiEventListener/>
            <ScrollToTop/>
            <Outlet/>
            <ModalSupport/>
        </>
    );
}
