import {MENU} from "./Menu";
import {ModalSupportUiEvent} from "../component/modal/ModalSupport";
import {Origin} from "../common/api/model/Origin";
import {pathHome} from "../common/utils/constants";
import {PortalConfig} from "../common/api/PortalConfig";
import {UIEventManager} from "../common/utils/UIEventManager";

export const PORTAL = new PortalConfig(Origin.CMP, pathHome)
    .withMenuItems(MENU)
    .withShortcuts([
        {target: () => UIEventManager.emit(ModalSupportUiEvent), icon: {name: "mail"}, label: "menu.contact_support"}
    ]);
