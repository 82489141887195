export class CmpCloudflareEndpoint {
    static CACHE: CmpCloudflareEndpoint = new CmpCloudflareEndpoint("cache", "https://cache.consentframework.com");
    static CHOICES: CmpCloudflareEndpoint = new CmpCloudflareEndpoint("choices", "https://choices.consentframework.com");

    name: string = "";
    url: string = "";

    constructor(name: string, url: string) {
        this.name = name;
        this.url = url;
    }

    static values(): CmpCloudflareEndpoint[] {
        return [
            CmpCloudflareEndpoint.CACHE,
            CmpCloudflareEndpoint.CHOICES
        ];
    }

    getUrl(preprod?: boolean): string {
        return preprod ? this.url.replace(".consentframework.com", "-preprod.consentframework.com") : this.url;
    }
}
