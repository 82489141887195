import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigPublisherRestriction} from "../../api/model/cmp/config/CmpConfigPublisherRestriction";
import {CmpConfigPublisherRestrictionType} from "../../api/model/cmp/config/CmpConfigPublisherRestrictionType";
import {Vendor} from "../../api/model/cmp/list/global-vendor-list/Vendor";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentDirection, FormLayoutButtons, FormLayoutRows, InputCheckbox, ModalContent, ModalNew, ModalTitle, SearchBar, Select, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {CmpConfigPublisherRestrictionField} from "../../api/model/cmp/config/CmpConfigPublisherRestrictionField";
import {sortItems} from "../../common/utils/helper";
import {VendorField} from "../../api/model/cmp/list/global-vendor-list/VendorField";

type ModalPurposeRestrictionProps = {
    initRestriction: CmpConfigPublisherRestriction | undefined;
    vendors: Vendor[];
    restrictions: CmpConfigPublisherRestriction[];
    onClose: () => void;
    onSubmit: (restriction: CmpConfigPublisherRestriction) => void;
}

const ModalPurposeRestriction: FunctionComponent<ModalPurposeRestrictionProps> = ({initRestriction, vendors, restrictions, onClose, onSubmit}) => {
    const {t: textConfigPartners} = useTranslation(TranslationPortalFile.CONFIG_PARTNERS);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const [partners, setPartners] = useState<Vendor[]>([]);
    const [query, setQuery] = useState("");
    const [currentPartners, setCurrentPartners] = useState<Vendor[]>([]);
    const [restriction, setRestriction] = useState<CmpConfigPublisherRestriction>(new CmpConfigPublisherRestriction());

    useEffect(() => {
        if (initRestriction) {
            setRestriction(new CmpConfigPublisherRestriction(initRestriction));
        }
    }, [initRestriction]);

    const isAllCurrentPartnersSelected = () => {
        return !!currentPartners.length && currentPartners.filter(({id}) => restriction.vendors.includes(id)).length === currentPartners.length;
    };

    const handleChange = (field: CmpConfigPublisherRestrictionField, value: any) => {
        setRestriction((prevState) => new CmpConfigPublisherRestriction({...prevState, [field]: value}));
    };

    const handleChangeAllVendors = () => {
        let newVendors = [...restriction.vendors];
        if (isAllCurrentPartnersSelected()) {
            newVendors = newVendors.filter((id) => !currentPartners.map(({id}) => id).includes(id));
        } else {
            newVendors = [...newVendors, ...currentPartners.map(({id}) => id)];
        }
        handleChange(CmpConfigPublisherRestrictionField.VENDORS, newVendors);
    };

    const handleChangeVendor = (vendorId: number) => {
        let newVendors = [...restriction.vendors];
        if (newVendors.includes(vendorId)) {
            newVendors = newVendors.filter((id) => id !== vendorId);
        } else {
            newVendors.push(vendorId);
        }
        handleChange(CmpConfigPublisherRestrictionField.VENDORS, newVendors);
    };

    const handleSubmit = () => {
        onSubmit(new CmpConfigPublisherRestriction(restriction));
    };

    useEffect(() => {
        const newCurrentPartners = partners.filter((partner) => partner.id.toString().includes(query) || partner.name.toLowerCase().includes(query.toLowerCase()));
        setCurrentPartners(newCurrentPartners);
    }, [query, partners]);

    useEffect(() => {
        if (!initRestriction) return;

        let newPartners = vendors.filter((vendor) => {
            if (restriction.restrictionType === CmpConfigPublisherRestrictionType.REQUIRE_CONSENT)
                return vendor.legIntPurposes.includes(initRestriction.purpose);
            if (restriction.restrictionType === CmpConfigPublisherRestrictionType.REQUIRE_LEGITIMATE_INTEREST)
                return vendor.purposes.includes(initRestriction.purpose);
            return vendor.purposes.includes(initRestriction.purpose) || vendor.legIntPurposes.includes(initRestriction.purpose);
        });
        newPartners = sortItems(newPartners, VendorField.NAME);
        setPartners(newPartners);
    }, [vendors, initRestriction, restriction.restrictionType]);

    useEffect(() => {
        const existingVendors = restrictions.find((item) => item.restrictionType === restriction.restrictionType)?.vendors || [];
        handleChange(CmpConfigPublisherRestrictionField.VENDORS, [...existingVendors]);
    }, [restrictions, restriction.restrictionType]);

    return (
        <ModalNew onClose={() => onClose()} active={!!initRestriction}>
            <ModalContent>
                <ModalTitle>{textConfigPurposes("add_restrict_for")} {restriction.purpose}</ModalTitle>
                <FormLayoutRows>
                    <FieldBlock label={textConfigPurposes("select_restriction_type")}>
                        <Select
                            value={restriction.restrictionType}
                            options={[
                                {value: CmpConfigPublisherRestrictionType.NOT_ALLOWED, label: textConfigPurposes("not_allowed")},
                                {value: CmpConfigPublisherRestrictionType.REQUIRE_CONSENT, label: textConfigPurposes("require_consent")},
                                {value: CmpConfigPublisherRestrictionType.REQUIRE_LEGITIMATE_INTEREST, label: textConfigPurposes("require_legint")}
                            ]}
                            onChange={(option) => option && handleChange(CmpConfigPublisherRestrictionField.RESTRICTION_TYPE, option.value)}
                        />
                    </FieldBlock>
                    <FieldBlock
                        label={textConfigPurposes("select_restriction_vendors")}
                        content={{direction: FlexContentDirection.COLUMN}}
                    >
                        <SearchBar
                            placeholder={textConfigPartners("search.partner")}
                            value={query}
                            onChange={setQuery}
                        />
                        <Table
                            columns={[
                                {width: 10, label: <InputCheckbox checked={isAllCurrentPartnersSelected()} onChange={handleChangeAllVendors}/>, styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 15, label: textConfigPartners("field.id")},
                                {width: 75, label: textConfigPartners("field.partner_name")}
                            ]}
                            pagination={MODAL_PAGE_SIZE}
                        >
                            {currentPartners.map((vendor) =>
                                <TableRow key={vendor.id} onClick={() => handleChangeVendor(vendor.id)}>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                        <InputCheckbox
                                            checked={restriction.vendors.includes(vendor.id)}
                                            onChange={() => {}}
                                        />
                                    </TableColumn>
                                    <TableColumn>{vendor.id}</TableColumn>
                                    <TableColumn>{vendor.name}</TableColumn>
                                </TableRow>
                            )}
                        </Table>
                    </FieldBlock>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <ButtonValidate onClick={handleSubmit}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalPurposeRestriction;
